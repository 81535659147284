import { TextField } from "@mui/material";

const Password = ({
  field_id,

  display_name,

  field_value,

  handleBlur,

  handleChange,

  touched,

  errors,

  values,

  type,

  ...otherProps
}) => {
  const renderField = () => {
    const shouldDisplayPassword =
      values.authentication_type === "basic" ||
      (type && type !== "Application");

    if (shouldDisplayPassword) {
      return (
        <TextField
          id="standard-basic"
          fullWidth
          variant="outlined"
          type="password"
          label={display_name}
          sx={{ gridColumn: "span 4", width: "360px!important", marginLeft: "12px", minHeight: "10px" }}

          InputLabelProps={{
            style: { color: "black", fontSize: '16px' },
             shrink: true, // Ensure the label stays above the input
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          // value={values != null ? values[field_id] || field_value : ""}
          value={values != null ? values[field_id] : ""}

          name={field_id}
          error={!!touched[field_id] && !!errors[field_id]}
          helperText={touched[field_id] && errors[field_id]}
          {...otherProps}
        />
      );
    } else {
      return null;
    }
  };

  return renderField();
};

export default Password;
