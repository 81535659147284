import React, { useEffect, useState } from "react";
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ChevronRightOutlined } from "@mui/icons-material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation, useNavigate } from "react-router-dom";
import program from "../svg/program.svg";
import config from "../svg/config.svg";
import configdetails from "../svg/configdetails.svg";
import configoptions from "../svg/configoptions.svg";
import connections from "../svg/connections.svg";
import pipelines from "../svg/pipelines.svg";
import project from "../svg/project.svg";
import task from "../svg/task.svg";
import useraccess from "../svg/useraccess.svg";
import users from "../svg/users.svg";

const style = { width: '25px', height: '25px' };

const navItems = [
  { text: "Dashboard", icon: <DashboardIcon sx={{ color: "white", width: '30px', height: '30px' }} /> },
  { text: "WorkSpace", icon: null },
  { icon: <img src={program} alt="Profile Icon" style={style} />, text: "Programs", role: ["ADMIN", "USER"] },
  { icon: <img src={project} alt="Profile Icon" style={style} />, text: "Projects", role: ["ADMIN", "USER"] },
  { icon: <img src={pipelines} alt="Profile Icon" style={style} />, text: "Pipelines", role: ["ADMIN", "USER"] },
  { icon: <img src={task} alt="Profile Icon" style={style} />, text: "Tasks", role: ["ADMIN", "USER"] },
  { icon: <img src={connections} alt="Profile Icon" style={style} />, text: "Connections", role: ["ADMIN", "USER"] },
  { text: "User Management", role: ["ADMIN"], icon: null },
  { icon: <img src={users} alt="Profile Icon" style={style} />, text: "Users", role: ["ADMIN"] },
  { text: "UserAccess", role: ["ADMIN"], icon: <img src={useraccess} alt="Profile Icon" style={style} /> },
  { text: "UI Configurations", role: ["ADMIN"], icon: null },
  { icon: <img src={config} alt="Profile Icon" style={style} />, text: "Configuration", role: ["ADMIN"] },
  { icon: <img src={configdetails} alt="Profile Icon" style={style} />, text: "Details", role: ["ADMIN"] },
  { icon: <img src={configoptions} alt="Profile Icon" style={style} />, text: "Options", role: ["ADMIN"] },
];

const Sidebar = ({ user, drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setActive(pathname.substring(1).split("/")[0]);
  }, [pathname]);

  return (
    <Box component="nav">
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: isSidebarOpen ? drawerWidth : "60px", // Width when open or closed
          transition: "width 0.3s", // Smooth transition
          "& .MuiDrawer-paper": {
            width: isSidebarOpen ? drawerWidth : "60px",
            transition: "width 0.3s",
            overflowX: "hidden",
            backgroundColor: "var(--primary-color) !important",
            color: "white",
            boxSizing: "border-box",
            borderWidth: isNonMobile ? 0 : "2px",
            fontFamily: "Poppins",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Pushes the copyright text to the bottom
          },
          "& .MuiTypography-root": {
            color: "white",
            fontSize: "14px!important",
            fontFamily: "Poppins",
          },
        }}
      >
        <Box width="100%">
          <List style={{ marginTop: '35px' }}>
            {navItems.map(({ text, icon, role }) => {
              const hasAccess = !role || role.includes(user.role);
              if (!hasAccess) return null;
              if (!icon) {
                return (
                  <Typography key={text} sx={{ m: "1.25rem 0 0.5rem 1.5rem", fontStyle: "bold" }}>
                    {isSidebarOpen ? text : "-"}
                  </Typography>
                );
              }
              let lcText = text.toLowerCase();
              if (text.includes("/")) lcText = text.split("/")[0].toLowerCase();
              return (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/${lcText}`);
                      setActive(lcText);
                    }}
                    sx={{
                      backgroundColor: active === lcText
                        ? "var(--sidebar-select-color) !important"
                        : "var(--primary-color) !important",
                      color: "white",
                      fontFamily: "Poppins",
                      "&:hover": {
                        backgroundColor: "var(--fifth-color) !important", // Change background color to red on hover
                      },
                    }}>
                    <ListItemIcon>
                      {icon}
                    </ListItemIcon>
                    {isSidebarOpen && <ListItemText primary={text} />} {/* Only show text when sidebar is open */}
                    {active === lcText && (<ChevronRightOutlined sx={{ ml: "auto", color: "white" }} />)}
                  </ListItemButton>
                </ListItem>

              );
            })}
          </List>
        </Box>

        {isSidebarOpen && ( // Display copyright only when sidebar is open
          <Box sx={{ textAlign: "left", marginLeft: "10px", marginBottom: "5px" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "10px", fontFamily: "Poppins", fontStyle: "italic" }}
            >
              &#169; QuestKart Inc
            </Typography>
          </Box>

        )}
      </Drawer>
    </Box>
  );
};


export default Sidebar;
