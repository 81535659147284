export default function getInitialFormikValues(json) {
    const initialValues = json.initialvalues;
  
    const deepClone = (obj) => {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }
      if (Array.isArray(obj)) {
        return obj.map(deepClone);
      }
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = deepClone(value);
        return acc;
      }, {});
    };
  
    const formikInitialValues = deepClone(initialValues);
  
    // Set empty strings for undefined values
    const setEmptyStrings = (obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          setEmptyStrings(value);
        } else if (value === undefined || value === null) {
          obj[key] = "";
        }
      });
    };
  
    setEmptyStrings(formikInitialValues);
  
    // Convert expression_list object to an array of objects
    if (formikInitialValues.expression_list) {
      formikInitialValues.expression_list = Object.values(
        formikInitialValues.expression_list
      );
    }
  
    return formikInitialValues;
  }
  