import React from "react";
import { Box } from "@mui/material";
import Element from "../components/Element";
import ToggleButtonForTaskParams from "./ToggleButtonForTaskParams";

const TaskParamsForm = (props) => {
  const { taskProps, formProps, handleConnSubTypeChange, object, getValuesForFormikSet } = props;
  const {
    connSubTypeList,
    currSrcConnSubType: currConnSubType,
    fieldList: fields_list,
  } = taskProps;

  console.log("Object: ", object);

  let valuesToPass = formProps.values;
  let taskParamFieldList = Object.assign([], fields_list);

  taskParamFieldList.sort(function (a, b) {
    return a.display_sequence - b.display_sequence;
  });

  const updateValuesBasedOnConditions = (values, object, currSrcConnSubType) => {
    // Create a new object to update state
    try {
      const updatedValues = { ...values };

      if (object === 'Source') {
        // Check if currSrcConnSubType is different from values.source
        if (currSrcConnSubType !== values.source) {
          // Iterate over the keys and update those starting with 'src_'
          Object.keys(updatedValues).forEach(key => {
            if (key.startsWith('src_')) {
              updatedValues[key] = '';
            }
          });
        }
      } else if (object === 'Target') {
        // Check if currSrcConnSubType is different from values.target
        if (currSrcConnSubType !== values.target) {
          // Iterate over the keys and update those starting with 'tgt_'
          Object.keys(updatedValues).forEach(key => {
            if (key.startsWith('tgt_')) {
              updatedValues[key] = '';
            }
          });
        }
      }
      return updatedValues;
    } catch (error) {
      console.log("Error while updating values", error);
    }
  };

  const handleConnTypeChange = (event, newValue) => {
    if ((object === "Source" && formProps.initialValues.source === "none") || (object === "Target" && formProps.initialValues.target === "none")) {
      valuesToPass = updateValuesBasedOnConditions(formProps.values, object, newValue);
    }
    else {
      valuesToPass = updateValuesBasedOnConditions(formProps.initialValues, object, newValue);
    }
    getValuesForFormikSet(valuesToPass);
    handleConnSubTypeChange(newValue);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box >
          {connSubTypeList ? (
            <ToggleButtonForTaskParams
              alignment="horizontal"
              value={currConnSubType}
              connSubList={connSubTypeList}
              handleChange={handleConnTypeChange}
            />
          ) : null}
        </Box>
        <Box mt="30px" display="flex" justifyContent="center">
  <Box
    display="grid"
    gridTemplateColumns={`repeat(${taskParamFieldList && taskParamFieldList.length > 10 ? 2 : 1}, minmax(0, 1fr))`}
    rowGap="10px"
    columnGap={taskParamFieldList && taskParamFieldList.length > 10 ? "50px" : "0px"}
    style={{ width: taskParamFieldList && taskParamFieldList.length < 10 ? '400px' : '100%' }}
  >
    {taskParamFieldList
      ? taskParamFieldList.map((field, index) => (
        index % 1 === 0 && (
          <div key={`row_${index}`}>
            <Element
              key={field.id}
              field={field}
              values={formProps.values}
              touched={formProps.touched}
              errors={formProps.errors}
              handleBlur={(e) => { }}
              handleChange={formProps.handleChange}
            />
          </div>
        )
      ))
      : null}
  </Box>
</Box>


      </Box>
    </>
  );
};

export default TaskParamsForm;
