import React, { useState } from "react";
import { Box, Button, Typography,Alert, AlertTitle,Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "../components/FlexBetween";

import {
  useGetUsersQuery,
  useGetObjectFormQuery,
  useAddObjectMutation,
  useUpdateObjectMutation,
  useActivateUserMutation,
} from "../state/apiSlice";
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";

import Header from "../components/Header";
import Popup from "../components/Popup";
import UserForm from "../pages/UserForm";
import { ModeEditOutline } from "@mui/icons-material";
import VerifiedIcon from '@mui/icons-material/Verified';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import useStyles from "useStyles";


const Users = () => {
  const styles = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const user = currentUser.login_id;
  const { data: userRecords, isLoading: isRecordLoading, error: recordError } = useGetUsersQuery();
  const { data: userformData, isLoading: isUserFormLoading, error: formError } =
    useGetObjectFormQuery({
      name: "User",
      user: user,
    });

  const [addObject] = useAddObjectMutation();
  const [updateObject] = useUpdateObjectMutation();

  const [initValue, setInitValue] = useState(null);
  const [fieldsList, setFieldList] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);



  if (!isUserFormLoading && initValue === null && !isRecordLoading) {
    if (formError) {
      console.error("Error fetching form data:", formError);
    } else {
      setInitValue(userformData[0]?.initialvalues);
      setFieldList(userformData[0]?.field_list);
    }
  }

  const addOrEdit = async (values, resetForm) => {
    if (values?.id === 0) {
      await addObject({ object: "User", data: values });
    } else {
      await updateObject({ object: "User", data: values });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  let pageTitle = "Users";
  let pageSubTitle = "List of Users.";
  const [approveUser] = useActivateUserMutation();

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "full_name", headerName: "Full Name", flex: 1 },
    { field: "login_id", headerName: "User ID", flex: 1 },
    { field: "user_email", headerName: "Email ID", flex: 2 },
    { field: "user_phone", headerName: "Phone Number", flex: 1 },
    { field: "is_active", headerName: "Active", flex: 0.5, headerAlign: "center",
      align: "center" },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
       headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const { is_active } = params.row;
        const onClick = () => {
          approveUser(params.row.id)
            .then(() => {
              console.log('Request succeeded');
              window.location.reload();
            })
            .catch((error) => {
              console.error('Request failed', error);
            });
        };
        return is_active === "Y" ? (
          <Tooltip title="Approved"  placement="right">
          <Box disabled style={{ color: "var(--fifth-color) !important" }}>
            <VerifiedIcon />
          </Box>
        </Tooltip>
        ) : (
          <Tooltip title="Approve"  placement="right">
          <Box
            onClick={onClick}
            sx={{ 
              color: "var(--primary-color) !important",
              cursor: "pointer",
              "&:hover": {
                "& svg": {
                  transform: "scale(1.2)",
                  transition: "transform 0.3s ease",
                },
              },
            }}
          >
            <ThumbUpAltIcon />
          </Box>
        </Tooltip>
        );
      },
    },
    {
      field: "update",
      headerName: "Update",
      flex: 0.5,
       headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return (
          <Button
            color="secondary"
            onClick={() => {
              openInPopup(row);
            }}
          >
            <ModeEditOutline sx={styles.editButton} />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title={pageTitle} subtitle={pageSubTitle} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(initValue);
            }}
            sx={styles.myBackground}
         
          >
            Create
          </Button>
        </FlexBetween>
      </Box>
      <Box m="1.5rem 2.5rem">
        {recordError ? (
          <Alert severity="error" style={{ width: '100%', height: '100vh', margin: 'auto' }}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            <Typography style={{ fontWeight: 'bold' }}>
              {recordError?.data?.message}
            </Typography>
          </Alert>

        ) : isRecordLoading ? (
          <p>Loading...</p>
        ) : userRecords ? (
          <Box
            m="10px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "var(--tertiary-color) !important",
              },
              "& .MuiDataGrid-row.Mui-disabled-row": {
                backgroundColor: "lightgrey",
              },


              "& .MuiDataGrid-root": {
                border: "none",
                fontWeight: "600",
                fontSize: "12px !important",
                fontFamily: "Poppins", 
              },

              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color) !important",
                border: "none",
                color: "#ffffff!important",
                fontSize: "15px!important",
                fontWeight: "bold!important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                fontFamily: "Poppins",
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#f5f5f5",
                minHeight: "352px!important",
                // maxHeight: "323px!important",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "var(--pagination-bottom-color) !important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                borderRadius: "0 0 6px 6px",
                marginTop: "5px!important",
                marginTop: "5px!important",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "black!important",
                fontSize: "12px",
                width: "100px",
                marginBottom: "1px",
                fontFamily: "Poppins",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#4b437cd1!important",
              },
              "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                color: 'white', // Ensure filter icons and three-dot menu icons are white
              },

            }}
          >
            <DataGrid
              rowHeight={40}
              rows={userRecords}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params) =>
                
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "Mui-odd"
                  : "Mui-even"
              }
            />
          </Box>
        ) : (
          <>Loading...</>
        )}
      </Box>
      <Popup
        title="User Registration Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        width={"sm"}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <UserForm
            fieldsList={fieldsList}
            values={recordForEdit}
            addOrEdit={addOrEdit}
            object={"User"}
          />)}
      </Popup>
    </>
  );
};

export default Users;
