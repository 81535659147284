import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Element from "../components/Element";
import { selectCurrentUser } from "state/authSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../api/axios';
import { selectCurrentToken } from "state/authSlice";
const ObjectForm = (props) => {
  const accessToken = useSelector(selectCurrentToken);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { fieldsList, values, addOrEdit, object } = props;
  const [userSchema, setUserSchema] = useState(null);
  const [action, setAction] = useState(null);
  const [isEditing, setIsEditing] = useState(Boolean(values?.id));
  const currentUser = useSelector(selectCurrentUser);
  const [resetKey, setResetKey] = useState(0);
 
 
  if (userSchema === null) {
    if (values?.id > 0) {
      setAction("Update");
    } else {
      setAction("Create");
    }
    let validationObject = {};
 
fieldsList?.forEach(({ field_id, display_label, required, field_value }) => {
  if (required === "Y") {
    validationObject[field_id] = yup.string().required(`${field_id} is required`);
 
    // Add alphanumeric validation for specific fields
    if (field_id === "program_name" || field_id === "project_name" || field_id === "pipeline_name") {
      validationObject[field_id] = validationObject[field_id].matches(
        /^[a-zA-Z0-9_]+$/,
        `${field_id} must be alphanumeric and may contain underscores only`
      );
    }
  }
});
 
const validationSchema = yup.object().shape(validationObject);
 
 
    setUserSchema(validationSchema);
  }
  const styles = {
    btnCss: {
   fontSize: "12px",
        fontWeight: "600",
        borderRadius: "3px",
        backgroundColor: "var(--primary-color) !important",
        color: "#FFFFFF!important",
        textTransform: "upper",
        padding: "4px 7px",
        fontFamily: "Poppins"
    },
   
  };
 
  const checkConnectionNameExists = async (firstpoint, secondpoint,name, setFieldError, field_name) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/${firstpoint}/${secondpoint}/${name}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const exists = response.data.exists;
 
      console.log(userSchema);
     
      updateValidationSchema(exists,field_name);
 
      // Trigger validation immediately after schema update
      if (exists) {
        setFieldError(field_name, `${field_name.replace(/_/g, ' ')} already exists`);
      } else {
        // Clear the error if the connection name does not exist
        setFieldError(field_name, undefined);
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  const updateValidationSchema = (exists,field_name) => {
    setUserSchema((prevSchema) =>
      prevSchema.shape({
        ...prevSchema.fields,
        [field_name] : yup.string()
          .test(
            'unique-connection-name',
            `${field_name.replace(/_/g, ' ')} already exists`,
            () => !exists
          )
          .matches(/^\w+$/, `${field_name.replace(/_/g, ' ')} must be alphanumeric and may contain underscores only`)
          .required(`${field_name.replace(/_/g, ' ')} is required`),
      })
    );
  };
  const handleFormSubmit = async (values, { resetForm }) => {
    setIsEditing(Boolean(values?.id));
    if (!("id" in values)) {
      values["id"] = 0;
    }
    if (!("created_by" in values)) {
      values["created_by"] = currentUser.login_id;
    }
    values["updated_by"] = currentUser.login_id;
 
    try {
      toast.success(` ${object} ${action}d  Successfully`, { position: "top-center" });
        addOrEdit(values, resetForm);
    } catch (error) {
      console.error("Form Submission Error: ", error);
      toast.error("Form Submission Error");
    }
  };
 
  const isMultipleColumns = fieldsList && fieldsList.length > 8;
 
  return (
    <Box >
      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={userSchema}
        initialValues={values}
        key={resetKey}
 
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          isSubmitting,
          validateField,
          setFieldError
        }) => (
          <form onSubmit={handleSubmit}>
            <Box margin="1.5rem "
              display="grid"
              rowGap="15px"
              columnGap="10px"
              gridTemplateColumns={`repeat(${isMultipleColumns ? 2 : 1}, minmax(0, 1fr))`}
            >
              {fieldsList
                ? fieldsList.map((field, index) => (
                  <Box key={field.id}>
                    <Element
                      key={field.id}
                      field={field}
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={(event) => {
                        handleChange(event);
                        const value = event.target.value;
                        if (field.field_id === 'project_name') {
                          checkConnectionNameExists('projects','projectexists',value, setFieldError, field.field_id);
                        }
                        else if(field.field_id === 'pipeline_name'){
                          checkConnectionNameExists('pipelines','pipelineExists',value, setFieldError, field.field_id);
                        }else if(field.field_id === 'program_name'){
                          checkConnectionNameExists('programs','programExists',value, setFieldError, field.field_id);
                        }
                       
                      }}
                      disabled={isEditing && field.field_id === "password"}
                    />
                    {isMultipleColumns && index % 2 === 1 && <br />}
                  </Box>
                ))
                : null}
            </Box>
            <Box display="flex" justifyContent="end" >
              
              <Button
                type="button"
                className="outline"
                onClick={() => {
                  handleReset();
                  setResetKey((prevKey) => prevKey + 1);
                }}
                sx={styles.btnCss}
 
                disabled={!dirty || isSubmitting}
              >
                Reset
              </Button>
              &nbsp;
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                sx={styles.btnCss}
              >
                {action} {object}
           </Button>
            </Box>
            <ToastContainer/>
 
          </form>
        )}
      </Formik>
    </Box>
  );
};
 
export default ObjectForm;
 