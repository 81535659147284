import { logOut, setCredentials } from "./authSlice";

export function saveTokenInLocalStorage(userDetails) {
  const expiresIn = userDetails.expiresIn;

  const durationInSeconds = parseInt(expiresIn) * 60 * 60;

  userDetails.expireDate = new Date(
    new Date().getTime() + durationInSeconds * 1000
  );

  localStorage.setItem("userDetails", JSON.stringify(userDetails));
}



export function runLogoutTimer(dispatch, timer) {
  setTimeout(() => {
    dispatch(logOut());
  }, timer);
}
export function checkAutoLogin(dispatch) {
  const userDetailsString = localStorage.getItem("userDetails");

  if (!userDetailsString) {
    dispatch(logOut());
    return;
  }

  const userDetails = JSON.parse(userDetailsString);

  // Check if expireDate is present, otherwise calculate it using expiresIn
  let expireDate = userDetails.expireDate
    ? new Date(userDetails.expireDate)
    : new Date(new Date().getTime() + userDetails.expiresIn * 1000);

  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logOut());
    return;
  }

  const { token, ...userInfo } = userDetails;
  dispatch(setCredentials({ user: userInfo, token }));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer);
}