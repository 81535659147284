import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "state/authSlice";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { FormControl, IconButton, Tooltip, TextField } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  Button,
  useTheme,
} from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ModeEditOutline } from "@mui/icons-material";
import Breadcrumbs from "components/elements/Breadcrumbs";
import FlexBetween from "../components/FlexBetween";
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import EditOffTwoToneIcon from '@mui/icons-material/EditOffTwoTone';
import {
  useGetProgramsQuery,
  useGetObjectFormQuery,
  useAddObjectMutation,
  useUpdateObjectMutation,
} from "../state/apiSlice";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import ObjectForm from "../pages/ObjectForm";
import ReactMarkdown from 'react-markdown';
import useStyles from "useStyles";
const Programs = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [markdownContent, setMarkdownContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    fetch('/docs/program/program.md')
      .then(response => response.text())
      .then(data => setMarkdownContent(data))
      .catch(error => console.error('Error fetching program.md:', error));
  }, []);


  const currentUser = useSelector(selectCurrentUser);
  const user = currentUser.login_id
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const { data: formData, isLoading: isFormLoading, error: formError } =
    useGetObjectFormQuery({
      name: "Program",
      user: user,
    });
  // useGetObjectFormQuery("Program");
  const { data: programRecords, isLoading: isRecordLoading, error: recordError } =
    useGetProgramsQuery(user);

  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggle(newToggle);
    }
  };

  const [addObject] = useAddObjectMutation();
  const [updateObject] = useUpdateObjectMutation();

  const [initValue, setInitValue] = useState(null);
  const [fieldsList, setFieldList] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupHelp, setOpenPopupHelp] = useState(false);


  const [toggle, setToggle] = useState(true);
  const addOrEdit = async (values, resetForm) => {
    if (values?.id === 0) {
      await addObject({ object: "program", data: values, user: user });
    } else {
      await updateObject({ object: "program", data: values, user: user });
    }

    const delayMilliseconds = 3000; // 3 seconds
    await new Promise(resolve => setTimeout(resolve, delayMilliseconds));
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };
  // Css for Button



  if (formData && !isFormLoading && initValue === null && !isRecordLoading) {
    setInitValue(formData[0]?.initialvalues);
    setFieldList(formData[0]?.field_list);
  }
  const closeModal = () => {
    setOpenPopupHelp(false);
  };

  const handleClick = async (item) => {
    navigate(`/projects/${item.id}`, { state: { itemData: item } });
  };

  const breadcrumbPaths = [
    { path: '/', label: 'Home' },
    { path: '/programs', label: 'Programs' },
  ];
  const filteredPrograms = programRecords?.filter((item) =>
    item.program_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header title="Programs" />

        <Breadcrumbs breadcrumbPaths={breadcrumbPaths} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header subtitle="View/Manage Programs" />
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              variant="outlined"
              placeholder="Search By Program Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--primary-color) !important", // default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--primary-color) !important", // border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--primary-color) !important", // border color when focused
                  },
                  height: "35px", // Match the height of buttons
                  width:"210px"
                },
              }}
            />
            <ToggleButtonGroup
              value={toggle}
              exclusive
              onChange={handleToggle}
              aria-label="Toggle between Active and All"
              sx={{ borderRadius: "80px" }}
            >
              <ToggleButton
                value={true}
                aria-label="All"
                sx={{
                  ...styles.myBackground,

                  backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: !toggle ? "#ffffff" : styles.myBackground.color,
                  '&:hover': {
                    backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  },
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value={false}
                aria-label="Active"
                sx={{
                  ...styles.myBackground,
                  backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: toggle ? "#ffffff" : styles.myBackground.color, // Text color changes based on toggle state
                  '&:hover': {
                    backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                    color: toggle ? "#ffffff" : styles.myBackground.color, // Ensure hover color is consistent with the toggle state
                  },
                }}
                disabled={!toggle}
              >
                Active
              </ToggleButton>
            </ToggleButtonGroup>

            <Button
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(initValue || {});
              }}
              sx={{
                ...styles.myBackground,
              }}
            >
              Create
            </Button>

            <Button
              onClick={() => {
                setOpenPopupHelp(true);
              }}
              sx={{
                ...styles.myBackgroundToggle,

              }}
            >
              Help
            </Button>
          </Box>


        </Box>



      </Box>
      <Box m="1.5rem 2.5rem">
        {recordError ? (
          <Alert severity="error" style={{ width: '100%', height: '100vh', margin: 'auto' }}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            <Typography style={{ fontWeight: 'bold' }}>
              {recordError?.data?.message}
            </Typography>
          </Alert>

          // <FormControl>{recordError?.data?.message}</FormControl>
        ) : isRecordLoading ? (
          <p>Loading...</p>
        ) : filteredPrograms ? (
          <Box

            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {filteredPrograms
              .filter((item) => (toggle ? item.is_active === 'Y' || item.is_active === 'N' : item.is_active === 'Y'))
              .map((item) => {
                const isDisabled = item.is_active === 'N';
                return (

                  <Card
                    key={item.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      boxShadow: "4px 5px 6px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(1 0 0 / 14%), 4px 2px 1px 1px rgb(0 0 0 / 12%) !important",
                      background: "var(--card-bgcolor) !important",
                      borderRadius: "0.55rem",
                     
                      transition: 'transform 0.3s ease-in-out',
                      borderLeft: "6px solid var(--primary-color) !important",

                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      ...(isDisabled && {
                        opacity: 0.5,
                        pointerEvents: 'none',
                        backgroundColor: "lightgrey",
                      }),
                    }}
                  >
                    <CardContent sx={{ flex: 1 }}>
                      <FlexBetween>
                        <Typography
                          sx={{
                            color: "black",
                            fontWeight: "700!important",
                            fontSize: "14px!important",
                          }}
                          gutterBottom
                        >
                          ID: {item.id}
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1} >
                          <Tooltip title="Explore Projects">
                            <Link
                              to={`/projects/${item.id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleClick(item);
                              }}
                              style={{ color: 'var(--primary-color) !important', textDecoration: 'none' }}
                            >
                              <AddCircleOutlineIcon sx={styles.iconButton} />
                            </Link>
                          </Tooltip>
                          <Box
                            color="secondary"
                            onClick={() => {
                              if (item.option_write === "1") {
                                openInPopup(item);
                              }
                            }}
                            disabled={item.option_write !== "1"}
                          >
                            {item.option_write === "1" ? (
                              <ModeEditOutlineTwoToneIcon sx={{ ...styles.editButton, cursor: 'pointer' }} />
                            ) : (
                              <EditOffTwoToneIcon sx={{ ...styles.editButtonDisable, cursor: 'pointer' }} />
                            )}
                          </Box>
                        </Box>
                      </FlexBetween>
                      <Typography variant="h5" component="div"
                        sx={{
                          color: "var(--sidebar-select-color) !important",
                          fontWeight: "600!important",
                          fontFamily: "Poppins",
                          fontSize: "13px"
                        }}
                      >
                        {item.program_name}
                      </Typography>
                      <Typography variant="h6"
                        sx={{

                          color: "black",
                          fontSize: "12px"
                        }}
                      >
                        {item.primary_stakeholder.charAt(0).toUpperCase() + item.primary_stakeholder.slice(1)}
                      </Typography>
                      <Typography sx={{
                        fontSize: "12px",
                        color: "black",

                      }} >
                        {item.secondary_stakeholder.charAt(0).toUpperCase() + item.secondary_stakeholder.slice(1)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontFamily: "Poppins",
                          fontStyle: 'italic',
                        }}
                      >
                        {item.program_description}
                      </Typography>
                    </CardContent>

                  </Card>

                );
              })
            }
          </Box>

        ) : (
          <>Loading...</>
        )}
      </Box>
      <Popup
        title="Program Form"
        openPopup={openPopup}
        width={"sm"}
        setOpenPopup={setOpenPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <ObjectForm
            fieldsList={fieldsList}
            values={recordForEdit}
            addOrEdit={addOrEdit}
            object={"Program"}
          />)}
      </Popup>
      <Popup
        title="Programs"
        openPopup={openPopupHelp}
        width={"md"}
        okBtn={true}
        handleOkBtnClick={closeModal}
        setOpenPopup={setOpenPopupHelp}
      >
        <ReactMarkdown>{markdownContent}</ReactMarkdown>

      </Popup>
    </>
  );
};

export default Programs;
