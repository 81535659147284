import React, { useMemo } from 'react';
import { Handle, useStore } from 'reactflow';

const selector = (s) => ({
    nodeInternals: s.nodeInternals,
    edges: s.edges,
});

const CustomHandle = ({ isConnectable, ...props }) => {
    const { edges } = useStore(selector);

    const isHandleConnectable = useMemo(() => {
        const connectedEdges = edges.filter(edge => edge.sourceHandle === props.id || edge.targetHandle === props.id);
        console.log(connectedEdges);
        if (typeof isConnectable === 'function') {
            return isConnectable({ connectedEdges });
        }

        if (typeof isConnectable === 'number') {
            return connectedEdges.length < isConnectable;
        }

        return isConnectable;
    }, [edges, isConnectable]);

    return <Handle {...props} isConnectable={isHandleConnectable} />;
};

export default CustomHandle;