import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "../components/FlexBetween";
import {
  useGetConfigurationDetailsQuery,
  useGetConfigurationDetailsFormQuery,
  useAddObjectMutation,
  useUpdateObjectMutation,
} from "../state/apiSlice";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import Popup from "../components/Popup";
import ObjectForm from "../pages/ObjectForm";
import { ModeEditOutline } from "@mui/icons-material";
import useStyles from "useStyles";



const ConfigurationDetails = () => {
  const styles = useStyles();
  const { id } = useParams();

  const { data: configurationdetailsRecords, isLoading: isRecordLoading } =
    useGetConfigurationDetailsQuery(id);
  const { data: formData, isLoading: isFormLoading } =
    useGetConfigurationDetailsFormQuery("configuration_detail");
  const [addObject] = useAddObjectMutation();
  const [updateObject] = useUpdateObjectMutation();

  const [initValue, setInitValue] = useState(null);
  const [fieldsList, setFieldList] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  if (!isFormLoading && initValue === null && !isRecordLoading) {
    setInitValue(formData[0].initialvalues);
    setFieldList(formData[0].field_list);
  }

  const addOrEdit = async (values, resetForm) => {
    if (values?.id === 0) {
      await addObject({ object: "configuration_detail", data: values });
    } else {
      await updateObject({ object: "configuration_detail", data: values });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  let pageTitle = "Configuration Details";
  let pageSubTitle = "Manage Configuration Details";

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    // { field: "configuration_id", headerName: "configuration_id", flex: 0.5 },
    {
      field: "configuration_name",
      headerName: "Item",
      flex: 0.5,
    },
    { field: "display_sequence", headerName: "Seq#",align:"center", flex: 0.5 },
    { field: "key_01", headerName: "Key 01", flex: 0.5 },
    { field: "value_01", headerName: "Value 01", flex: 0.5 },
    { field: "required", headerName: "Required", flex: 0.25,align:"center" },
    { field: "key_02", headerName: "Key 02", flex: 0.5 },
    { field: "value_02", headerName: "Value 02", flex: 0.5 },
    { field: "field_type", headerName: "Type", flex: 0.5 },
    { field: "remarks", headerName: "Remarks", flex: 0.4 },
    { field: "is_active", headerName: "Active", flex: 0.35,align:"center" },
    {
      field: "update",
      headerName: "Update",
      flex: 0.3,
      align:"center",
      renderCell: ({ row }) => {
        return (
          <Button
            color="secondary"
            onClick={() => {
              openInPopup(row);
            }}
          >
            <ModeEditOutline sx={styles.editButton} />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <FlexBetween>
          <Header title={pageTitle} subtitle={pageSubTitle} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(initValue);
            }}
            sx={styles.myBackground}
            style={styles.myBorder}
          >
            Create
          </Button>
        </FlexBetween>
      </Box>
      <Box m="1.5rem 2.5rem">
        {configurationdetailsRecords || !isRecordLoading ? (
          <Box
            m="10px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "var(--tertiary-color) !important",
              },
              "& .MuiDataGrid-row.Mui-disabled-row": {
                backgroundColor: "lightgrey",
              },


              "& .MuiDataGrid-root": {
                border: "none",
                fontWeight: "600",
                fontSize: "12px !important",
                fontFamily: "Poppins", 
              },

              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color) !important",
                border: "none",
                color: "#ffffff!important",
                fontSize: "15px!important",
                fontWeight: "bold!important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                fontFamily: "Poppins",
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#f5f5f5",
                minHeight: "352px!important",
                // maxHeight: "323px!important",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "var(--pagination-bottom-color) !important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                borderRadius: "0 0 6px 6px",
                marginTop: "5px!important",
                marginTop: "5px!important",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "black!important",
                fontSize: "12px",
                width: "100px",
                marginBottom: "1px",
                fontFamily: "Poppins",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#4b437cd1!important",
              },
              "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                color: 'white', // Ensure filter icons and three-dot menu icons are white
              },

            }}
          >
            <DataGrid
              rowHeight={32}
              rows={configurationdetailsRecords}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "Mui-odd"
                  : "Mui-even"
              }
            />
          </Box>
        ) : (
          <>Loading...</>
        )}
      </Box>
      <Popup
        title="Configuration Details Form"
        openPopup={openPopup}
        width={"md"}
        setOpenPopup={setOpenPopup}
      >
        <ObjectForm
          fieldsList={fieldsList}
          values={recordForEdit}
          addOrEdit={addOrEdit}
          object={"configuration_detail"}
        />
      </Popup>
    </>
  );
};

export default ConfigurationDetails;
