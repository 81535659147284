import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "../components/FlexBetween";
import {
  useGetConfigurationQuery,
  useGetConfigurationFormQuery,
  useAddObjectMutation,
  useUpdateObjectMutation,
} from "../state/apiSlice";
import Header from "../components/Header";
import Popup from "../components/Popup";
import ObjectForm from "../pages/ObjectForm";
import { ModeEditOutline } from "@mui/icons-material";
import { Alert, AlertTitle } from '@mui/material';
import Breadcrumbs from "components/elements/Breadcrumbs";
import useStyles from "useStyles";


const Configuration = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { data: configurationRecords, isLoading: isRecordLoading, error: recordError } =
    useGetConfigurationQuery();
  const { data: formData, isLoading: isFormLoading, error: formError } = useGetConfigurationFormQuery("Configuration");
  const [addObject] = useAddObjectMutation();
  const [updateObject] = useUpdateObjectMutation();

  const [initValue, setInitValue] = useState(null);
  const [fieldsList, setFieldList] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  console.error("Error fetching form data:", formError);


  if (!isFormLoading && initValue === null && !isRecordLoading) {
    if (formError) {
      console.error("Error fetching form data:", formError);

    } else {
      setInitValue(formData[0]?.initialvalues);
      setFieldList(formData[0]?.field_list);
    }
  }

  const addOrEdit = async (values, resetForm) => {
    if (values?.id === 0) {
      await addObject({ object: "configuration", data: values });
    } else {
      await updateObject({ object: "configuration", data: values });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  let pageTitle = "Configuration";
  let pageSubTitle = "Manage Configuration";

  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 },
    { field: "configuration_name", headerName: "Configuration", flex: 0.6 },
    { field: "additional_detail", headerName: "Details", flex: 1 },
    { field: "is_active", headerName: "Active", flex: 0.25, headerAlign: "center",
      align: "center" },
    {
      field: "update",
      headerName: "Update",
      flex: 0.25,
       headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return (
          <Button
            color="secondary"
            onClick={() => {
              openInPopup(row);
            }}
          >
            <ModeEditOutline sx={styles.editButton} />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box m="1.5rem 2.5rem">
      {/* <Breadcrumbs breadcrumbPaths={breadcrumbPaths} /> */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={pageTitle} subtitle={pageSubTitle} />
          <Box display="flex" alignItems="center" gap={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(initValue);
            }}
            sx={styles.myBackground}
           
          >
            Create
          </Button>
          </Box>
        </Box>
      </Box>
      <Box m="1.5rem 2.5rem">
        {recordError ? (
          <Alert severity="error" style={{ width: 'fit-content', margin: 'auto' }}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            <Typography>{recordError?.data?.message}</Typography>
          </Alert>) : isRecordLoading ? (
            <p>Loading...</p>
          ) : configurationRecords ? (
            <Box
              m="10px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-row.Mui-odd": {
                  backgroundColor: "var(--tertiary-color) !important",
                },
                "& .MuiDataGrid-row.Mui-disabled-row": {
                  backgroundColor: "lightgrey",
                },
  
  
                "& .MuiDataGrid-root": {
                  border: "none",
                  fontWeight: "600",
                  fontSize: "12px !important",
                  fontFamily: "Poppins", 
                },
  
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "var(--primary-color) !important",
                  border: "none",
                  color: "#ffffff!important",
                  fontSize: "15px!important",
                  fontWeight: "bold!important",
                  lineHeight: "38px!important",
                  minHeight: "38px!important",
                  maxHeight: "38px!important",
                  fontFamily: "Poppins",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#f5f5f5",
                  minHeight: "352px!important",
                  // maxHeight: "323px!important",
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "var(--pagination-bottom-color) !important",
                  lineHeight: "38px!important",
                  minHeight: "38px!important",
                  maxHeight: "38px!important",
                  borderRadius: "0 0 6px 6px",
                  marginTop: "5px!important",
                  marginTop: "5px!important",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: "black!important",
                  fontSize: "12px",
                  width: "100px",
                  marginBottom: "1px",
                  fontFamily: "Poppins",
                },
                "& .MuiCheckbox-root.Mui-checked": {
                  color: "#4b437cd1!important",
                },
                "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                  color: 'white', // Ensure filter icons and three-dot menu icons are white
                },
  
              }}
            >
              <DataGrid
                 rowHeight={40}
                rows={configurationRecords}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0
                    ? "Mui-odd"
                    : "Mui-even"
                }
              />
            </Box>
          ) : (
          <>Loading...</>
        )}
      </Box>
      <Popup
        title="Configuration Form"
        openPopup={openPopup}
        width={"sm"}
        setOpenPopup={setOpenPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <ObjectForm
            fieldsList={fieldsList}
            values={recordForEdit}
            addOrEdit={addOrEdit}
            object={"Configuration"}
          />)}
      </Popup>
    </>
  );
};

export default Configuration;
