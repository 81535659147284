import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Element from "../components/Element";
import axios from '../api/axios';
import { selectCurrentToken } from "state/authSlice";
 
const TaskForm = (props) => {
  const accessToken = useSelector(selectCurrentToken);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { taskProps, formProps, checkTaskName } = props;
  const { fields_list: fieldsList } = taskProps;
  let taskParamFieldList = Object.assign([], fieldsList);
 
  taskParamFieldList.sort(function (a, b) {
    return a.display_sequence - b.display_sequence;
  });
 
  const checkTaskNameExists = async (task_name) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/tasks/taskexists/${task_name}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const exists = response.data.exists;
 
      if (exists) {
        // Manually set the error for task_name
        formProps.setFieldError('task_name', 'Task name already exists');
        return false;
      } else {
        formProps.setFieldError('task_name', undefined);  // Clear the error
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  const handleChange = (event) => {
    formProps.handleChange(event);
    const { name, value } = event.target;
 
    // Check if the field name is 'task_name' before calling checkTaskName
    if (name === 'task_name') {
      // Call checkTaskName if field name is 'task_name'
      checkTaskName(value, formProps.setFieldError, formProps.setErrors, formProps.errors);
    }
  };
 
  return (
    <Box>
      <Box
      margin="1.5rem"
      display="grid"
      rowGap="15px"
      columnGap="15px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      >
        {taskParamFieldList
          ? taskParamFieldList.map(
            (
              field,
              index //field_type, field_id, field_value, display_name,option_list
            ) => (
              <Element
                key={field.id}
                field={field}
                values={formProps.values}
                touched={formProps.touched}
                errors={formProps.errors}
                handleBlur={formProps.handleBlur}
                handleChange={handleChange}
              />
            )
          )
          : null}
      </Box>
    </Box>
  );
};
 
export default TaskForm;