export const dataQualitySubmit = async (
    data,
    values,
    addOrEditRecordDQ,

  ) => {
    console.log(data, "submitted");
    const updatedData = {
      ...data,
      DataQuality: data.DataQuality.map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
    };
    const cleanedData = JSON.parse(JSON.stringify(updatedData).replace(/"dq_/g, '"'));

    const restructuredData = [];

    // Add main task entries
    restructuredData.push(
      {
        "task_id": values.id,
        "task_type": "DataQuality",
        "parameter_type": "parameters",
        "key_01": "pre_check",
        "value_01": cleanedData.pre_check !== "" ? cleanedData.pre_check : false,
        "is_active": "Y",
        "created_by": "admin",
        "sequence": 1
      },
      {
        "task_id": values.id,
        "task_type": "DataQuality",
        "parameter_type": "parameters",
        "key_01": "post_check",
        "value_01": cleanedData.post_check !== "" ? cleanedData.post_check : false,
        "is_active": "Y",
        "created_by": "admin",
        "sequence": 2
      }
    );

    // Process DataQuality array
    cleanedData.DataQuality.forEach((item, idx) => {

      const parameterType = item.idx;
      const dynamicKeys = Object.keys(item).filter(key => key !== "idx");

      dynamicKeys.forEach((key, sequence) => {
        const restructuredItem = {
          "task_id": values.id,
          "task_type": "DataQuality",
          "parameter_type": parameterType,
          "key_01": key,
          "value_01": item[key],
          "is_active": "Y",
          "created_by": "admin",
          "sequence": sequence + 3 // Add 3 to start the sequence from 3
        };

        restructuredData.push(restructuredItem);
      });
    });

    // Output the restructured data
    console.log(restructuredData, "restructuredData");

    for (let item of restructuredData) {
      if (Array.isArray(item.value_01)) {
        item.value_01 = item.value_01.join(",");
      }
    }
    console.log(restructuredData, "restructuredData1");
    let taskRecordDQ = { details: restructuredData };

    addOrEditRecordDQ(taskRecordDQ, values.id);
};