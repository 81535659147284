import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, Tooltip } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Popup from '../Popup';
function SQLQueryComponent({
  field_id,
  display_name,
  field_type,
  field_value,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  ...otherProps
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [popupQuery, setPopupQuery] = useState('');

  const handleExecuteQuery = () => {
    const mainQuery = values != null ? values[field_id] || field_value : '';

    if (mainQuery != null && mainQuery !== '') {
      setPopupQuery(mainQuery);
      setShowPopup(true);
    } else {
      console.log('mainQuery is empty or null. Showing existing value:');
      setShowPopup(true);
    }
  };

  const handlePopupOK = () => {
    setShowPopup(false);
    handleChange(field_id)(popupQuery);
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (values[field_id]) {
      setPopupQuery(values[field_id]);
    }
  }, [values, field_id]);

  const handlePopupQueryChange = (event) => {
    setPopupQuery(event.target.value);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginLeft: "12px" }}>
      <TextField
        sx={{ width: "360px" }}
        variant="outlined"
        label={display_name}
        InputLabelProps={{
          style: { color: "black", alignItems: "left" },
        }}
        value={values != null ? values[field_id] || "select * from tbl_name" : ""}
        disabled
        placeholder="Enter your SQL query"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Override"
              >
                <ChangeCircleIcon
                  onClick={handleExecuteQuery}
                  sx={{
                    fontSize: 25,
                    color: "var(--primary-color) !important",
                    cursor: "pointer",
                    "&:hover": {
                      fontSize: 30, 
                      transform: "rotate(360deg)", 
                    },
                    transition: "font-size 0.3s ease-in-out, transform 0.5s ease-in-out",
                  }}
                />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />

      <Popup
        title="Enter Custom SQL Query"
        openPopup={showPopup}
        setOpenPopup={setShowPopup}
        width="sm"
        okBtn
        cancelBtn
        handleOkBtnClick={handlePopupOK}
        handleCancelBtnClick={handlePopupCancel}
      >
        <TextField
          sx={{
            marginTop: '16px',
            width: '100%',
            minWidth: '300px'
          }}
          id="src_query"
          value={popupQuery}
          placeholder="Enter your SQL query"
          multiline
          rows={20}
          onChange={handlePopupQueryChange}
        />
      </Popup>
    </div>
  );
}

export default SQLQueryComponent;
