import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Element from "../components/Element";

// Style for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

/**
 * Component for handling operations, including custom queries.
 * @param {Object} props - The component props.
 * @param {function} props.onSubmit - The function to call on form submission.
 * @param {Array} props.filterJson - The JSON data for filtering.
 * @param {Object} props.dataPrev - The previous data to populate the form.
 */
function CustomComponent({ onSubmit, filterJson, dataPrev }) {
  const [formValidation, setFormValidation] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [customQuery, setCustomQuery] = useState(false);

  /**
   * Function to get initial values for custom fields.
   * @param {Array} filterJson - The JSON data for filtering.
   * @returns {Object} The initial values for custom fields.
   */
  const getCustomInitialValues = (filterJson) => {
    try {
      const customInitialValues = {};
      for (const key in filterJson[0].initialvalues) {
        if (key.startsWith("custom")) {
          customInitialValues[key] = filterJson[0].initialvalues[key];
        }
      }
      return customInitialValues;
    } catch (error) {
      throw ("Error while fetching initial values", error);
    }
  };

  /**
   * Function to get custom field values from filterJson.
   * @param {Array} filterJson - The JSON data for filtering.
   * @returns {Array} The custom field values.
   */
  const getCustomFieldValues = (filterJson) => {
    try {
      const customFieldValues = [];
      const fieldsList = filterJson[0].fields_list;
      for (const field of fieldsList) {
        if (field.field_id.startsWith("custom")) {
          customFieldValues.push(field);
        }
      }
      return customFieldValues;
    } catch (error) {
      throw ("Error while getting field values", error);
    }
  };

  const fieldsList = getCustomFieldValues(filterJson);

  // Set form validation schema
  try {
    if (formValidation === null) {
      let validationObject = {};
      fieldsList?.forEach(
        ({ field_id, display_label, required }) => {
          if (required === "Y") {
            validationObject[field_id] = yup.string().required("Required");
          }
        }
      );
      const validationSchema = yup.object().shape(validationObject);
      setFormValidation(validationSchema);
    }
  } catch (error) {
    throw ("Validation error", error);
  }

  /**
   * Function to handle form submission.
   * @param {Object} values - The form values.
   */
  const handleFormSubmit = async (values) => {
    try {
      console.log(values);
      setConfirmation(true);
      setCustomQuery(values);
    } catch (error) {
      throw ("Error while saving custom", error);
    }
  };

  /**
   * Function to handle save confirmation.
   */
  const handleSaveConfirmation = () => {
    try {
      onSubmit(customQuery);
      setConfirmation(false);
      formik.resetForm();
    } catch (error) {
      throw error;
    }
  };

  // Sort fields by display sequence
  fieldsList.sort((a, b) => a.display_sequence - b.display_sequence);

  /**
   * Function to handle cancel action in the confirmation modal.
   */
  const handleCancel = () => {
    setConfirmation(false);
  };

  const formik = useFormik({
    initialValues: getCustomInitialValues(filterJson),
    validationSchema: formValidation,
    onSubmit: handleFormSubmit,
  });

  // Set form values from previous data
  useEffect(() => {
    formik.setValues(dataPrev);
  }, [formik.setValues, dataPrev]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" marginTop="20px" flexWrap="wrap" gap="20px">
          {fieldsList
            ? fieldsList.map((field) => (
                <Element
                  key={field.id}
                  field={field}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  disabled={field.field_id === "password"}
                />
              ))
            : null}
        </Box>
        <Box display="flex" justifyContent="start" marginLeft="12px" mt="20px">
          <Button type="submit"  sx={{
                        backgroundColor: "rgb(0, 72, 190)!important",
                        color: 'white',
                      }}>Update</Button>
        </Box>
      </form>
      <Modal
        open={confirmation}
        onClose={() => setConfirmation(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={() => setConfirmation(false)}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 3, textAlign: "center" }}>
            Operations and groups will get deleted and only custom will be saved
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleCancel}
              sx={{ bgcolor: "grey.500" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveConfirmation}
              sx={{
                backgroundColor: "rgb(0, 72, 190)!important",
                color: 'white',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default CustomComponent;