// ToggleButtonElement.js
import React from "react";
import {
    ToggleButton,
    ToggleButtonGroup,
    Avatar,Box
  } from "@mui/material";

function ToggleButtonElement(props) {
  const { alignment, value, connList, handleChange } = props;
  const tabElements = connList?.map((item, index) => {
    return (
      <ToggleButton
      sx={{
        color: "var(--primary-color) ",
        fontWeight: "700!important",
        display: "flex",
        fontFamily: "Poppins",
        backgroundColor: "lightgray", // Default background color
        alignItems: "center",
        height: "40px", // Set a consistent height for all buttons
        minWidth: "150px",
        '&.Mui-selected': {
          backgroundColor: "var(--primary-color)", // Background color when selected
          color: "white", // Text color when selected (optional)
        },
      }}
      key={item}
      value={item}
      aria-label="left aligned"
    >
      <Avatar
        alt={item}
        sx={{
          color: "black",
          width: 25, // Set a consistent width for the circle
          height: 25, // Set a consistent height for the circle
          mr: 1.25,
          backgroundColor: "white", // Set the background to white
          borderRadius: "50%", // Make the avatar circular
          objectFit: "cover", // Ensure the image fits within the circle
        }}
        src={`assets/${item.toLowerCase().trim().replace(" ", "_")}.png`}
      >
        {item}
      </Avatar>
      <Box>{item}</Box>
    </ToggleButton>
    
    );
  });
  return (
    <>
      <ToggleButtonGroup
        orientation={alignment}
        value={value}
        exclusive
        onChange={handleChange}
        aria-label="Connection Type"
      >
        {tabElements}
      </ToggleButtonGroup>
    </>
  );
}

export default ToggleButtonElement;
