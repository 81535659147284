import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";


export default function OperatorComponent({ columnInfo, onUpdateColumnInfo, operatorsData }) {
  const [selectedOperatorType, setSelectedOperatorType] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedOperatorData, setSelectedOperatorData] = useState({
    description: "",
    syntax: "",
    example: "",
  });
  const [expression, setExpression] = useState("");

  useEffect(() => {
    if (columnInfo && columnInfo.operatorType && columnInfo.operator) {
      setSelectedOperatorType(columnInfo.operatorType);
      setSelectedOperator(columnInfo.operator);
      setExpression(columnInfo.expression_value);
      const operatorInfo =
        operatorsData[columnInfo.operatorType][columnInfo.operator];
      setSelectedOperatorData({
        description: operatorInfo["Operator Description"],
        syntax: operatorInfo["Operator Syntax"],
        example: operatorInfo["Operator Example"],
      });
    } else {
      const defaultOperatorType = Object.keys(operatorsData)[0];
      const defaultOperator = Object.keys(
        operatorsData[defaultOperatorType]
      )[0];
      setSelectedOperatorType(defaultOperatorType);
      setSelectedOperator(defaultOperator);
      const operatorInfo = operatorsData[defaultOperatorType][defaultOperator];
      setSelectedOperatorData({
        description: operatorInfo["Operator Description"],
        syntax: operatorInfo["Operator Syntax"],
        example: operatorInfo["Operator Example"],
      });
      setExpression(operatorInfo["Operator Syntax"]);
    }
  }, [columnInfo]);

  const handleOperatorTypeChange = (event) => {
    const selectedOperatorType = event.target.value;
    setSelectedOperatorType(selectedOperatorType);
    const defaultOperator = Object.keys(operatorsData[selectedOperatorType])[0];
    setSelectedOperator(defaultOperator);
    const operatorInfo = operatorsData[selectedOperatorType][defaultOperator];
    setSelectedOperatorData({
      description: operatorInfo["Operator Description"],
      syntax: operatorInfo["Operator Syntax"],
      example: operatorInfo["Operator Example"],
    });
    setExpression(operatorInfo["Operator Syntax"]);
  };

  const handleOperatorChange = (event) => {
    const selectedOperator = event.target.value;
    setSelectedOperator(selectedOperator);
    const operatorInfo = operatorsData[selectedOperatorType][selectedOperator];
    setSelectedOperatorData({
      description: operatorInfo["Operator Description"],
      syntax: operatorInfo["Operator Syntax"],
      example: operatorInfo["Operator Example"],
    });
    setExpression(operatorInfo["Operator Syntax"]);
  };

  const handleExpressionChange = (event) => {
    setExpression(event.target.value);
  };

  const handleAddButtonClick = () => {
    if (!columnInfo) {
      return;
    }
    const updatedColumnInfo = {
      ...(columnInfo || {}),
      expression_value: expression,
      operatorType: selectedOperatorType,
      operator: selectedOperator,
    };

    // Call the callback function with updated columnInfo
    onUpdateColumnInfo(updatedColumnInfo);
  };

  return (
    <Box sx={{ flex: 1, width: "100%", height: "50%" }}>
      <Paper sx={{ p: 2}}>
        <Box sx={{ pb: 2, borderBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold", mb: 1, fontFamily: "Poppins" }}>Operation:</Typography>
          <Box
            className="Operator-dropdown"
            sx={{
              display: "flex",
              gap: '10px' // Gap between the dropdowns
            }}
          >
            <Select
              value={selectedOperatorType}
              onChange={handleOperatorTypeChange}
              sx={{
                width: '50%' // Set width of the first Select
              }}
            >
              {Object.keys(operatorsData).map((operatorType, index) => (
                <MenuItem key={index} value={operatorType}>
                  {operatorType}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={selectedOperator}
              onChange={handleOperatorChange}
              sx={{
                width: '50%' // Set width of the second Select
              }}
            >
              {selectedOperatorType &&
                operatorsData[selectedOperatorType] &&
                Object.keys(operatorsData[selectedOperatorType]).map((operator, index) => (
                  <MenuItem key={index} value={operator}>
                    {operator}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box sx={{ mb: 1, mt: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: "medium", mb: 1, fontFamily: "Poppins" }}
            >
              Expression
            </Typography>
            <TextField
              variant="outlined"
              placeholder="expression"
              value={expression}
              onChange={handleExpressionChange}
              fullWidth
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'var(--primary-color) !important',
                color: 'white',
                fontFamily: "Poppins", mt: 2
              }}
              onClick={handleAddButtonClick}
            >
              {columnInfo?.expression_value !== "" ? "Update" : "Add"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{mb: 1, fontFamily: "Poppins" }}>
            Description
          </Typography>
          <Typography variant="body2">
            {selectedOperatorData.description}
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ fontFamily: "Poppins", mb: 1 }}>
            Syntax
          </Typography>
          <Typography variant="body2">{selectedOperatorData.syntax}</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{  fontFamily: "Poppins", mb: 1 }}>
            Example
          </Typography>
          <Typography variant="body2">
            {selectedOperatorData.example}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
