import React from "react";
import { Box } from "@mui/material";
import Element from "../components/Element";
import ToggleButtonForTaskParams from "./ToggleButtonForTaskParams";

const BulkIngestionParamsForm = (props) => {
  const { taskProps, formProps, handleConnSubTypeChange } = props;
  const {
    connSubTypeList,
    currSrcConnSubType: currConnSubType,
    fieldList: fields_list,
  } = taskProps;

  let taskParamFieldList = Array.isArray(fields_list) ? [...fields_list] : [];

  taskParamFieldList.sort((a, b) => a.display_sequence - b.display_sequence);
  
  try {
    taskProps.fieldList.forEach(field => {
      const { field_id } = field;
      if (formProps.values[field_id] === undefined) {
        formProps.setFieldValue(field_id, '');
      }
    });
  } catch (error) {
    console.log("Error while reseting form values", error);
  }
  
  

  const handleConnTypeChange = (event, newValue) => {
    handleConnSubTypeChange(newValue);
  };

  return (
    <>
      <Box display="flex" flexDirection="row" sx={{ m: "3rem 1.5rem" }}>
        <Box>
          {connSubTypeList ? (
            <ToggleButtonForTaskParams
              alignment="vertical"
              value={currConnSubType}
              connSubList={connSubTypeList}
              handleChange={handleConnTypeChange}
            />
          ) : null}
        </Box>


        <Box flexGrow={1}>
          <Box
            display="grid"
            gap="8px"
            gridTemplateColumns="repeat(2, 1fr)"
          >

            {taskParamFieldList
              ? taskParamFieldList?.map((field, index) => (
                index % 1 === 0 && (
                  <div key={`row_${index}`}>
                    <Element
                      key={field.id}
                      field={field}
                      values={formProps.values}
                      touched={formProps.touched}
                      errors={formProps.errors}
                      handleBlur={(e) => { }}
                      handleChange={formProps.handleChange}
                    />


                  </div>
                )
              ))
              : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BulkIngestionParamsForm;
