import React from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  MarkerType,
  getBezierPath,
  useReactFlow,
} from "reactflow";

import { getSmoothStepPath } from 'reactflow';

import "./css/edgebutton.css";

function EdgeLabel({ transform, label }) {
  return (
    <div
      style={{
        position: "absolute",
        background: "transparent",
        padding: 5,
        color: "black",
        fontSize: 8,
        fontWeight: 400,
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

const DefaultEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style = {},
  markerEnd = { type: MarkerType.ArrowClosed },
}) => {

  const [edgePath, labelX, labelY] =getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { setEdges } = useReactFlow();

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-100%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 15,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <button className="edgebutton" onClick={onEdgeClick}>
            ×
          </button>
        </div>
        {/* {data.endLabel && (
        )} */}
        <EdgeLabel
          transform={`translate(-0%, -100%) translate(${labelX}px,${labelY + 8.5
            }px)`}
          label={data?.label}
        />

      </EdgeLabelRenderer>
    </>
  );
};
export default DefaultEdge;