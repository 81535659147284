import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  useLazyGetSourceColumnsQuery,
  useLazyGetSourceColumnsForFileQuery,
  useLazyGetPreviewFileQuery,
  useGetConnectionByIdQuery,
  useLazyGetPreviewDatabaseQuery,
} from "../state/apiSlice";

import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreviewData from "../components/PreviewData";

import TaskParamsForm from "./TaskParamsForm";

import CloseIcon from "@mui/icons-material/Close";
import TaskForm from "./TaskForm";
import { values } from "lodash";
import axios from '../api/axios';
import { selectCurrentToken } from "state/authSlice";
import { useSelector } from "react-redux";

const styles = {
  btnBack: {
    backgroundColor: "#111111",
    color: "#FFFFFF",
    marginRight: "15px",
  },
  btnNext: {
    backgroundColor: "rgb(0, 72, 190)!important",
    color: "#FFFFFF",
    fontWeight: "800",
  },
  btnPreview: {
    top: "-30px",
    // backgroundColor: "#1E6738",
    float: "right",
    color: "#FFFFFF",
    fontWeight: "600",
  },
};

const findSectionField = (formObject, section, field = null) => {
  const sectionRecord = formObject?.find((rec) => rec.section === section);
  if (field) {
    const fieldRecord = sectionRecord.fields_list.find(
      (rec) => rec.field_id === field
    );
    return fieldRecord;
  }
  return sectionRecord;
};

const TaskParameters = (props) => {
  const {
    taskFormFields,
    values: editValue,
    addOrEditRecord,
    formType,
  } = props;
  const accessToken = useSelector(selectCurrentToken);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [getSourceColumns] = useLazyGetSourceColumnsQuery();
  const [getSourceColumnsForFile] = useLazyGetSourceColumnsForFileQuery();

  const [getPreviewFile] = useLazyGetPreviewFileQuery();
  const [getPreviewDatabase] = useLazyGetPreviewDatabaseQuery();

  const mainRecord = findSectionField(taskFormFields, "main");
  const sourceRecord = findSectionField(taskFormFields, "source");
  const targetRecord = findSectionField(taskFormFields, "target");
  const mainForm = mainRecord.fields_list;
  const sourceFormData = sourceRecord.fields_list;
  const targetFormData = targetRecord.fields_list;
  const sourceConnSubTypeList = sourceFormData
    .map((rec) => rec.connection_subtype)
    .filter((rec, index, _arr) => _arr.indexOf(rec) === index);
  const targetConnSubTypeList = targetFormData
    .map((rec) => rec.connection_subtype)
    .filter((rec, index, _arr) => _arr.indexOf(rec) === index);

  const [currSrcConnSubType, setCurrSrcConnSubType] = useState(null);
  const [currTgtConnSubType, setCurrTgtConnSubType] = useState(null);
  const [sourceForm, setSourceForm] = useState(null);
  const sourceFormFieldIds = sourceForm?.field_list?.map(
    (field) => field.field_id
  );
  const [targetForm, setTargetForm] = useState(null);
  const targetFormFieldIds = targetForm?.field_list?.map(
    (field) => field.field_id
  );
  const [previewInfo, setPreviewInfo] = useState(null);

  function changeConnSubType(type, connSubType) {
    if (type === "source") {
      setCurrSrcConnSubType(connSubType);
      const srcSelectedItem = sourceFormData.filter(
        (item) => item.connection_subtype === connSubType
      );
      setSourceForm(srcSelectedItem[0]);
    }

    if (type === "target") {
      setCurrTgtConnSubType(connSubType);
      const tgtSelectedItem = targetFormData.filter(
        (item) => item.connection_subtype === connSubType
      );
      setTargetForm(tgtSelectedItem[0]);
    }
  }

  function validateFormSchema(formData) {
    let validSchemaObject = {};
    formData.forEach(({ field_id, display_label, required }) => {
      if (required === "Y") {
        validSchemaObject[field_id] = yup
          .string()
          .required(`${field_id} is required`);
      }
      if (field_id === "task_name") {
        validSchemaObject[field_id] = validSchemaObject[field_id].matches(
          /^[a-zA-Z0-9_]+$/,
          `${field_id} must be alphanumeric and may contain underscores only`
        );
      }
    });
    return yup.object().shape(validSchemaObject);
  }

  function validateSchemaObject(section) {
    let validationObject = {};

    switch (section) {
      case "main":
        if (mainForm != null) {
          validationObject[section] = validateFormSchema(mainForm);
        }
        break;

      case "source":
        if (sourceForm != null) {
          validationObject[section] = validateFormSchema(
            sourceForm?.field_list
          );
        }
        break;
      case "target":
        if (targetForm != null) {
          validationObject[section] = validateFormSchema(
            targetForm?.field_list
          );
        }
        break;
      default:
        break;
    }

    return validationObject;
  }

  if (formType === "source" && currSrcConnSubType === null) {
    if (editValue && editValue.source !== "none") {
      changeConnSubType("source", editValue.source);
    } else {
      changeConnSubType("source", sourceFormData[0].connection_subtype);
    }
  }

  if (formType === "target" && currTgtConnSubType === null) {
    if (editValue && editValue.target !== "none") {
      changeConnSubType("target", editValue.target);
    } else {
      changeConnSubType("target", targetFormData[0].connection_subtype);
    }
  }

  let initValues = {};
  if (editValue === null || editValue === undefined) {
    initValues = {
      ...mainForm?.initialvalues,
      ...sourceForm?.initialvalues,
      ...targetForm?.initialvalues,
    };
  } else {
    const { details, ...taskRecord } = editValue;
    if (Object.keys(details).length !== 0) {
      const { Source, Target } = details;
      let detailRecord = Object.assign({}, Source, Target);
      initValues = { ...taskRecord, ...detailRecord };
    } else {
      initValues = { ...taskRecord };
    }

  }
  initValues.src_chunk_size = initValues?.src_chunk_size || 10000;

  const validationSchema = validateSchemaObject(formType);
  const [validationSchemaa, setValidationSchemaa] = useState(() => validateSchemaObject(formType)); // Initialize state
 
  useEffect(() => {
    const schema = validateSchemaObject(formType);
    setValidationSchemaa(schema[formType]); // Update the state when formType changes
  }, [formType,currSrcConnSubType,currTgtConnSubType]); // Dependency array with formType
  const [errors, setErrors] = useState(null);
  const checkTaskNameExists = async (task_name, setFieldError, validateForm, setFieldValue) => {
    console.log("Coming to check this")
    try {
      const response = await axios.get(
        `${baseURL}/api/tasks/taskexists/${task_name}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const exists = response.data.exists;
      updateValidationSchema(exists);
      console.log(exists,"ExistsExists")
      // Trigger validation immediately after schema update
      if (exists) {
        console.log("ExistsExists")
        setFieldError('task_name', 'Task name already exists');
      } else {
        // Clear the error if the connection name does not exist
        setFieldError('task_name', undefined);
      }
 
      // setFieldValue('task_name', task_name, true);
    } catch (error) {
      console.error(error);
    }
  };
 
  const updateValidationSchema = (exists) => {
    setValidationSchemaa((prevSchema) =>
      prevSchema.shape({
        ...prevSchema.fields,
        task_name: yup.string()
          .test(
            'unique-connection-name',
            'Task name already exists',
            () => !exists
          )
          .matches(/^\w+$/, 'Task Name must be alphanumeric and may contain underscores only')
          .required('Task Name is required'),
      })
    );
  };
  if (sourceFormData === null || targetFormData === null) {
    setErrors("Form data for Main, Source or Target can't be null.");
  }


  const formik = useFormik({
    initialValues: initValues,
    validationSchema: validationSchemaa,
    onSubmit: (values, { resetForm }) => {
      const taskNameRegex = /^[a-zA-Z0-9_]+$/;
      if (!taskNameRegex.test(values?.task_name)) {
        return;
      }
      handleFormSubmit(values, resetForm);
    },
  });

  function handleSettingUpInitialValues(updatedInitialValues) {
    try {
      formik.setValues(updatedInitialValues);
    } catch (error) {
      console.log("Error while setting up values", error)
    }
  }

  if (formik?.values?.src_header === null) {
    formik.values.src_header = "N"
  }

  const formTask = (formikProps) => {
    return (
      <TaskForm
        taskProps={mainRecord}
        formProps={formikProps}
        checkTaskName = {checkTaskNameExists}
        object={"Task"}
      />
    );
  };
  const formSourceTask = (formikProps, sourceConnType) => {
    return (
      <TaskParamsForm
        taskProps={{
          connSubTypeList: sourceConnSubTypeList,
          currSrcConnSubType: sourceConnType,
          fieldList: sourceForm?.field_list,
        }}
        formProps={formikProps}
        handleConnSubTypeChange={handleSourceConnSubTypeChange}
        getValuesForFormikSet={handleSettingUpInitialValues}
        object={"Source"}
      />
    );
  };

  const formTargetTask = (formikProps, targetConnType) => {
    return (
      <TaskParamsForm
        taskProps={{
          connSubTypeList: targetConnSubTypeList,
          currSrcConnSubType: targetConnType,
          fieldList: targetForm?.field_list,
        }}
        formProps={formikProps}
        handleConnSubTypeChange={handleTargetConnSubTypeChange}
        getValuesForFormikSet={handleSettingUpInitialValues}
        object={"Target"}
      />
    );
  };

  function handleSourceConnSubTypeChange(subTypeValue) {
    changeConnSubType("source", subTypeValue);
  }

  function handleTargetConnSubTypeChange(subTypeValue) {
    changeConnSubType("target", subTypeValue);
  }

  const handleFormSubmit = async (values, resetForm) => {
    const mainFormFields = mainForm
      .map((rec) => rec.field_id)
      .filter((rec, index, _arr) => _arr.indexOf(rec) === index);

    let taskParameterValues = {};
    let srcParameterType, tgtParameterType;
    const removeFields = [
      "id",
      "is_active",
      "source",
      "target",
      "dqcount",
      "project_name",
      "pipeline_name",
    ];

    let tblTask = {
      is_active: "Y",
      id: values?.id || 0,
    };
    /* collecting Task fields for table tbl_task. */
    mainFormFields.forEach((prop) => (tblTask[prop] = values[prop]));

    switch (formType) {
      case "source":
        if ("tgt_parameter_type" in values) {
          tgtParameterType = values.tgt_parameter_type;
          srcParameterType = currSrcConnSubType;
        }
        //acc- accumulator
        taskParameterValues = {
          ...Object.keys(values).reduce((acc, fieldId) => {
            if (!fieldId.startsWith("src_")) {
              acc[fieldId] = values[fieldId];
            }
            return acc;
          }, {}),
          src_parameter_type: currSrcConnSubType,
          ...sourceFormFieldIds.reduce((acc, fieldId) => {
            if (fieldId.startsWith("src_")) {
              acc[fieldId] = values[fieldId];
            }
            return acc;
          }, {}),
        };
        break;

      case "target":
        if ("src_parameter_type" in values) {
          srcParameterType = values.src_parameter_type;
          tgtParameterType = currTgtConnSubType;
        }
        taskParameterValues = {
          ...Object.keys(values).reduce((acc, fieldId) => {
            if (!fieldId.startsWith("tgt_")) {
              acc[fieldId] = values[fieldId];
            }
            return acc;
          }, {}),
          tgt_parameter_type: currTgtConnSubType,
          ...targetFormFieldIds.reduce((acc, fieldId) => {
            if (fieldId.startsWith("tgt_")) {
              acc[fieldId] = values[fieldId];
            }
            return acc;
          }, {}),
        };
        break;

      default:
        break;
    }
    mainFormFields.forEach((prop) => (tblTask[prop] = values[prop]));
    mainFormFields.forEach((prop) => delete taskParameterValues[prop]);
    removeFields.forEach((prop) => delete taskParameterValues[prop]);

    const taskDetails = [];
    Object.keys(taskParameterValues).forEach((key, index) => {
      let parameter_type = null;

      if (key.includes("src_")) {
        parameter_type = srcParameterType;
      } else if (key.includes("tgt_")) {
        parameter_type = tgtParameterType;
      }
      let taskType = "DataQuality";

      if (key.includes("src_")) {
        taskType = "Source";
      } else if (key.includes("tgt_")) {
        taskType = "Target";
      }
      taskDetails.push({
        task_type: taskType,
        parameter_type,
        key_01: key.replace("src_", "").replace("tgt_", ""),
        value_01: taskParameterValues[key],
        sequence: index + 1,
        is_active: "Y",
      });
    });

    let taskRecord = { ...tblTask, details: taskDetails };

    try {
      addOrEditRecord(taskRecord, resetForm);
      if (taskRecord?.id === 0) {
        toast.success(`Task Created Successfully`, { position: "top-center" });
      } else {
        toast.success(`Task Updated Successfully`, { position: "top-center" });
      }
    } catch (error) {
      console.error("Form Submission Error: ", error);
      toast.error("Form Submission Error");
    }
  };
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePreview = async () => {
    let data = {};
    let filetype;
    let connectionType;
    try {
      setLoading(true);
      if (formType === "source") {
        connectionType = currSrcConnSubType.toLowerCase().replace(/\s/g, "");
        filetype = formik.values.src_file_type;
        if (
          connectionType === "remoteserver" ||
          connectionType === "awss3" ||
          connectionType === "localserver"
        ) {
          data = {
            connection_id: formik.values.src_connection_name,
            table_name: formik.values.src_file_name,
            filepath: formik.values.src_file_path,
            header: formik.values.src_header,
            file_type: formik.values.src_file_type,
            delimiter: formik.values.src_delimiter,
             encoding: "utf-8",
          };
        } else {
          data = {
            connection_id: formik.values.src_connection_name,
            table_name: formik.values.src_table_name,
            schema_name: formik.values.src_schema,
            header: formik.values.src_select_columns,
            query: formik.values.src_query_params,
            content_type: formik.values.src_content_type,
            src_query: formik.values.src_query,
          };
        }
      }

      if (formType === "target") {
        connectionType = currTgtConnSubType.toLowerCase().replace(/\s/g, "");
        filetype = formik.values.tgt_file_type;
        if (
          connectionType === "awss3" ||
          connectionType === "localserver" ||
          connectionType === "remoteserver"
        ) {
          data = {
            connection_id: formik.values.tgt_connection_name,
            table_name: formik.values.tgt_file_name,
            filepath: formik.values.tgt_file_path,
            header: formik.values.tgt_header,
            file_type: formik.values.tgt_file_type,
            delimiter: formik.values.tgt_delimiter,
            encoding: "utf-8",
          };
        } else {
          data = {
            connection_id: formik.values.tgt_connection_name,
            table_name: formik.values.tgt_table_name,
            schema_name: formik.values.tgt_schema,
            content_type: formik.values.tgt_content_type,
          };
        }
      }

      let response;

      if (
        connectionType === "awss3" ||
        connectionType === "localserver" ||
        connectionType === "remoteserver"
      ) {
        response = await getPreviewFile({
          connectionType,
          filetype,
          params: data,
        });
      } else {
        response = await getPreviewDatabase({ connectionType, params: data });
      }

      if (response.isSuccess) {
        setPreviewInfo(response.data);
        setOpen(true);
        setError(null); // Clear any previous errors
      } else {
        throw new Error(response.error.data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Set loading state back to false after fetching preview
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const [options, setOptions] = useState([]);
  const [actualoptions, setActualOptions] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  // Replace 'initialSourceRecord' with the initial value of sourceRecord
  const handleChange = async () => {
    if (formType === "source") {
      const srcconnectionType = currSrcConnSubType
        .toLowerCase()
        .replace(/\s/g, "");

      let data;
      if (
        srcconnectionType === "awss3" ||
        srcconnectionType === "localserver" ||
        srcconnectionType === "remoteserver"

      ) {
        const response = await getSourceColumnsForFile({
          srcconnectionType,
          src_connection_name: formik.values.src_connection_name,
          src_file_type: formik.values.src_file_type,
          src_file_name: formik.values.src_file_name,
          src_file_path: formik.values.src_file_path,
          src_delimiter: formik.values.src_delimiter,
          src_encoding: "utf-8",
        });
        data = response.data;
      } else {
        const response = await getSourceColumns({
          srcconnectionType,
          src_connection_name: formik.values.src_connection_name,
          src_table_name: formik.values.src_table_name,
          src_schema_name: formik.values.src_schema,
        });
        data = response.data;
      }

      setIsFetching(true);

      if (data) {
        const aliasColumnsObject = data.metaDataInfo.map(
          (item) => item.aliasColumns
        );
        const aliasColumnsArray = Object.values(aliasColumnsObject);
        const actualColumnsObject = data.metaDataInfo.map(
          (item) => item.actualColumns
        );
        const actualColumnsArray = Object.values(actualColumnsObject);
        setOptions(aliasColumnsArray);
        setActualOptions(actualColumnsArray);
        assignOptionList("src_select_columns", aliasColumnsArray);
        const aliasColumnsString = aliasColumnsArray.join(",");

        formik.values.src_alias_columns = aliasColumnsString;
      }

      setIsFetching(false);
    }
  };

  const assignOptionList = (fieldId, options) => {
    if (sourceForm && Array.isArray(sourceForm.field_list)) {
      const updatedFieldList = sourceForm.field_list.map((field) => {
        if (
          field.field_id === fieldId &&
          (field.field_type === "multiselect" || field.option_list === null)
        ) {
          return { ...field, option_list: options };
        }
        return field;
      });

      setSourceForm({ ...sourceForm, field_list: updatedFieldList });
    }
  };

  const assignOptionList1 = (fieldId, actualoptions, options) => {
    if (sourceForm && Array.isArray(sourceForm.field_list)) {
      const updatedFieldList1 = sourceForm.field_list.map((field) => {
        if (field.field_id === "src_query") {
          if (formik.values.src_table_name) {
            if (formik.values.src_select_columns) {
              // Split the columns into an array
              const selectedColumns =
                formik.values.src_select_columns.split(",");
              // Create a mapping object for aliases to actual columns
              const aliasToActualMap = {};
              options.forEach((alias, index) => {
                const actualCol = actualoptions[index];
                aliasToActualMap[alias] = actualCol;
              });
              const formattedColumns = selectedColumns.map((selectedColumn) => {
                const actualCol =
                  aliasToActualMap[selectedColumn] || selectedColumn;
                if (actualCol === selectedColumn) {
                  return actualCol;
                } else {
                  return `${actualCol} AS ${selectedColumn.replace(/`/g, '')}`;
                }
              });
              if (initValues.src_query === undefined) {
                formik.values.src_query = `SELECT ${formattedColumns.join(', ')} FROM ${formik.values.src_schema}.${formik.values.src_table_name}`;

              } else {
                formik.values.src_query = initValues.src_query;
                if (
                  formik.values.src_select_columns !==
                  initValues.src_select_columns
                ) {
                  formik.values.src_query = `SELECT ${formattedColumns.join(', ')} FROM ${formik.values.src_schema}.${formik.values.src_table_name}`;

                }
              }
            } else {
              formik.values.src_query = `SELECT * FROM ${formik.values.src_schema}.${formik.values.src_table_name}`;
            }
          } else {
            formik.values.src_query = "";
          }
        }
        return field;
      });

      setSourceForm({ ...sourceForm, field_list: updatedFieldList1 });
    }
  };

  useEffect(() => {
    if (formik.values && formik.values.src_connection_name) {
      handleChange();
    }
  }, [formik.values, formik.values.src_connection_name, URL]);

  useEffect(() => {
    if (formik.values.src_table_name || formik.values.src_select_columns) {
      assignOptionList1("src_select_columns", actualoptions, options);
    }
  }, [formik.values.src_table_name, formik.values.src_select_columns]);
  let isButtonEnabled = false;
  let val = formik.values;

  if (formType === "source") {
    if (currSrcConnSubType === "REST API") {
      isButtonEnabled =
        (!!val?.src_content_type &&
          !!val?.src_connection_name)

    } else {
      isButtonEnabled =
        (!!val?.src_file_name &&
          !!val?.src_file_path &&
          !!val?.src_connection_name) ||
        (!!val?.src_schema &&
          !!val?.src_table_name &&
          !!val?.src_connection_name);
    }
  } else {
    if (currTgtConnSubType === "REST API") {
      isButtonEnabled =
        (!!val?.tgt_content_type &&
          !!val?.tgt_connection_name)

    } else {
      isButtonEnabled =
        (!!val?.tgt_table_name &&
          !!val?.tgt_schema &&
          !!val?.tgt_connection_name) ||
        (!!val?.tgt_file_name &&
          !!val?.tgt_file_path &&
          !!val?.tgt_connection_name);
    }
  }

  useEffect(() => {
    if (options.length > 0) {
      assignOptionList("src_select_columns", options);
    }
  }, [options]);
  let formComponent = null;

  if (formType === "main") {
    formComponent = formTask(formik);
  } else if (formType === "source") {
    formComponent = formSourceTask(formik, currSrcConnSubType);
  } else if (formType === "target") {
    formComponent = formTargetTask(formik, currTgtConnSubType);
  }

  const id = formik?.values?.src_connection_name; // Replace with your actual ID
  const [filepath, setFilePath] = useState(null);
  const [filename, setFileName] = useState(null);
  const { data, isLoading, isError } = useGetConnectionByIdQuery(id || null);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const connectionDetails = data[0]?.connection_details;
      if (Array.isArray(connectionDetails) && connectionDetails.length > 0) {
        const file_path = connectionDetails[1]?.file_path || connectionDetails[0]?.file_path;
        const file_name = connectionDetails[0]?.file_name || connectionDetails[1]?.file_name;
        setFilePath(file_path);
        setFileName(file_name);
      } else {
        console.log('Connection details array is empty.');
      }
    }
  }, [data, isLoading, isError]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  if (formik?.values?.src_connection_name !== undefined && formik?.values?.src_file_path == undefined) {
    formik.values.src_file_path = filepath;
    // initValues.src_file_path =initValues?.src_file_path||filepath;
    formik.values.src_file_name = filename;
  }


  return (
    <>
      {errors ? (
        <Box display="flex" flexDirection="row" sx={{ m: "3rem 1.5rem" }}>
          <Typography variant="h4">{errors}</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              {formComponent}
            </Grid>

            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box display="flex" justifyContent="end" sx={{ flexDirection: "row", gap: "16px",mt:"15px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={formik.handleSubmit}
                  sx={{
                    backgroundColor: "var(--primary-color) !important",
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                  }}
                >
                  {formType === "main" ? "CREATE TASK" : "SUBMIT"}
                </Button>

                {formType === "source" || formType === "target" ? (
                  <Button
                    variant="contained"
                    onClick={handlePreview}
                    style={{
                      backgroundColor: loading
                        ? "var(--fifth-color) !important"
                        : isButtonEnabled
                          ? "var(--primary-color) !important"
                          : "var(--secondary-color) !important",
                      position: "relative",
                      overflow: "hidden",
                      fontFamily: "Poppins",
                    }}
                    disabled={!isButtonEnabled || loading}
                  >
                    {loading ? (
                      <span style={{ display: "inline-block", color: "white"}}>
                        PREVIEWING
                        <span
                          style={{
                            position: "absolute",
                            overflow: "hidden",
                            width: "0",
                            animation: "ellipsis 35s steps(4, start) infinite",
                            
                          }}
                        >
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </span>
                      </span>
                    ) : (
                      <span style={{ color: "white"}}>PREVIEW</span>
                    )}
                  </Button>
                ) : null}
              </Box>
              <ToastContainer />

              <br />
              <br />

              <style>
                {`
        @keyframes ellipsis {
          0% { width: 3%; }
          20% { width: 10%; }
          40% { width: 20%; }
          60% { width: 35%; }
          80% { width: 45%; }
          100% { width: 55%; }
        }
      `}
              </style>

              {error && (
                <div
                  style={{
                    backgroundColor: "rgb(7, 0, 76)",
                    color: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    fontFamily: "Poppins",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "10px" }}>⚠️</div>{" "}
                      <div style={{ fontWeight: "bold" }}>INFORMATION</div>
                    </div>
                  
                      <CloseIcon style={{ fontSize: "20px",cursor:"pointer" }}  onClick={handleClose} />
                  
                  </div>
                  <div style={{ flex: "1", marginTop: "3px" }}>{error}</div>{" "}
                </div>
              )}
            </Grid>
          </Grid>


          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                marginTop: "75px",
                minWidth: "90%",
                height: "90vh",
                maxWidth: "none",
                maxHeight: "none",
                resize: true,
              },
            }}
          >
            <DialogTitle
              style={{
                backgroundColor: "#0cf5ea8a",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {formType === "source" ? (
                <>
                  {(() => {
                    const connectionType = currSrcConnSubType?.toLowerCase().replace(/\s/g, "");
                    return connectionType === "awss3" ||
                      connectionType === "localserver" || connectionType === "restapi" || connectionType === "remoteserver" ? (
                      <> <center></center>
                        PREVIEWING :{" "}
                        {formik?.values?.src_file_name || "REST API"}
                      </>
                    ) : (
                      <> <center></center>
                        PREVIEWING DATABASE TABLES : {" "}
                        {formik?.values?.src_table_name}
                      </>
                    );
                  })()}
                </>
              ) : null}

              {
                formType === "target" ? (
                  <>
                    {(() => {
                      const connectionType = currTgtConnSubType?.toLowerCase().replace(/\s/g, "");
                      return connectionType === "awss3" ||
                        connectionType === "localserver" || connectionType === "restapi" || connectionType === "remoteserver" ? (
                        <> <center></center>
                          PREVIEWING :{" "}
                          {formik?.values?.tgt_file_name || "REST API"}
                        </>
                      ) : (
                        <> <center></center>
                          PREVIEWING DATABASE TABLES : {" "}
                          {formik?.values?.tgt_table_name}
                        </>
                      );
                    })()}
                  </>
                ) : null}

              <IconButton onClick={handleClose} color="inherit">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <PreviewData
                handleClose={handlePreview}
                previewInfo={previewInfo}
              />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default TaskParameters;
