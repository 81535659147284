import { Box, useTheme, Typography } from "@mui/material";
import HomepageBackground2 from "../assets/HomepageBackground2.png";

const Home = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '95vh', // Ensure the Box covers the full height of the viewport
        backgroundImage: `url(${HomepageBackground2})`, // Use your image URL here
        backgroundSize: 'cover', // Ensure the image covers the entire area
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Prevent repeating the image
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end', // Align items to the right
        px: 4, // Add padding on the x-axis to ensure some space from the edges
        color: theme.palette.secondary[100],
      }}
    >
      <Typography
        variant="h1" // Use a large font size
        component="div"
        sx={{
          textAlign: 'center', // Center the text
          maxWidth: '50%', // Limit the width to keep it from stretching too much
          padding: 2, // Add padding for spacing
          borderRadius: 2, // Add rounded corners for the background
          fontWeight: 'bold', // Make the text bold
          fontSize: '2.5rem', // Adjust the font size to make it bigger
          color: "var(--primary-color)", // Use your primary color
          fontFamily: "Poppins",
        }}
      >
        NextGen Ingestion - Integration Framework
      </Typography>
    </Box>
  );
};

export default Home;
