import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";

const MultiSelect = (props) => {
  const {
    field_id,
    display_name,
    options,
    handleBlur,
    handleChange,
    touched,
    errors,
    values,
  } = props;

  const selectedValues = values[field_id] ? values[field_id].split(",") : [];
  const allOptionsSelected = selectedValues.length === (options?.length || 0);

  const handleMultiSelectChange = (event, newValue) => {
    if (newValue.includes("selectAll")) {
      const remainingOptions = (options || [])
        .map((item) => item.id || item)
        .filter((option) => option !== "selectAll");

      handleChange({
        target: {
          name: field_id,
          value: allOptionsSelected ? "" : remainingOptions.join(","),
        },
      });
    } else {
      // Extract relevant value from objects if options are objects
      const selectedValues = newValue.map(option => typeof option === "object" ? option.id : option);
      handleChange({
        target: {
          name: field_id,
          value: selectedValues.join(","),
        },
      });
    }
  };

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      {option === "selectAll" ? (
        <React.Fragment>
          <Checkbox
            style={{ color: selected ? "black" : "inherit" }}
            checked={allOptionsSelected}
          />
          <span>Select All</span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Checkbox
            style={{ color: selected ? "black" : "inherit" }}
            checked={selected}
          />
          {option.name || option}
        </React.Fragment>
      )}
    </li>
  );

  return (
    <FormControl sx={{ gridColumn: "span 4", width: "360px!important", marginLeft: "12px", minHeight: "10px" }}>
      <Autocomplete
        multiple
        id={field_id}
        options={["selectAll", ...(options || [])]}
        disableCloseOnSelect
        freeSolo // Allow input text
        value={selectedValues}
        onChange={handleMultiSelectChange}
        onBlur={handleBlur}
        getOptionLabel={(option) => (option === "selectAll" ? "Select All" : option.name || option)}
        renderOption={renderOption}
        renderTags={(value, getTagProps) => (
          <div style={{ maxHeight: "50px", overflowY: "auto" }}>
            {value.map((option, index) => (
              <Chip
                key={index}
                label={option === "selectAll" ? "Select All" : option.name || option}
                {...getTagProps({ index })}
              />
            ))}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            label={display_name}
            InputLabelProps={{
              style: { color: "black",fontSize: "15px" },
              shrink:true,
            }}
            error={touched[field_id] && errors[field_id]}
            helperText={touched[field_id] && errors[field_id]}
          />
        )}
      />
    </FormControl>
  );
};

export default MultiSelect;
