
import React, { useCallback } from "react";
import CustomHandle from "./CustomHandle";
import { Handle, Position } from "reactflow";
import { useDeleteNode } from "./DeleteNodeContext";
export default function OtherCustom({
    id,
    data: { label, displayName }
  }) {
    const onDelete = useDeleteNode(); // Use the hook to get the delete function
  
    const handleDelete = useCallback(() => {
      onDelete(id); // Trigger the onDelete function with the node ID
    }, [onDelete, id]);
    let content=null
    let deleteButton = (
        <div
            className="deleteButton"
            onClick={(e) => {
                e.stopPropagation();
                handleDelete();
            }}
            style={{ top: '0', right: '0' }} >
            x
        </div>
    );
    switch (label) {
        case 'Expression':
            content = (
                <>
                    <div className="nodeStyle" style={{ border: '1px solid black' }}>
                        <CustomHandle
                            type="target"
                            position={Position.Left}
                            isConnectable={1}
                            id={`handle_${id}`}
                        />
                        <div className="textStyle">{displayName}</div>
                        <Handle
                            type='source'
                            position={Position.Right}
                        />
                        {deleteButton}
                    </div>
                </>
            );
            break;
        case 'Filter':
            content = (
                <div className="nodeStyle" style={{ border: '1px solid black' }}>
                    <CustomHandle
                        type="target"
                        position={Position.Left}
                        isConnectable={1}
                        id={`handle_${id}`}
                    />
                    <Handle
                        type='source'
                        position={Position.Right}
                    />
                    <div className="textStyle">{displayName}</div>
                    {deleteButton}
                </div>
            );
            break;
            case 'SQL':
                content = (
                    <div className="nodeStyle" style={{ border: '1px solid black' }}>
                        <CustomHandle
                            type="target"
                            position={Position.Left}
                            // isConnectable={4}
                            id={`handle_${id}`}
                        />
                        <Handle
                            type='source'
                            position={Position.Right}
                        />
                        <div className="textStyle">{displayName}</div>
                        {deleteButton}
                    </div>
                );
                break;
                case 'Python':
                    content = (
                        <div className="nodeStyle" style={{ border: '1px solid black' }}>
                            <CustomHandle
                                type="target"
                                position={Position.Left}
                                // isConnectable={4}
                                id={`handle_${id}`}
                            />
                            <Handle
                                type='source'
                                position={Position.Right}
                            />
                            <div className="textStyle">{displayName}</div>
                            {deleteButton}
                        </div>
                    );
                    break;
        case 'Input':
            content = (
                <div className="nodeStyle" style={{ border: '1px solid black' }}>
                    <Handle
                        type='source'
                        position={Position.Right}
                    />
                    <div className="textStyle">{displayName}</div>
                    {deleteButton}
                </div>
            );
            break;

        case 'Joiner':
            content = (
                <div className="nodeStyle" style={{ border: '1px solid black' }}>
                    <CustomHandle
                        type="target"
                        position={Position.Left}
                        isConnectable={1}
                        style={{ top: 5, background: '#555' }}
                        id={`handle_${id}`}
                    />
                    <Handle
                        type='source'
                        position={Position.Right}
                    />
                    <div className="textStyle">{displayName}</div>
                    <CustomHandle
                        type="target"
                        position={Position.Left}
                        isConnectable={1}
                        style={{ top: 16, background: '#555' }}
                        id={`handle_${id}1`}
                    />
                    {deleteButton}
                </div>
            );
            break;

        case 'Output':
            content = (
                <div className="nodeStyle" style={{ border: '1px solid black' }}>
                    <CustomHandle
                            type="target"
                            position={Position.Left}
                            isConnectable={1}
                            id={`handle_${id}`}
                        />
                    <div className="textStyle">{displayName}</div>
                    {deleteButton}
                </div>
            );
            break;

        default:
            break;
    }
    return content;
}
