import React from 'react';

const useStyles = () => {
  return {
    myBackground: {
      fontSize: "13px",
      fontWeight: "600",
      borderRadius: "3px",
      backgroundColor: "var(--primary-color) !important",
      color: "#FFFFFF!important",
      textTransform: "uppercase",
      padding: "4px 16px", // Adjust padding to match the height of the TextField
      height: "35px", // Ensure consistent height
      fontFamily: "Poppins",
    },
    myBackgroundToggle: {
      fontSize: "13px",
      fontWeight: "600",
      borderRadius: "3px",
      backgroundColor: "var(--primary-color) !important",
      color: "#FFFFFF!important",
      textTransform: "uppercase",
      padding: "4px 16px", // Adjust padding to match the height of the TextField
      height: "35px", // Ensure consistent height
      fontFamily: "Poppins",
    },
      logger: {
        color: "white",
        textTransform: "none",
        borderRadius: "6px!important",
        minWidth: "10px!important",
        minHeight: "10px!important",
        marginTop: "0px!important",
        marginLeft: "3px",
        lineHeight: 1,
      },
      auditStatus: {
        fontSize: "12px",
        fontWeight: "bold",
        borderRadius: "6px",
        color: "white",
        textTransform: "none",
        lineHeight: 1,
        width: "70px",
        padding: "7px 9px",
      },
      editButton: {
        fontSize: "16px",
        borderRadius: "6px!important",
        color: "var(--primary-color)",
      },
      editButtonDisable: {
        fontSize: "16px",
        borderRadius: "6px!important",
        color: "var(--primary-color)",
      },
      body: {
        overflow: 'hidden', /* Prevent scrollbar on the main page */
      }, 
      gridLinkBtn: {
        fontSize: "12px",
        fontWeight: "bold",
        borderRadius: "6px",
        backgroundColor: "#5adbb5!important",
        color: "#03112e!important",
        textTransform: "none",
        lineHeight: 1,
        width: "auto",
        padding: "7px 9px",
      }, 
      cssBtnTest: {
        fontSize: "12px",
        fontWeight: "600",
        borderRadius: "4px",
        backgroundColor: "var(--primary-color) !important",
        color: "#FFFFFF!important",
        padding: "6px 8px", 
        fontFamily: "Poppins"
      },
      cssBtnResets: {
        fontSize: "12px",
        fontWeight: "600",
        borderRadius: "4px",
        backgroundColor: "var(--primary-color) !important",
        color: "#FFFFFF!important",
        padding: "6px 8px", 
        fontFamily: "Poppins"
      },
      cssBtnEnabled: {
        fontSize: "12px",
        fontWeight: "600",
        borderRadius: "4px",
        backgroundColor: "var(--primary-color) !important",
        color: "#FFFFFF!important",
        padding: "6px 8px", 
        fontFamily: "Poppins"
      },
      cssBtnDisabled: {
        fontSize: "12px",
        fontWeight: "600",
        borderRadius: "4px",
        backgroundColor: "var(--secondary-color) !important",
        color: "#FFFFFF!important",
        padding: "6px 8px", 
        fontFamily: "Poppins"
      },
      statusBar: {
        position: 'fixed',
        bottom: 10,
        right: 10,
        backgroundColor: 'white',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        zIndex: 1000,
        width: '300px',
      },
      statusBarTitle: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        marginBottom: '5px',
      },
      notificationIcon: {
        marginRight: '5px',
        fontSize: '1.5em',
      },
      issueList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
      },
      issueContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      issueIcon: {
        marginRight: '5px',
        fontSize: '1.5em',
        flexShrink: 0,
      },
      issueText: {
        flex: 1,
        wordWrap: 'break-word',
      },
      criticalIssue: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderLeft: '5px solid #f5c6cb',
        padding: '10px',
        marginBottom: '5px',
        borderRadius: '3px',
      },
      warningIssue: {
        backgroundColor: '#fff3cd',
        color: '#856404',
        borderLeft: '5px solid #ffeeba',
        padding: '10px',
        marginBottom: '5px',
        borderRadius: '3px',
      },
      successMessage: {
        backgroundColor: '#d4edda',
        color: '#155724',
        borderLeft: '5px solid #c3e6cb',
        padding: '10px',
        marginBottom: '5px',
        borderRadius: '3px',
      },
      errorMessage: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderLeft: '5px solid #f5c6cb',
        padding: '10px',
        marginBottom: '5px',
        borderRadius: '3px',
      },
      errorMsg: {
        fontWeight: "400",
        color: "red",
        fontSize: "12px",
        fontFamily: "Poppins",
      },
      btnAdmin: {
        fontSize: "14px",
        fontWeight: "800",
        borderRadius: "6px",
        //boxshadow: "grey 0px 0px 5px!important",
        backgroundColor: "var(--primary-color) !important",
        color: "#FFFFFF",
        textTransform: "upper",
        lineHeight: 1.5,
        width: "80px!important",
        position: "absolute",
        zIndex: "1",
        marginLeft: "-350px",
        marginTop: "90px",
      },
       btnTextCss: {
        fontSize: "13px",
        fontWeight: "600",
        color: "var(--primary-color) !important",
        textTransform: "upper",
        lineHeight: 1.5,
        marginLeft: "5px",
        fontFamily:"Poppins"
        //  width:"200px",
      },
}
};

export default useStyles;
