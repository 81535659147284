import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
 
const Input = ({
  field_id,
  display_name,
  field_type,
  field_value,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  ...otherProps
}) => {
  const [openPopup, setOpenPopup] = useState(false);
 
  const handleEyeIconClick = (field_id) => {
    setOpenPopup(field_id);
  };
 
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  let leftColumns = [];
  let rightColumns = [];
 
  const select_columns = values?.select_columns;
  if (select_columns) {
    const columns = select_columns.split(',');
 
    columns.forEach(item => {
      if (item.endsWith('_right')) {
        rightColumns.push(item);
      } else if (item.endsWith('_left')) {
        leftColumns.push(item);
      }
    });
  }
 
 
 
  const columnsToDisplay = display_name === 'Right Source Dataframe' ? rightColumns : leftColumns;
 
 
  return (
    <>
      <TextField
        id="standard-basic"
        fullWidth
        variant="outlined"
        type={field_type}
        label={display_name}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values != null ? values[field_id] : ""}
        InputLabelProps={{
          style: { color: "black",fontSize:"15px"},
          shrink: true // Force label to shrink always
        }}
        name={field_id}
        error={!!touched[field_id] && !!errors[field_id]}
        helperText={touched[field_id] && errors[field_id]}
        InputProps={{
          endAdornment:
            (display_name === "Left Source Dataframe" ||
              display_name === "Right Source Dataframe") && (
              <InputAdornment position="end">
                <IconButton onClick={() => handleEyeIconClick(field_id)} edge="end">
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          readOnly:
            field_id === "input_col_name" || field_id === "expression_value",
        }}
        sx={{
          gridColumn: "span 4",
          width:
            field_id === "input_col_name" ||
            field_id === "output_col_name" ||
            field_id === "expression_value"
              ? "160px!important"
              : "360px!important",
          marginLeft: "12px",
          minHeight: "10px",
          display:
            field_id === "input_df" || field_id === "output_df" ? "none" : "",
        }}
        {...otherProps}
      />
 
      <Dialog open={openPopup === field_id} onClose={handleClosePopup}>
      <DialogTitle style={{ color: 'black', fontWeight: 'bold' }}>
        Select Values for {display_name}
        </DialogTitle>
      <DialogContent>
  <ul>
    {columnsToDisplay.map((column, index) => (
      <li key={index}>{column}</li>
    ))}
  </ul>
</DialogContent>
 
      <DialogActions>
        <Button
              sx={{ backgroundColor: "rgb(0, 72, 190)!important", color: "white" }}
              onClick={handleClosePopup}
              >
                Close
                </Button>
      </DialogActions>
      </Dialog>
    </>
  );
};
 
export default Input;