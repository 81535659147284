
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Element from "../components/Element";
import { useFormik } from "formik";

function Group({ onSubmit, isEditing, filterJson, displayOper, dataPrev }) {
  // const dataPrev = useSelector((state) => state.counter.formDataPreview);
  // const displayOper = useSelector((state) => state.counter.displayOperations);
  const [formValidation, setFormValidation] = useState(null);

  const getGroupInitialValues = (filterJson) => {
    try {
      const groupInitialValues = {};
      for (const key in filterJson[0].initialvalues) {
        if (key.startsWith("group")) {
          groupInitialValues[key] = filterJson[0].initialvalues[key];
        }
      }
      return groupInitialValues;
    } catch (error) {
      console.log("Error while fetching group initial values", error);
      return {}; //return empty object in case of error
    }
  };

  const getGroupFieldValues = (filterJson) => {
    try {
      const groupFieldValues = [];
      const fieldsList = filterJson[0].fields_list;
  
      for (const field of fieldsList) {
        if (field.field_id.startsWith("group")) {
          if (
            field.field_id === "group_column1" ||
            field.field_id === "group_column2"
          ) {
            const modifiedArray = displayOper.map((value) => {
              return { id: value, name: value };
            });
            
            const updatedField = { ...field, option_list: modifiedArray };
            groupFieldValues.push(updatedField);
          } else {
            groupFieldValues.push(field);
          }
        }
      }
      return groupFieldValues;
    } catch (error) {
      console.log("Error while fetching group field values");
      return []; 
    }
  };
  
  const fieldsList = getGroupFieldValues(filterJson);

  if (formValidation === null) {
    let validationObject = {};
    fieldsList?.forEach(
      ({ field_id, display_label, required, field_value }) => {
        if (required === "Y") {
          validationObject[field_id] = yup.string().required("Required");
        }
      }
    );
    const validationSchema = yup.object().shape(validationObject);
    setFormValidation(validationSchema);
  }

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values);
    onSubmit(values);
    formik.resetForm();
  };

  fieldsList.sort(function (a, b) {
    return a.display_sequence - b.display_sequence;
  });

  const formik = useFormik({
    initialValues: getGroupInitialValues(filterJson),
    validationSchema: formValidation,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    if (isEditing) {
      formik.setValues(dataPrev);
    }
  }, [isEditing, formik.setValues, dataPrev]);

  return (
    <>
      <div style={{ maxWidth: "800px" }}>
        <form onSubmit={formik.handleSubmit}>
        <p style={{marginLeft : "12px"}}>GROUP FORM</p>
          <Box
            display="flex"
            flexWrap="wrap"
            gap="20px"
            marginTop="20px"
          >
            {fieldsList
              ? fieldsList.map((field) => (
                  <Element
                    key={field.id}
                    field={field}
                    values={formik.values}
                    touched={formik.touched}
                    errors={formik.errors}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    disabled={isEditing && field.field_id === "password"}
                  />
                ))
              : null}
          </Box>
          <Box display="flex" marginLeft="12px" mt="20px">
            <Button type="submit" sx={{ backgroundColor: "rgb(0, 72, 190)!important", color: "white" }} >Update</Button>
          </Box>
        </form>
      </div>
    </>
  );
}
export default Group;

