import { useForm, useFieldArray, Controller } from "react-hook-form";

import {
  Box,
  Button,
  makeStyles,
  useTheme,
  TextareaAutosize,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ModeEditOutline } from "@mui/icons-material";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import VisibilityIcon from "@mui/icons-material/Visibility";

import { DataGrid } from "@mui/x-data-grid";

import {
  useAddTaskParameterSQLMutation,
  useUpdateTaskParameterSQLMutation,
  useGetTasksSqlExecutionQuery,
} from "../state/apiSlice";

import { useState } from "react";

import { handleSqlExecutionSubmit } from "../services/sqlLogic.controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "useStyles";







const findSectionField = (formObject, section, field = null) => {
  const sectionRecord = formObject.find((rec) => rec.section === section);

  if (field) {
    const fieldRecord = sectionRecord.fields_list.find(
      (rec) => rec.field_id === field
    );

    return fieldRecord;
  }

  return sectionRecord;
};

const SqlExecutionForm = ({
  taskFormFields,

  values,

  addOrEditRecord,

  formType,
}) => {
  const styles = useStyles();
  const { data: tasksRecords } = useGetTasksSqlExecutionQuery(values.id);

  const [addTaskParameterSQL] = useAddTaskParameterSQLMutation();

  const [updateTaskParameterSQL] = useUpdateTaskParameterSQLMutation();

  const theme = useTheme();

  const editValue = {
    connection_name: "",

    restart: "",

    SqlExecution: [],
  };

  editValue.connection_name =
    values.details?.SqlExecution?.parameters?.se_connection_name;

  editValue.restart = values.details?.SqlExecution?.parameters?.se_restart;

  // Append SqlExecution values

  if (values.details?.SqlExecution?.SqlExecution !== undefined) {
    const cleanedData = JSON.parse(
      JSON.stringify(values.details.SqlExecution).replace(/"se_/g, '"')
    );

    const sqlExecutions = cleanedData?.SqlExecution || [];

    for (const item of sqlExecutions) {
      editValue.SqlExecution.push(item);
    }
  }

  const [editRowIndex, setEditRowIndex] = useState(null);

  const [viewRowIndex, setViewRowIndex] = useState(null);

  const [data] = useState(editValue);

  const sqlExecutionRecord = findSectionField(taskFormFields, formType);
  const [selectedOption, setSelectedOption] = useState(data.restart || "");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleEditClick = (rowData) => {
    setEditRowIndex(rowData);

    setFormData({
      sql_seq_no: rowData.sql_seq_no || "",

      table_used_desc: rowData.table_used_desc || "",

      sql_query: rowData.sql_query || "",
    });
  };

  const handleViewClick = (rowData) => {
    setEditRowIndex(null);

    setViewRowIndex(rowData);

    setFormData({
      sql_seq_no: rowData.sql_seq_no || "",

      table_used_desc: rowData.table_used_desc || "",

      sql_query: rowData.sql_query || "",
    });
  };

  const handleDelete = (rowData) => {
    const rowIndex = fields.findIndex((row) => row === rowData);

    if (rowIndex !== -1) {
      remove(rowIndex);
    }
  };

  const handleMoveUp = (sql_seq_no) => {
    const currentIndex = fields.findIndex(
      (row) => row.sql_seq_no === sql_seq_no
    );
    if (currentIndex > 0) {
      const updatedFields = [...fields];
      const temp = updatedFields[currentIndex];
      updatedFields[currentIndex] = updatedFields[currentIndex - 1];
      updatedFields[currentIndex - 1] = temp;

      updatedFields[currentIndex].sql_seq_no = currentIndex + 1;
      updatedFields[currentIndex - 1].sql_seq_no = currentIndex;

      remove(updatedFields);

      append(updatedFields);
    }
  };

  const handleMoveDown = (sql_seq_no) => {
    const currentIndex = fields.findIndex(
      (row) => row.sql_seq_no === sql_seq_no
    );

    if (currentIndex < fields.length - 1) {
      const updatedFields = [...fields];
      const temp = updatedFields[currentIndex];
      updatedFields[currentIndex] = updatedFields[currentIndex + 1];
      updatedFields[currentIndex + 1] = temp;

      updatedFields[currentIndex].sql_seq_no = currentIndex + 1;
      updatedFields[currentIndex + 1].sql_seq_no = currentIndex + 2;

      remove(updatedFields);
      append(updatedFields);
    }
  };

  const handleCancelEdit = () => {
    setEditRowIndex(null); // Clear the edit mode

    setFormData({
      sql_seq_no: "",

      table_used_desc: "",

      sql_query: "",
    });
  };

  let maincomponent = JSON?.parse(
    JSON.stringify(sqlExecutionRecord["fields_list"][1]["main"])
  );

  let option_list = maincomponent?.option_list;

  let maincomponent1 = JSON?.parse(
    JSON.stringify(sqlExecutionRecord["fields_list"][2]["main"])
  );

  let option_list1 = maincomponent1?.option_list;

  const { register, handleSubmit, control } = useForm({ defaultValues: data });

  const { fields, append, remove, update } = useFieldArray({
    control,

    name: "SqlExecution",
  });

  const addOrEditRecordSqlexe = async (values, row) => {
    if (tasksRecords?.data[0]?.id > 0) {
      await updateTaskParameterSQL({ data: values, task_id: row });
      toast.success(`SQL Orchestration Updated Successfully`, { position: "top-center" });

    } else {
      await addTaskParameterSQL({ data: values });
      toast.success(`SQL Orchestration Created Successfully`, { position: "top-center" });

    }
  };
  const handleButtonClick = () => {
    if (!formData?.sql_query && !formData?.sql_seq_no) {
      console.error("Please enter a value for sql_query");
      return;
    }
    if (editRowIndex !== null) {
      const updatedFields = fields.map((row) => {
        if (row.sql_seq_no === formData.sql_seq_no) {
          return {
            ...row,
            table_used_desc: formData.table_used_desc || "",
            sql_query: formData.sql_query || "",
          };
        }
        return row;
      });

      remove(updatedFields);
      append(updatedFields);
    } else if (viewRowIndex !== null) {
      setEditRowIndex(null);
      setViewRowIndex(null);
    } else {
      append(formData);
    }

    setEditRowIndex(null);
    setFormData({
      sql_seq_no: "",
      table_used_desc: "",
      sql_query: "",
    });
  };
  const onSubmit = (data) => {
    handleSqlExecutionSubmit(data, values, addOrEditRecordSqlexe);
  };

  const [formData, setFormData] = useState({
    sql_seq_no: "",

    table_used_desc: "",

    sql_query: "",
  });

  const handleTextFieldChange = (name, value) => {
    setFormData({
      ...formData,

      [name]: value,
    });
  };

  const columns = [
    { field: "sql_seq_no", headerName: "SEQ #", width: 60 },

    { field: "table_used_desc", headerName: "Description", width: 150 },

    { field: "sql_query", headerName: "SQL Query", width: 300 },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      align: "center",
      renderCell: (params) => {
        const rowData = params.row;

        return (
          <DeleteOutlineIcon
            style={{
              color: "red", cursor: "pointer", fontSize: "18px"
            }}
            onClick={() => {
              handleDelete(rowData);
            }}
          />
        );
      },
    },

    {
      field: "edit",
      width: 50,
      headerName: "Edit",
      align: "center",
      renderCell: (params) => {
        const rowData = params.row;

        return (

          <ModeEditOutline onClick={() => {
            handleEditClick(rowData);
          }}
            style={{
              color: "var(--primary-color) !important", cursor: "pointer", fontSize: "18px"
            }}
          />

        );
      },
    },

    {
      field: "view",
      headerName: "View",
      align: "center",
      width: 50,
      renderCell: (params) => {
        const rowData = params.row; // Access the entire row data

        return (
          <VisibilityIcon onClick={() => {
            handleViewClick(rowData);
          }} style={{
            color: "var(--primary-color) !important", cursor: "pointer", fontSize: "18px"
          }} />

        );
      },
    },
    {
      field: "moveUp",
      align: "center",
      width: 70,
      headerName: "Move Up",
      renderCell: (params) => {
        const sql_seq_no = params.row.sql_seq_no; // Get the sql_seq_no
        return (
          <Box
            component="button"
            variant="outlined"
            type="button"
            onClick={() => handleMoveUp(sql_seq_no)} // Pass sql_seq_no
            style={{

              background: "none",
              border: "none",
              padding: 0,
              margin: 0,
              color: "var(--primary-color)",
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
            }}
            disabled={params.row === 0}
          >
            <ArrowUpwardIcon />
          </Box>
        );
      },
    },
    {
      field: "moveDown",
      align: "center",
      width: 90,
      headerName: "Move Down",
      renderCell: (params) => {
        const sql_seq_no = params.row.sql_seq_no;
        return (
          <Box
            component="button"
            variant="outlined"
            type="button"
            onClick={() => handleMoveDown(sql_seq_no)} // Pass sql_seq_no
            style={{

              background: "none",
              border: "none",
              padding: 0,
              margin: 0,
              color: "var(--primary-color)",
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
            }}
            disabled={params.row === fields.length - 1}
          >
            <ArrowDownwardIcon />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box style={{ display: "flex", gap: "20px", marginBottom: "15px" }}  >
          <Box style={{ width: "50vw" }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
              }}
            >
              Database Connections
            </Typography>
            <Box
              display="grid"
              gap="10px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
            >
              <Controller
                name="connection_name"
                control={control}
                defaultValue={data.connection_name || ""}
                render={({ field }) => (
                  <Select {...field} sx={{ width: "100%" }}>
                    {option_list.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
          <Box style={{ width: "50vw" }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
              }}
            >
              Restart
            </Typography>
            <Box
              display="grid"
              gap="10px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
            >
              <Controller
                name="restart"
                control={control}
                defaultValue={data.restart || ""}
                render={({ field }) => (
                  <Select {...field} sx={{ width: "100%" }}>
                    {option_list1.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.id}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ marginBottom: "15px" }}>
          <Typography variant="h5"
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "15px",
              marginBottom: "15px"
            }} >
            SQL Execution
          </Typography>

          <Box style={{ display: "flex", gap: "20px" }}>
            <Box style={{ width: "50vw" }}>
              <TextField
                fullWidth
                label="SQL Sequence No"
                variant="outlined"
                type="text"
                name="sql_seq_no"
                value={formData.sql_seq_no}
                onChange={(e) =>
                  handleTextFieldChange("sql_seq_no", e.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black" },
                }}
                autoComplete="off"
                sx={{ marginBottom: "18px" }}
              />
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                type="text"
                name="table_used_desc"
                value={formData.table_used_desc}
                onChange={(e) =>
                  handleTextFieldChange("table_used_desc", e.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black" },
                }}
                autoComplete="off"
              />
            </Box>

            <Box style={{ width: "50vw" }}>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={4.5}
                name="sql_query"
                value={formData.sql_query}
                onChange={(e) =>
                  handleTextFieldChange("sql_query", e.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black" },
                }}
                autoComplete="off"
                label="SQL Query"
              />
            </Box>
          </Box>
          <Box style={{ display: "flex", gap: "8px", marginTop: "15px", justifyContent: "flex-end" }} >
            <Button type="button" onClick={handleButtonClick} sx={styles.myBackground}>
              {editRowIndex !== null
                ? "Update"
                : viewRowIndex !== null
                  ? "Cancel"
                  : "Add"}
            </Button>
            <Button
              type="button"
              onClick={() => {
                setEditRowIndex(null);
                setViewRowIndex(null);
                setFormData({
                  sql_seq_no: "",
                  table_used_desc: "",
                  sql_query: "",
                });
              }}
              sx={styles.myBackground}
            >
              Reset
            </Button>
          </Box>
        </Box>
        <hr />
        <Box>
          {" "}
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "15px",
            }}
          >
            Task Summary
          </Typography>
          <Box style={{ height: 400 }}>
            <Box
              m="10px 0 0 0"
              height="50vh"
              sx={{
                "& .MuiDataGrid-row.Mui-odd": {
                  backgroundColor: "var(--tertiary-color) !important",
                },
                "& .MuiDataGrid-row.Mui-disabled-row": {
                  backgroundColor: "lightgrey",
                },


                "& .MuiDataGrid-root": {
                  border: "none",
                  fontWeight: "500",
                  fontSize: "11px !important",
                  fontFamily: "Poppins",
                },

                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "var(--primary-color) !important",
                  border: "none",
                  color: "#ffffff!important",
                  fontSize: "13px!important",
                  fontWeight: "bold!important",
                  lineHeight: "38px!important",
                  minHeight: "38px!important",
                  maxHeight: "38px!important",
                  fontFamily: "Poppins",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#f5f5f5",
                  minHeight: "352px!important",
                  // maxHeight: "323px!important",
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "var(--pagination-bottom-color) !important",
                  lineHeight: "38px!important",
                  minHeight: "38px!important",
                  maxHeight: "38px!important",
                  borderRadius: "0 0 6px 6px",
                  marginTop: "5px!important",
                  marginTop: "5px!important",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: "black!important",
                  fontSize: "12px",
                  width: "100px",
                  marginBottom: "1px",
                  fontFamily: "Poppins",
                },
                "& .MuiCheckbox-root.Mui-checked": {
                  color: "#4b437cd1!important",
                },
                "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                  color: 'white', // Ensure filter icons and three-dot menu icons are white
                },

              }}
            >
              <DataGrid
                rows={fields}
                columns={columns}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>

          <Button type="submit" variant="contained" sx={styles.myBackground}>
            Submit
          </Button>
        </Box>
        <ToastContainer />
      </form>
    </Box>
  );
};

export default SqlExecutionForm;
