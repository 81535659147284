import React, { useRef, useEffect } from "react";
import { TextField, Card, CardContent, Typography } from "@mui/material";
import { VscTerminalPowershell } from "react-icons/vsc";
import { FaCode } from "react-icons/fa";

const HelpCardSQL = () => (
  <Card
    style={{
      maxHeight: 300,
      maxWidth: 375,
      margin: "9px",
      padding: "3px",
      border: "2px solid #ffdb4e",
      boxShadow: "0 0 8px #b48505",
    }}
  >
    <CardContent>
      <Typography variant="h6" component="div">
        SQL Node
      </Typography>
      <Typography variant="body2" color="textSecondary">
        The SQL node is designed to take in custom SQL queries by the user to
        assist in transformation jobs. The columns specified in the Select
        Columns field as comma seperated values will be passed to the next node. In SQL node,{" "}
        <b>
          the user should manually type the select columns they wish to pass
          further down the node.
        </b>
      </Typography>
    </CardContent>
  </Card>
);

const HelpCardShell = () => (
  <Card
    style={{
      maxHeight: 300,
      maxWidth: 375,
      margin: "9px",
      padding: "3px",
      border: "2px solid #2dd9fe",
      boxShadow: "0 0 8px #00a3d5",
    }}
  >
    <CardContent>
      <Typography variant="h6" component="div">
        <VscTerminalPowershell style={{ marginRight: "8px" }} />
        Shell Script
      </Typography>
      <Typography variant="body2" color="textSecondary">
        The shell script allows the user to execute shell commands. The columns
        specified in the Select Columns field as comma seperated values will be passed to the next node.
        In shell script, <b>select columns are auto-populated</b> into the
        select columns field, without any edit access for the user.
      </Typography>
    </CardContent>
  </Card>
);

const HelpCardInline = () => (
  <Card
    style={{
      maxHeight: 300,
      maxWidth: 375,
      margin: "9px",
      padding: "3px",
      border: "2px solid #00fe9b",
      boxShadow: "0 0 8px #02c435",
    }}
  >
    <CardContent>
      <Typography variant="h6" component="div">
        <FaCode style={{ marginRight: "8px" }} />
        Inline Code
      </Typography>
      <Typography variant="body2" color="textSecondary">
        The inline code allows the user to execute Python code. The columns
        specified in the Select Columns field as comma seperated values  will be passed to the next node.
        In inline code, <b>the user should manually type the select columns</b>{" "}
        which they wish to pass further down the node.
      </Typography>
    </CardContent>
  </Card>
);

const PythonInput = ({
  field_id,
  display_name,
  field_type,
  handleBlur,
  handleChange,
  disabled,
  touched,
  errors,
  values,
  ...otherProps
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    // Ensure the input value is only updated when it's different to avoid unnecessary updates
    if (inputRef.current && inputRef.current.value !== values[field_id]) {
      inputRef.current.value = values[field_id] || "";
    }
  }, [values, field_id]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    handleChange({
      target: {
        name: field_id,
        value,
      },
    });
  };

  // Help card conditions
  if (disabled !== undefined) {
    if (field_id === "help" && "select_columns" && disabled) {
      return <HelpCardShell />;
    }
    if (field_id === "help" && "select_columns_1" && !disabled) {
      return <HelpCardInline />;
    }
  }
  if (field_id === "help_sql") {
    return <HelpCardSQL />;
  }

  // Render control for fields
  const shouldRender = !(
    (disabled === undefined) || // Hide if disabled is undefined
    (field_id === "select_columns_1" && disabled) || // Hide select_columns_1 if disabled is true
    (field_id === "select_columns" && !disabled) // Hide select_columns if disabled is false
  );

  if (!shouldRender) {
    return null; // Do not render this field
  }

  // Force disable select_columns only
  const isDisabled = field_id === "select_columns" ? true : disabled;

  return (
    <TextField
      id="standard-basic"
      fullWidth
      variant="outlined"
      type={field_type}
      label={display_name}
      inputRef={inputRef}
      InputLabelProps={{
        style: { color: "black", fontSize: "18px" },
        shrink: true, // Force label to shrink always
      }}
      onBlur={handleBlur}
      onChange={handleInputChange}
      defaultValue={values[field_id] || ""} // Use defaultValue instead of value to prevent uncontrolled warning
      disabled={isDisabled} // Disable select_columns, keep select_columns_1 enabled unless explicitly disabled
      name={field_id}
      error={!!touched[field_id] && !!errors[field_id]}
      helperText={touched[field_id] && errors[field_id]}
      sx={{
        gridColumn: "span 4",
        width:
          field_id === "input_col_name" ||
          field_id === "output_col_name" ||
          field_id === "expression_value"
            ? "160px!important"
            : "360px!important",
        marginLeft: "12px",
        minHeight: "10px",
        display:
          field_id === "input_df" || field_id === "output_df" ? "none" : "",
      }}
      {...otherProps}
    />
  );
};

export default PythonInput;
