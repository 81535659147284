import React from "react";
import { Box } from "@mui/material";
import Element from "../components/Element";

const BulkForm = (props) => {
  const { taskProps, formProps } = props;
  const { fields_list: fieldsList } = taskProps;


  return (
    <Box margin="2rem 15rem">
      <Box
        display="grid"
        gap="230px"
        rowGap="10px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      >
        {fieldsList
          ? fieldsList.map(
            (
              field,
              index 
            ) => (
              <Element
                key={field.id}
                field={field}
                values={formProps.values}
                touched={formProps.touched}
                errors={formProps.errors}
                handleBlur={formProps.handleBlur}
                handleChange={formProps.handleChange}
              />
            )
          )
          : null}
      </Box>
    </Box>
  );
};

export default BulkForm;
