import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

const Breadcrumbs = ({ breadcrumbPaths }) => {
  const location = useLocation();
  const { pathname } = location;

  if (!Array.isArray(breadcrumbPaths)) {
    return null; 
  }

  const linkStyle = {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'var(--fifth-color) !important',
    textDecoration: 'none', // No underline by default
  };

  const labelStyle = {
    color: 'inherit',
  };

  return (
    <Typography component="div" variant="subtitle1" sx={{ fontFamily: 'Poppins' }}>
      {breadcrumbPaths.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.path}>
          <Link
            to={breadcrumb.path}
            style={{
              ...linkStyle,
              textDecoration: index < breadcrumbPaths.length - 1 ? 'underline' : 'none', // Underline only previous pages
            }}
          >
            <span style={labelStyle}>
              {index === 0 ? 'Home' : breadcrumb.label}
            </span>
          </Link>
          {index < breadcrumbPaths.length - 1 && ' > '}
        </React.Fragment>
      ))}
    </Typography>
  );
};

export default Breadcrumbs;
