import React, { useRef, useState, useEffect, useCallback } from "react";
import { TextField } from "@mui/material";

const Text = ({
  field_id,
  display_name,
  field_type,
  field_value,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  authentication_type,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState(values[field_id] || "");
  const inputRef = useRef(null);
  const cursorPosition = useRef(null);

  useEffect(() => {
    setInputValue(values[field_id] || "");
  }, [values, field_id]);

  const handleInputChange = (event) => {
    const { value } = event.target;

    // Save the cursor position
    cursorPosition.current = event.target.selectionStart;

    // Update the local state
    setInputValue(value);

    // Trigger the parent handleChange
    handleChange(event);
  };

  const setInputRef = useCallback((element) => {
    if (element) {
      element.selectionStart = cursorPosition.current;
      element.selectionEnd = cursorPosition.current;
    }
    inputRef.current = element;
  }, []);

  const renderField = () => {
    if (
      (values.authentication_type === "basic" && field_id === "username") ||
      (values.authentication_type === "access_token" && field_id === "access_token") ||
      (values.authentication_type === "api_key" && field_id === "api_token")
    ) {
      return (
        <TextField
          id="standard-basic"
          fullWidth
          variant="outlined"
          type={field_type}
          InputLabelProps={{
            style: { color: "black", fontSize: '16px' },
             shrink: true, // Ensure the label stays above the input
          }}
          label={display_name}
          sx={{
            gridColumn: "span 4",
            width: "360px!important",
            marginLeft: "12px",
            minHeight: "10px",
          }}
          onBlur={handleBlur}
          onChange={handleInputChange}
          value={inputValue}
          name={field_id}
          error={!!touched[field_id] && !!errors[field_id]}
          helperText={touched[field_id] && errors[field_id]}
          inputRef={setInputRef}
          {...otherProps}
        />
      );
    }
    return null;
  };

  return renderField();
};

export default Text;
