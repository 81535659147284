import { Box, CircularProgress, Fab } from "@mui/material";
import React, { useState } from "react";
import { Save } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import DoneAllIcon from '@mui/icons-material/DoneAll';

const TasksActions = ({ params, rowId, setRowId, updateTaskDetails }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      let {
        id,
        pipeline_id,
        task_name,
        task_description,
        task_type,
        task_sequence,
        is_active,
        ...otherFields
      } = params.row;

      const updateTaskRecord = {
        id,
        pipeline_id,
        task_name,
        task_description,
        task_type,
        task_sequence,
        is_active,
        ...otherFields,
      };

      await updateTaskDetails({
        data: updateTaskRecord,
        id: id,
      });

      setSuccess(true);
      setRowId(null);
    } catch (error) {
      console.error("Request failed:", error);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          sx={{
            width: 30,
            height: 30, 
            minHeight: 30,
          }}
        >
          <DoneAllIcon
            sx={{
              color: green[500],
              fontSize: "17px", // Adjust the icon size
              "&:hover": { color: green[700] },
            }}
          />
        </Fab>
      ) : (
        <Fab
          sx={{
            width: 30, // Adjust the width
            height: 30, // Adjust the height
            minHeight: 30, // Ensure height doesn't get overridden
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save
            sx={{
              fontSize: "17px", // Adjust the icon size
            }}
          />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={20}
          sx={{
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
          transform: "translate(-50%, -50%)", // Center the progress
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default TasksActions;
