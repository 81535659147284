import React from 'react';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

function ToggleButtonForTaskParams(props) {
  const { alignment, value, connSubList, handleChange } = props;
  const tabElements = connSubList?.map((item) => {
    return (
      <ToggleButton key={item.id} value={item} aria-label="left aligned" 
      sx={{
        color: "var(--primary-color) ",
        fontWeight: "700!important",
        display: "flex",
        fontFamily: "Poppins",
        backgroundColor: "lightgray", // Default background color
        alignItems: "center",
        height: "40px", // Set a consistent height for all buttons
        minWidth: "130px",
        '&.Mui-selected': {
          backgroundColor: "var(--primary-color)", // Background color when selected
          color: "white", // Text color when selected (optional)
        },
      }}>
        {item}
      </ToggleButton>
    );
  });

  return (
    <>
      <ToggleButtonGroup
        orientation={alignment}
        value={value}
        exclusive
        onChange={handleChange}
        sx={{
          display: "flex",
          flexWrap: "wrap", // Allow wrapping to the next line
        }}
        aria-label="Connection Type"
      >
        {tabElements}
      </ToggleButtonGroup>
    </>
  );
}

export default ToggleButtonForTaskParams;
