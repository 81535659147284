import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Button,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Tooltip,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import WrapTextRoundedIcon from '@mui/icons-material/WrapTextRounded';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import axios from "api/axios";
import { FileCopyOutlined } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useParams, useLocation } from "react-router-dom";
import PublishIcon from "@mui/icons-material/Publish";
import { selectCurrentUser, selectCurrentToken } from "state/authSlice";
import { useSelector } from "react-redux";
import Breadcrumbs from "components/elements/Breadcrumbs";
import ReactMarkdown from 'react-markdown';
import { Alert, AlertTitle, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FaRegPlayCircle, FaRegPauseCircle, FaRegStopCircle } from 'react-icons/fa';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {
  useGetTasksQuery,
  useGetTasksLogCaptureQuery,
  useGetTaskFormQuery,
  useGetSqlOrchestrationFormQuery,
  useGetBulkIngestionFormQuery,
  useAddTaskMutation,
  useAddPythonRunMutation,
  useUpdateTaskMutation,
  useUpdateTaskDetailsMutation,
  useTaskGitDataMutation,
  useTaskGitDataTransformationMutation,
  useTaskBulkDataMutation,
  useGetTaskAuditQuery,
  useRunPythonScriptMutation,
  // useAddGitDataMutation,
  useLazyGetTransformationFlowByIdQuery,
  useGetTaskLogCaptureQuery,
  useCopyDataToTaskMutation,
  useGetGitBranchesWithFileQuery,
} from "../state/apiSlice";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import Header from "../components/Header";
import Popup from "../components/Popup";
import TaskParameters from "./TaskParameters";

import TasksActions from "./TasksActions";
import DataQualityForm from "./DataQualityForm";
import SqlExecutionForm from "./SqlExecutionForm";
import BulkIngestionForm from "./BulkIngestionForm";
import Transformation from "./Transformation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { error } from "im/lib/ident";
import { clear } from "@testing-library/user-event/dist/clear";
import useStyles from "useStyles";



const Tasks = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  const { itemProjectData, itemProgramData, itemPipelineData } = location.state || {};
  const mergedData = { ...itemProjectData, ...itemProgramData, ...itemPipelineData };

  const [selectedRow, setSelectedRow] = useState(null);
  const [rowId, setRowId] = React.useState(null);
  const [rowcopy, setRowCopy] = useState(null);
  const [rowpublish, setRowPublish] = useState(null);

  const currentUser = useSelector(selectCurrentUser);
  const accessToken = useSelector(selectCurrentToken);

  const user = currentUser.login_id
  const { data: tasksRecords, isLoading: isRecordLoading, error: recordError } = useGetTasksQuery({ id, user });

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (tasksRecords) {
      setRows(tasksRecords);
    }
  }, [tasksRecords]);
  const { data: taskFormFields, isLoading: isFormLoading, error: formError } =
    useGetTaskFormQuery(user);

  const { data: sqlFormFields, isLoading: isSqlFormLoading, error: sqlformError } =
    useGetSqlOrchestrationFormQuery(user);


  const { data: bulkIngFormFields, isLoading: isBulkIngFormLoading, error: bulkIngformError } =
    useGetBulkIngestionFormQuery(user);

  const [selectedRowIndex, setSelectedRowIndex] = React.useState(-1);
  const [checkedValue, setCheckedValue] = React.useState(-1);
  const [openPopupHelp, setOpenPopupHelp] = useState(false);

  const [markdownContent, setMarkdownContent] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    fetch('/docs/task/task.md')
      .then(response => response.text())
      .then(data => setMarkdownContent(data))
      .catch(error => console.error('Error fetching task.md:', error));
  }, []);

  const handleRowSelection = (row, checked) => {
    if (checked) {
      setSelectedRow(row.id);
      setSelectedRowIndex(row);
    } else {

      setSelectedRow(null);
    }
  };

  const breadcrumbPaths = [
    { path: '/dashboard', label: 'Home' },
  ];
  if (itemProgramData?.program_name !== undefined) {
    breadcrumbPaths.push({ path: '/programs', label: `Programs (${itemProgramData?.program_name})` });
  }
  if (itemProjectData?.project_name !== undefined) {
    breadcrumbPaths.push({ path: `/projects/${itemProjectData?.program_id}`, label: `Projects (${itemProjectData?.project_name})` });
  }
  if (itemPipelineData?.pipeline_name !== undefined) {
    breadcrumbPaths.push({ path: `/pipelines/${itemPipelineData?.project_id}`, label: `Pipelines (${itemPipelineData?.pipeline_name})` });
  }

  breadcrumbPaths.push({ path: '/tasks', label: 'Tasks' });



  const [recordForEdit, setRecordForEdit] = useState(null);
  const [rowIdSQL, setRowIdSQL] = useState(null);

  const [rowIdTranform, setrowIdTranform] = useState(null);
  const [openTaskPopup, setOpenTaskPopup] = useState(false);
  const [openTaskPopup1, setOpenTaskPopup1] = useState(false);

  const [openSourcePopup, setOpenSourcePopup] = useState(false);
  const [openTargetPopup, setOpenTargetPopup] = useState(false);
  const [openDQFormPopup, setOpenDQFormPopup] = useState(false);
  const [openSqlExecutionFormPopup, setopenSqlExecutionFormPopup] =
    useState(false);
  const [openBulkIngestionFormPopup, setopenBulkIngestionFormPopup] =
    useState(false);
  const [openTransformationFormPopup, setopenTransformationFormPopup] =
    useState(false);

  const [addTask] = useAddTaskMutation();
  const [runTask] = useAddPythonRunMutation();

  const [updateTask] = useUpdateTaskMutation();
  const [updateTaskDetails] = useUpdateTaskDetailsMutation();
  const [taskGitData] = useTaskGitDataMutation();
  const [taskGitDataTransformation] = useTaskGitDataTransformationMutation();
  const [taskBulkData] = useTaskBulkDataMutation();


  // const [addGit] = useAddGitDataMutation();
  const [copytaskdata] = useCopyDataToTaskMutation();

  const [taskType, setTaskType] = useState([]);
  const [bulkIngType, setBulkIngType] = useState([]);


  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showCopyCancelDialog, setShowCopyCancelDialog] = useState(false);
  const [showPublishCancelDialog, setShowPublishCancelDialog] = useState(false);
  const [copySuccessDialogOpen, setCopySuccessDialogOpen] = useState(false);
  const [publishSuccessDialogOpen, setPublishSuccessDialogOpen] =
    useState(false);
  const [isRecordCopied, setIsRecordCopied] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [output, setOutput] = useState('');
  const [TaskName, setTaskName] = useState('');


  const [modalIsOpen, setModalIsOpen] = useState(false);

  const findSectionField = (formObject, section, field = null) => {
    const sectionRecord = formObject?.find((rec) => rec.section === section);
    if (field) {
      const fieldRecord = sectionRecord?.fields_list.find(
        (rec) => rec.field_id === field
      );
      return fieldRecord;
    }
    return sectionRecord;
  };
  // const findSectionBulkField = (formObject, section, field = null) => {
  //   const sectionRecord = formObject.find((rec) => rec.section === section);
  //   if (field) {
  //     const fieldRecord = sectionRecord?.fields_list.find(
  //       (rec) => rec.field_id === field
  //     );
  //     return fieldRecord;
  //   }
  //   return sectionRecord;
  // };
  useEffect(() => {
    if (formError) {
      console.error('Error fetching task form:', formError);
    } else if (!isFormLoading) {
      const taskTypeField = findSectionField(taskFormFields, 'main', 'task_type');

      let taskFieldResult = [];

      if (taskTypeField && taskTypeField.option_list) {
        taskTypeField.option_list.forEach((element) => {
          taskFieldResult.push(element.name);
        });
        setTaskType(taskFieldResult);
      }
    }
  }, [isFormLoading, taskFormFields, formError]);


  useEffect(() => {
    if (bulkIngformError) {
      console.error('Error fetching task form:', bulkIngformError);
    } else if (!isBulkIngFormLoading) {
      const bulkIngFormField = bulkIngFormFields
      // const bulkIngFormField = findSectionBulkField(bulkIngFormFields, 'Source');

      let bulkIngFieldResult = [];

      if (bulkIngFormField && bulkIngFormField?.option_list) {
        bulkIngFormField.option_list.forEach((element) => {
          bulkIngFieldResult.push(element.name);
        });
        setTaskType(bulkIngFieldResult);
      }
    }
  }, [isBulkIngFormLoading, bulkIngFormFields, bulkIngformError]);



  const addOrEditRecord = async (values, resetForm, row) => {
    values.details = values.details.filter(item => !["created_by", "updated_by", "task_sequence", "option_read", "option_write", "option_execute", "task_type", "last_run_id", "status"].includes(item.key_01));
    if (values?.id > 0) {

      values.updated_by = currentUser.login_id;
      await updateTask({ data: values, task_id: values.id });
    } else {

      values.created_by = currentUser.login_id;
      await addTask({ data: values });
    }

    const delayMilliseconds = 3000;
    await new Promise(resolve => setTimeout(resolve, delayMilliseconds));
    // resetForm();

    setRecordForEdit(null);
    setOpenTaskPopup1(false);

    setOpenTaskPopup(false);
    setOpenSourcePopup(false);
    setOpenTargetPopup(false);
    if (isRecordCopied) {
      setIsRecordCopied(false);
      setCopySuccessDialogOpen(true);
    }


  }

  const addCopy = async (values, resetForm, row) => {
    recordForEdit.task_name = values?.task_name;
    recordForEdit.task_sequence = values?.task_sequence;
    recordForEdit.pipeline_id = values?.pipeline_id;
    recordForEdit.task_description = values?.task_description;
    recordForEdit.task_type = values?.task_type;

    if (values?.id > 0) {
      await updateTask({ data: values, task_id: values.id });
    } else {
      await addTask({ data: recordForEdit });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenTaskPopup1(false);

    setOpenTaskPopup(false);
    setOpenSourcePopup(false);
    setOpenTargetPopup(false);
    if (isRecordCopied) {
      setIsRecordCopied(false);
      setCopySuccessDialogOpen(true);
    }
  };
  const copyRow = async (row) => {
    if (row === -1) {
      setShowCopyDialog(false);
    } else {
      setShowCopyDialog(true);
    }
    setRowCopy(row);
  };
  const publishRow = async (row) => {
    if (row === -1) {
      setShowPublishDialog(false);
    } else {
      setShowPublishDialog(true);
    }
    setRowPublish(row);
  };
  const publishRows = (rows) => {
    rows.forEach((row) => {
      publishRow(row);
    });
  };

  const [rowJobs, setRowJobs] = useState();
  const [showRunDialog, setShowRunDialog] = useState(false);

  const runFromBranch = async (row) => {
    if (row === -1) {
      setShowRunDialog(false);
    } else {
      setShowRunDialog(true);
    }
    setRowPublish(row);
    setRowJobs(row)

  };

  const handleCopyDialogClose = () => {
    setShowCopyDialog(false);
    setShowCopyCancelDialog(true);
  };
  const handlePublishDialogClose = () => {
    setShowPublishDialog(false);
    setShowRunDialog(false)
    setShowPublishCancelDialog(true);
  };

  const handleDialogClose = () => {
    setShowCopyCancelDialog(false);
    setShowPublishCancelDialog(false);
  };

  const handleCopyConfirm = async () => {
    setShowCopyDialog(false);

    let copyRecord = JSON.parse(JSON.stringify(rowcopy));

    copyRecord.task_name = `${rowcopy.task_name}_copy`;
    copyRecord.id = null;

    const data11 = await copytaskdata({ id: rowcopy.id, copyRecord });

    if (data11.data.task && data11.data.details) {
      const updatedTask = {
        ...data11.data.task,
        task_name: `${rowcopy.task_name}_copy`,
      };
      const updatedData = { ...updatedTask, details: data11.data.details };

      setRecordForEdit(updatedData);
      setOpenTaskPopup1(true);
      setIsRecordCopied(true);
    }
  };

  const [branchNames, setBranchNames] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("main");
  const [getTransformationFlowById] = useLazyGetTransformationFlowByIdQuery();
  const [transformationFlow, setTransformationFlow] = useState([]);


  useEffect(() => {
    // Simulated branch names for example
    const fetchedBranchNames = ["main", "feature-branch", "bug-fixes"];
    setBranchNames(fetchedBranchNames);
  }, []);

  const projectName = rowpublish?.project_name;
  const taskName = rowpublish?.task_name;
  console.log(rowpublish, "rowpublishrowpublishrowpublishrowpublish")
  const { data: branchData, error } = useGetGitBranchesWithFileQuery({
    variables: {
      project: projectName,
      name: taskName,
      type: "task"
    },
  }
  );


  useEffect(() => {
    if (branchData) {
      const branchesWithStatus = branchData.branches.map((branch) => {
        return {
          ...branch,
          statusMessage: branch.pathExists ? 'File Path Exists  ' : 'File Path Does Not Exist',
        };
      });
      setBranchNames(branchesWithStatus);
    }
  }, [branchData]);
  const handlePublishConfirm = async () => {
    setShowPublishDialog(false);
    setShowRunDialog(false)

    const publishRecord = JSON.parse(JSON.stringify(rowpublish));
    try {
      if (selectedBranch) {
        if (rowpublish?.task_type === "Bulk Ingestion") {
          await taskBulkData({ id: publishRecord?.id, branch: selectedBranch, login_id: currentUser?.login_id });
        }
        else if (rowpublish?.task_type === "Transformation") {
          await taskGitDataTransformation({ id: publishRecord?.id, branch: selectedBranch, login_id: currentUser?.login_id });
        }
        else {
          await taskGitData({ id: publishRecord?.id, branch: selectedBranch, login_id: currentUser?.login_id });

        }
      } else {

        if (rowpublish?.task_type === "Bulk Ingestion") {
          await taskBulkData({ id: publishRecord?.id, branch: "main", login_id: currentUser?.login_id });
        } else if (rowpublish?.task_type === "Transformation") {
          await taskGitDataTransformation({ id: publishRecord?.id, branch: "main", login_id: currentUser?.login_id });
        }
        else {
          await taskGitData({ id: publishRecord?.id, branch: "main", login_id: currentUser?.login_id });

        }
      }
    } catch (error) {
      console.log(error);
    }
    setPublishSuccessDialogOpen(true);
  };

  const handleCopySuccessDialogClose = () => {
    setCopySuccessDialogOpen(false);
  };

  const handlePublishSuccessDialogClose = () => {
    setPublishSuccessDialogOpen(false);
  };

  function handleDownloadClick() {
    const logsData = logs[loggerID?.id];
    if (!logsData) {
      return;
    }
    const blob = new Blob([logsData], { type: "text/plain" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${loggerID?.task_name} ${loggerID?.id}.txt`;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }
  const [rowId1, setRowid1] = useState({});
  const [logs, setLogs] = useState([]); // Store logs in an array

  const TerminateButton = ({ row }) => {

    const [isClicked, setIsClicked] = useState(false);

    const handleTerminate = async () => {
      // Send a request to the server to terminate the script for the selected pipeline
      try {
        const response = await fetch(
          `${baseURL}/api/tasks/terminate-python-script/${row.task_name}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.message) {
            // Successful termination
            console.log(data.message, "Script termination request sent successfully");
            toast.success(data.message);
          }
        } else {
          // Error in termination
          const errorData = await response.json();
          console.error("Failed to send script termination request:", errorData.error);
          toast.error(errorData.error);
        }
      } catch (error) {
        console.error("Error sending script termination request:", error);
        toast.error("Error sending script termination request");
      }
    };

    return (
      <Box
        onClick={handleTerminate}
        sx={{
          display: "flex",
          alignItems: "center", // Vertically center
          justifyContent: "center", // Horizontally center
          textAlign: "center",
        }}
      >
        <FaRegStopCircle
          style={{
            fontSize: '18px', // Increase the size of the icon
            color: 'red', // Set the color to red
          }}
          sx={{
            "&:hover": {
              fontSize: '25px', // Change size on hover
              cursor: 'pointer',
            },
          }}
        />
      </Box>
    );
  };


  const RunButton = ({ row }) => {
    const isExecuteEnabled = row.option_execute === "1";
    const [isPlaying, setIsPlaying] = useState(false);
    return (
      <Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Vertically center
            justifyContent: "center", // Horizontally center
            textAlign: "center",
            ...(loadingStates[row.id]
              ? {
                color: "black",
                cursor: "not-allowed",
              }
              : {}),
          }}
          onClick={() => {
            if (isExecuteEnabled) {
              if (isPlaying) {
                console.log("isPlaying", isPlaying);
              } else {
                runFromBranch(row);
              }

              setIsPlaying(!isPlaying);
            }
          }}
          disabled={loadingStates[row.id] || !isExecuteEnabled}
        >
          <PlayCircleIcon
            sx={{
              cursor: "allowed",
              fontSize: '20px',
              color: isPlaying ? 'red' : 'green',
              transition: 'font-size 0.3s ease',
              "&:hover": {
                fontSize: '25px', // Change on hover
                cursor: 'pointer',
              },
            }}
          />
        </Box>
      </Box>

    );
  };

  const [loggerID, setLoggerId] = useState(null);
  const LoggerButton = ({ row }) => {
    const isLogAvailable = logs[row.id] !== undefined;

    return (
      <Box>
        <Box

          onClick={() => {
            if (isLogAvailable) {
              openModal(logs[row.id]);
              setLoggerId(row);
            }
          }}
        >
          <TextSnippetIcon sx={{ color: isLogAvailable ? "green " : "black", fontSize: "20px", cursor: isLogAvailable ? "pointer" : "default" }} />
        </Box>
      </Box>
    );
  };


  const runPythonScript = async (rowJobs) => {
    setShowRunDialog(false)

    if (!rowJobs || !rowJobs.id) {
      console.error("rowJobs is undefined or does not have the 'id' property.");
      return;
    }
    const id = rowJobs?.id;
    setRowid1(id);
    const task_name = rowJobs?.task_name;
    const project_name = rowJobs?.project_name;

    try {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: true,
      }));

      const response = await fetch(
        `${baseURL}/api/tasks/run-python-script`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            project_name,
            task_name,
            selectedBranch,
          }),
        }
      );
      setTaskName(task_name);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const reader = response.body.getReader();
      let output = "";

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        const chunk = new TextDecoder().decode(value);

        setLogs((prevLogs) => ({
          ...prevLogs,
          [id]: (prevLogs[id] || "") + chunk,
        }));

        output += chunk;
        setOutput(output);
      }
    } catch (error) {
      console.error("Error running Python script:", error);
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setOpenPopupHelp(false);

  };
  const Status = ({ row }) => {
    const { data, error } = useGetTaskAuditQuery({
      taskName: row.task_name,
      pipeline_id: row.pipeline_id,
    });

    const buttonState = {
      label: "NEW",
      Icon: AutoAwesomeIcon, // Default icon for "NEW"
      color: "black", // Default color for "NEW"
    };

    if (data) {
      if (data[0]?.audit_value === "COMPLETED") {
        buttonState.Icon = CheckCircleIcon;
        buttonState.color = "black";
      } else if (data[0]?.audit_value === "FAILED") {
        buttonState.Icon = CancelIcon;
        buttonState.color = "black";
      }
    }

    const IconComponent = buttonState.Icon;

    return (
      <Box>
        <span
          style={{
            cursor: loadingStates[row.id] ? "not-allowed" : "default",
            display: "flex",
            alignItems: "center", // Vertically center
            justifyContent: "center", // Horizontally center
            textAlign: "center",
            paddingLeft: "15px",
          }}
        >
          <IconComponent style={{ marginRight: "5px", color: buttonState.color }} />
        </span>
      </Box>
    );
  };


  let filteredRows = rows.filter((item) => item.is_active === "N");
  const columns = [
    {
      field: 'selection',
      headerName: '',
      type: 'boolean',
      sortable: false,
      width: 30,
      renderCell: (params) => {
        return (
          <input
            type="radio"
            name="rowSelection"
            checked={params.row.id === selectedRow}
            onChange={(event) => handleRowSelection(params.row, event.target.checked)}


          />
        );
      },
    }
    ,

    { field: "id", headerName: "ID", flex: 0.6, headerAlign: "center", align: "center" },
    { field: "project_name", headerName: "Project ", flex: 1, headerAlign: "center", align: 'left', },
    {
      field: "pipeline_name",
      headerName: "Pipeline ",
      flex: 1.25,
      headerAlign: "center",
      align: 'left',
    },
    {
      field: "task_name",
      headerName: "Task ",
      flex: 1,
      editable: true,
      headerAlign: "center", align: 'left',
      renderHeader: (params) => (
        <Box display="flex" alignItems="center" justifyContent="center" gap="2px">
          <WrapTextRoundedIcon fontSize="small" />
          <Typography fontSize={16} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>
        </Box>
      ),
    },

    {
      field: "task_type",
      headerName: "Type",
      type: "singleSelect",
      headerAlign: "center",
      align: 'center',
      flex: 1,
      valueOptions: taskType,
      editable: true,
      renderHeader: (params) => (
        <Box display="flex" alignItems="center" justifyContent="center" gap="2px">
          <ArrowDropDownCircleRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>
        </Box>
      ),
    },
    {
      field: "task_sequence",
      headerName: "Seq#",
      headerAlign: "center",
      align: 'center',
      flex: 0.8,
      editable: true,

    },
    {
      field: "sourceAndTarget",
      headerName: "Task Details",
      headerAlign: "center",
      align: "left",
      flex: 1.1,
      renderCell: ({ row }) => {
        const handleSourceClick = () => {
          setOpenSourcePopup(true);
          setRecordForEdit(row);
        };

        const handleTargetClick = () => {
          setOpenTargetPopup(true);
          setRecordForEdit(row);
        };

        const handleSqlExecutionCellClick = (row) => {
          setRecordForEdit(row);
          setopenSqlExecutionFormPopup(true);
          setRowIdSQL(row.id);
        };

        const handleBulkIngestionCellClick = (row) => {
          setRecordForEdit(row);
          setopenBulkIngestionFormPopup(true);
        };

        const handleTransformationClick = async (row) => {
          let id = row.id;
          const { data } = await getTransformationFlowById(id);
          if (data) {
            setTransformationFlow(data);
          }
          setRecordForEdit(row);
          setopenTransformationFormPopup(true);
          console.log("TransformID", row.id);
          setrowIdTranform(row.id);
        };

        if (row.task_type === "SQL Execution") {
          return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Tooltip title="Click to execute SQL">
                <Box
                  sx={{
                    color: 'var(--primary-color) !important',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => handleSqlExecutionCellClick(row)}
                >
                  SQL Execution
                </Box>
              </Tooltip>
            </Box>
          );
        }
        if (row.task_type === "Bulk Ingestion") {
          return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Tooltip title="Click for bulk ingestion">
                <Box
                  sx={{
                    color: 'var(--primary-color) !important',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => handleBulkIngestionCellClick(row)}
                >
                  Bulk Ingestion
                </Box>
              </Tooltip>
            </Box>
          );
        }
        if (row.task_type === "Transformation") {
          return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Tooltip title="Click for transformation">
                <Box
                  sx={{
                    color: 'var(--primary-color) !important',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => handleTransformationClick(row)}
                  disabled={row.task_type !== "Transformation"}
                >
                  Transformation
                </Box>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Tooltip title="Click for source info">
                <Box
                  sx={{
                    color: 'var(--primary-color) !important',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={handleSourceClick}
                >
                  {row.source}
                </Box>
              </Tooltip>
              <Tooltip title="Click for target info">
                <Box
                  sx={{
                    color: 'var(--primary-color) !important',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={handleTargetClick}
                >
                  {row.target}
                </Box>
              </Tooltip>
            </Box>
          );
        }
      },
    },




    { field: "is_active", headerName: "Active", flex: 0.6, editable: true, headerAlign: "center", align: 'center', },
    {
      field: "dqcount",
      headerName: "Data Quality",
      headerAlign: "center",
      align: 'center',
      flex: 1,
      renderCell: ({ row }) => {
        const handleDQCellClick = () => {
          setRecordForEdit(row);
          setOpenDQFormPopup(true);
        };
        const isIngestionTask = row.task_type === "Ingestion";

        return (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            {isIngestionTask ? (
              <Box
                color="secondary"
                onClick={handleDQCellClick}
                style={{ textTransform: 'none', padding: 8, textAlign: 'center', minWidth: '35px', cursor: "pointer" }}
              >
                {row.dqcount}
              </Box>
            ) : (
              <Typography
                variant="body2"
                color="black"
                style={{ textAlign: 'center', padding: 8, minWidth: '35px' }}
              >
                NA
              </Typography>
            )}
          </Box>
        );
      },
    }
    ,
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      headerAlign: "center",
      flex: 0.7,
      renderCell: (params) => (
        <TasksActions {...{ params, rowId, setRowId, updateTaskDetails }} />
      ),
    }
    ,
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      flex: 0.7,
      renderCell: ({ row }) => <Status row={row} />
    },
    // {
    //   field: "run",
    //   headerName: "Run",
    //   headerAlign: "center",
    //   flex: 0.6,
    //   renderCell: ({ row }) => <RunButton row={row} />
    // },
    // {
    //   field: "TERMINATE",
    //   headerName: "Stop",
    //   headerAlign: "center",
    //   flex: 0.6,
    //   renderCell: ({ row }) => <TerminateButton row={row} />,
    // },
    // {
    //   field: "logger",
    //   headerName: "Log",
    //   headerAlign: "center",
    //   flex: 0.8,
    //   renderCell: ({ row }) => {
    //     return <LoggerButton row={row} log={logs[row.id]} />;
    //   }
    // },
    {
      field: "Run Stop Log",
      headerName: "Run Stop Log",
      headerAlign: "center",
      flex: 1.2, // Adjust the flex value as needed to accommodate all buttons
      renderCell: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", width: "100%" }}>
          <RunButton row={row} style={{ flex: 1 }} />
          <TerminateButton row={row} style={{ flex: 1 }} />
          <LoggerButton row={row} log={logs[row.id]} style={{ flex: 1 }} />
        </div>
      )
    }
  ];
  const [toggle, setToggle] = useState(true);
  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggle(newToggle);
    }
  };

  const filteredData = tasksRecords?.filter(item => item.is_active === 'N');

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Popup
          title={`Python Script Output for Task Name :  ${loggerID?.task_name} ${loggerID?.id} `}
          openPopup={modalIsOpen}
          setOpenPopup={setModalIsOpen}
          width="lg"
          yesBtn={false}
          noBtn={false}
          okBtn={false}
          closeBtn={true}
          handleCloseBtnClick={closeModal}
        >
          <div>
            <pre>
              {logs[loggerID?.id]} {/* Display the log for the specific row */}
            </pre>
            <Button onClick={handleDownloadClick} sx={styles.myBackground}>
              Download
            </Button>
          </div>
        </Popup>
        <Popup
          title="Copy Task?"
          openPopup={showCopyDialog}
          width={"sm"}
          setOpenPopup={setShowCopyDialog}
          handleYesBtnClick={handleCopyConfirm}
          handleNoBtnClick={handleCopyDialogClose}
          yesBtn={true}
          noBtn={true}
        >
          <p>Do you want to copy this task {rowcopy?.task_name}?</p>
        </Popup>
        <Popup
          title="Publish Task To Github?"
          openPopup={showPublishDialog}
          width={"sm"}
          setOpenPopup={setShowPublishDialog}
          handleYesBtnClick={handlePublishConfirm}
          handleNoBtnClick={handlePublishDialogClose}
          yesBtn={true}
          noBtn={true}
        >
          <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: 600 }}>
            Do you want to publish this task {rowpublish?.task_name} to GitHub
          </p>

          <FormControl sx={{
            gridColumn: "span 4",
            width: "200px!important",

          }} >
            <InputLabel shrink id="selectbranch"
              sx={{
                '&.MuiInputLabel-shrink': {
                  fontSize: '16px', // Adjust this value as needed for the larger font size when shrunk
                  backgroundColor: 'white', // Adds white background behind the label
                  padding: '0 4px', // Optional: Adds some padding for better appearance
                  marginLeft: '-4px', // Optional: Adjusts margin to avoid shifting the label
                },
              }}
              style={{ color: 'black' }}>Select Branch</InputLabel>
            <Select
              value={selectedBranch}
              fullWidth
              onChange={(event) => setSelectedBranch(event.target.value)}
              sx={{ gridColumn: "span 4" }}
            >
              {branchNames.map((branch) => (
                <MenuItem key={branch.branchName} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedBranch && (
            <p
              style={{
                color: branchNames.find(
                  (branch) => branch.branchName === selectedBranch
                )?.pathExists
                  ? "green"
                  : "red",
              }}
            >
              {branchNames.find(
                (branch) => branch.branchName === selectedBranch
              )?.pathExists
                ? `Task : ${rowpublish?.task_name} exists in ${selectedBranch} branch. Do you want to overwrite?`
                : `Task : ${rowpublish?.task_name} Does Not Exist in ${selectedBranch} branch. Do you want to create it?`}
            </p>
          )}
        </Popup>
        <Popup
          title="RUN JOB"
          openPopup={showRunDialog}
          width={"md"}
          setOpenPopup={setShowRunDialog}
          handleYesBtnClick={() => {
            runPythonScript(rowJobs);
          }}
          handleNoBtnClick={handlePublishDialogClose}
          yesBtn={true}
          noBtn={true}
        >
          <p>
            Select the branch from where you need to run this Task{" "}
            {rowJobs?.task_name}
          </p>

          <FormControl style={{ width: "20%" }}>
            <InputLabel>Select Branch</InputLabel>
            <Select
              value={selectedBranch}
              onChange={(event) => setSelectedBranch(event.target.value)}
              style={{ width: "100%" }}
            >
              {branchNames.map((branch) => (
                <MenuItem key={branch.branchName} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Popup>

        <Popup
          title="Canceled"
          openPopup={showCopyCancelDialog}
          setOpenPopup={setShowCopyCancelDialog}
          width={"md"}
          handleOkBtnClick={handleDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <p>Copying Task: {rowcopy?.task_name} record is Canceled.</p>
        </Popup>
        <Popup
          title="Canceled"
          openPopup={showPublishCancelDialog}
          setOpenPopup={setShowPublishCancelDialog}
          width={"md"}
          handleOkBtnClick={handleDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <p>
            Publishing Task: {rowpublish?.task_name} record to GitHub is
            Canceled.
          </p>
        </Popup>

        <Popup
          title="Canceled"
          openPopup={showPublishCancelDialog}
          setOpenPopup={setShowPublishCancelDialog}
          width={"md"}
          handleOkBtnClick={handleDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <p>
            Job Execution for pipeline: {rowJobs?.pipeline_name} is Canceled.
          </p>
        </Popup>
        <Popup
          title="Task Copied"
          openPopup={copySuccessDialogOpen}
          setOpenPopup={setShowCopyCancelDialog}
          width={"sm"}
          handleYesBtnClick={handleCopySuccessDialogClose}
          yesBtn={true}
          noBtn={false}
        >
          <p>
            Task: {rowcopy?.task_name} is copied as Task: {rowcopy?.task_name}
            _copy Successfully.
          </p>
        </Popup>
        <Popup
          title="Task Published"
          openPopup={publishSuccessDialogOpen}
          setOpenPopup={setShowPublishCancelDialog}
          width={"sm"}
          handleOkBtnClick={handlePublishSuccessDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <Box>
            <Typography variant="body1" component="p" sx={{ fontFamily: "Poppins, sans-serif" }}>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                Task Name:
              </Typography>{" "}
              {rowpublish?.task_name} has been successfully uploaded to GitHub.
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontFamily: "Poppins, sans-serif" }}>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                Branch:
              </Typography>{" "}
              {selectedBranch ? selectedBranch : "main"}
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontFamily: "Poppins, sans-serif" }}>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                Created by:
              </Typography>{" "}
              {rowpublish?.created_by}
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontFamily: "Poppins, sans-serif" }}>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                Updated by:
              </Typography>{" "}
              {rowpublish?.updated_by}
            </Typography>
          </Box>
        </Popup>
        <Header title="Tasks" />

        <Breadcrumbs breadcrumbPaths={breadcrumbPaths} />

        <Box display="flex" justifyContent="space-between" alignItems="center">

          <Header subtitle={`View/Manage Tasks `} />
          <Box display="flex" alignItems="center" gap={1}>
            <ToggleButtonGroup
              value={toggle}
              exclusive
              onChange={handleToggle}
              aria-label="Toggle between Active and All"
              sx={{
                borderRadius: "80px",

              }}
            >
              <ToggleButton
                value={true}
                aria-label="All"
                sx={{
                  ...styles.myBackground,

                  backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: !toggle ? "#ffffff" : styles.myBackground.color,
                  '&:hover': {
                    backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  },
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value={false}
                aria-label="Active"
                sx={{
                  ...styles.myBackground,
                  backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: toggle ? "#ffffff" : styles.myBackground.color, // Text color changes based on toggle state
                  '&:hover': {
                    backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                    color: toggle ? "#ffffff" : styles.myBackground.color, // Ensure hover color is consistent with the toggle state
                  },
                }}
                disabled={!toggle}
              >
                Active
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenTaskPopup(true);
                setRecordForEdit(null);
              }}
              sx={styles.myBackground}

            >
              Create
            </Button>
            <Button
              sx={{
                ...styles.myBackground,
                ...(checkedValue ? {} : styles.disabledButton), // Apply styles based on condition
              }}

              disabled={!checkedValue} // Disable the button if checkedValue is false
              onClick={() => {
                if (checkedValue) {
                  copyRow(selectedRowIndex);
                }
              }}
            >
              <FileCopyOutlined sx={{
                fontSize: '16px',
                marginRight: '4px'
              }} /> COPY
            </Button>
            <Button
              sx={styles.myBackground}

              onClick={() => {
                publishRow(selectedRowIndex);
              }}
            >
              <PublishIcon sx={{
                fontSize: '16px',
                marginRight: '4px'
              }} /> PUBLISH
            </Button>
            <Button
              sx={styles.myBackground}

              onClick={() => {
                setOpenPopupHelp(true);
              }}
            >
              Help
            </Button>
          </Box>
        </Box>

      </Box>

      <Box m="1.5rem 2.5rem">
        {recordError ? (
          <Alert severity="error" style={{ width: '100%', height: '100vh', margin: 'auto' }}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            <Typography style={{ fontWeight: 'bold' }}>
              {recordError?.data?.message}
            </Typography>
          </Alert>

        ) : isRecordLoading ? (
          <p>Loading...</p>
        ) : tasksRecords ? (
          <Box
            m="10px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "var(--tertiary-color) !important",
              },
              "& .MuiDataGrid-row.Mui-disabled-row": {
                backgroundColor: "lightgrey",
              },


              "& .MuiDataGrid-root": {
                border: "none",
                fontWeight: "600",
                fontSize: "12px !important",
                fontFamily: "Poppins",
              },

              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color) !important",
                border: "none",
                color: "#ffffff!important",
                fontSize: "15px!important",
                fontWeight: "bold!important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                fontFamily: "Poppins",
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#f5f5f5",
                minHeight: "352px!important",
                // maxHeight: "323px!important",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "var(--pagination-bottom-color) !important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                borderRadius: "0 0 6px 6px",
                marginTop: "5px!important",
                marginTop: "5px!important",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "black!important",
                fontSize: "12px",
                width: "100px",
                marginBottom: "1px",
                fontFamily: "Poppins",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#4b437cd1!important",
              },
              "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                color: 'white', // Ensure filter icons and three-dot menu icons are white
              },

            }}
          >
            <DataGrid
              rowHeight={40}
              getRowId={(row) => row.id}
              rows={rows?.filter((item) =>
                toggle ? item.is_active === "Y" || item.is_active === 'N' : item.is_active === "Y"

              )}
              columns={columns}
              components={{ Toolbar: GridToolbar }}

              onCellEditCommit={(params) => {
                setRowId(params.id);
                const updatedRows = rows.map((row) =>
                  row.id === params.id ? { ...row, [params.field]: params.value } : row
                );
                setRows(updatedRows);
              }}
              getRowClassName={(params) => {
                const isDisabled = filteredData.some(filteredItem => filteredItem.id === params.row.id);
                if (isDisabled) {
                  return 'Mui-disabled-row';
                }

                return params.indexRelativeToCurrentPage % 2 === 0
                  ? 'Mui-odd'
                  : 'Mui-even';

              }}




              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
            />
          </Box>
        ) : (
          <>Loading...</>
        )}
      </Box>
      <Popup
        title="Task Details Form"
        openPopup={openTaskPopup}
        width={"sm"}
        setOpenPopup={setOpenTaskPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <TaskParameters
            taskFormFields={taskFormFields}
            values={recordForEdit}
            addOrEditRecord={addOrEditRecord}
            formType={"main"}
          />)}
      </Popup>
      <Popup
        title="Task Details Copy"
        openPopup={openTaskPopup1}
        width={"sm"}
        setOpenPopup={setOpenTaskPopup1}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <TaskParameters
            taskFormFields={taskFormFields}
            values={recordForEdit}
            addOrEditRecord={addCopy}
            formType={"main"}
          />)}
      </Popup>
      <Popup
        title={`Task Source Details Form  : ${recordForEdit?.task_name}`}
        openPopup={openSourcePopup}
        width={"md"}
        setOpenPopup={setOpenSourcePopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <TaskParameters
            taskFormFields={taskFormFields}
            values={recordForEdit}
            addOrEditRecord={addOrEditRecord}
            formType={"source"}
          />)}
      </Popup>
      <Popup
        title={`Task Target Details Form : ${recordForEdit?.task_name}`}
        openPopup={openTargetPopup}
        width={"md"}
        setOpenPopup={setOpenTargetPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <TaskParameters
            taskFormFields={taskFormFields}
            values={recordForEdit}
            addOrEditRecord={addOrEditRecord}
            formType={"target"}
          />)}
      </Popup>
      <Popup
        title="Data Quality Form"
        openPopup={openDQFormPopup}
        width={"lg"}
        setOpenPopup={setOpenDQFormPopup}
      >  {formError ? (
        <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
          <Typography>{formError?.data?.message}</Typography>
        </Alert>
      ) : (
        <DataQualityForm
          taskFormFields={taskFormFields}
          values={recordForEdit}
          addOrEditRecord={addOrEditRecord}
          formType={"dataquality"}
        />)}
      </Popup>
      <Popup
        title={`SQL Orchestration : ${recordForEdit?.task_name}`}
        openPopup={openSqlExecutionFormPopup}
        width={"md"}
        setOpenPopup={setopenSqlExecutionFormPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <SqlExecutionForm
            taskFormFields={sqlFormFields}
            values={recordForEdit}
            addOrEditRecord={addOrEditRecord}
            formType={"sql execution"}
            setRecordForEdit={setRecordForEdit}
            rowIdSQL={rowIdSQL}
          />)}
      </Popup>

      {/* <Popup
        title={`Bulk Ingestion : ${recordForEdit?.task_name}`}
        openPopup={openBulkIngestionFormPopup}
        width={"lg"}
        setOpenPopup={setopenBulkIngestionFormPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <BulkIngestionForm
            taskFormFields={bulkIngFormFields}
            values={recordForEdit}
            addOrEditRecord={addOrEditRecord}
            formType={"bulkingestion"}
            setRecordForEdit={setRecordForEdit}
            rowIdSQL={rowIdSQL}
          />)}
      </Popup> */}

      <Popup
        title={`Bulk Ingestion : ${recordForEdit?.task_name}`}
        openPopup={openBulkIngestionFormPopup}
        width={"md"}
        setOpenPopup={setopenBulkIngestionFormPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <BulkIngestionForm
            taskFormFields={bulkIngFormFields}
            values={recordForEdit}
            addOrEditRecord={addOrEditRecord}
            formType={"bulkingestion"}
            setRecordForEdit={setRecordForEdit}
            rowIdSQL={rowIdSQL}
          />
        )}
        {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button sx={{
            backgroundColor: "rgb(0, 72, 190)!important",
            color: "#FFFFFF",
            marginLeft: "72%",
            marginRight: "auto",
            marginTop: "-5.5%",
            padding: "6.8px 10px",
          }} onClick={() => setopenBulkIngestionFormPopup(false)}>
            Cancel
          </Button>
        </Box> */}
      </Popup>

      <Popup
        title="Transformation Form"
        openPopup={openTransformationFormPopup}
        width={"lg"}
        setOpenPopup={setopenTransformationFormPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <Transformation
            taskFormFields={taskFormFields}
            values={recordForEdit}
            addOrEditRecord={addOrEditRecord}
            formType={"Transformation"}
            transformationFlow={transformationFlow}
            setRecordForEdit={setRecordForEdit}
            rowIdTranform={rowIdTranform}
          />)}
      </Popup>
      <Popup
        title="Tasks"
        openPopup={openPopupHelp}
        width={"md"}
        okBtn={true}
        handleOkBtnClick={closeModal}
        setOpenPopup={setOpenPopupHelp}
      >
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </Popup>
      <ToastContainer
        position="top-center"  // Set position to top-center
        autoClose={10000}       // Set autoClose to 5000ms (5 seconds)
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: '1.2em' }}  // Increase the font size
      />
    </>
  );
};

export default Tasks;
