import React from "react";
import {Grid, Box, Card, CardContent, Typography } from "@mui/material";
import Header from "../components/Header";
import { useTheme } from "@emotion/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useGetFrequentlyUsedObjectsQuery,
  useGetDashboardCountQuery,
} from "../state/apiSlice";
import { Alert,AlertTitle} from '@mui/material';
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";
import connections from "../svg/connections.svg";
import pipelines from "../svg/pipelines.svg";
import project from "../svg/project.svg";
import task from "../svg/task.svg";

const Dashboard = () => {
  const currentUser = useSelector(selectCurrentUser);
  const user = currentUser?.login_id
  
  const { data: projectCount } = useGetDashboardCountQuery(user);
  const theme = useTheme();

  const { data: projectRecords, isLoading: isRecordLoading, error: recordError } =
    useGetFrequentlyUsedObjectsQuery(user);

  const metrics = [
    { icon: <img src={connections} alt="Profile Icon" style={{ width: '25px', height: '25px' }} />, label: "Connections", value: projectCount && projectCount.length > 0 ? projectCount[0]?.connection || "" : "" },
    {icon: <img src={project} alt="Profile Icon" style={{ width: '25px', height: '25px' }} />, label: "Projects", value: projectCount && projectCount.length > 0 ? projectCount[0]?.projects || "" : "" },
    { icon: <img src={pipelines} alt="Profile Icon" style={{ width: '25px', height: '25px' }} />, label: "Pipelines", value: projectCount && projectCount.length > 0 ? projectCount[0]?.pipelines || "" : "" },
    { icon: <img src={task} alt="Profile Icon" style={{ width: '25px', height: '25px' }} />, label: "Tasks", value: projectCount && projectCount.length > 0 ? projectCount[0]?.tasks || "" : "" },

  ];

  const columns = [
   
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "object", headerName: "Object", flex: 1 },
    {
      field: "created_dttm",
      headerName: "Created Date",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
    
        const formattedDate = date.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
    
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });
    
        return `${formattedDate} ${formattedTime}`;
      },
    }
  ];
  return (
    <Box m="1.5rem 2.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ color: "var(--primary-color) !important" }} >
        <Header title="Dashboard" />
      </Box>

      <Grid container spacing={1}> {/* spacing={1} gives 8px gap */}
      {metrics.map((metric, index) => (
        <Grid 
          item 
          key={index} 
          xs={12} 
          sm={6} 
          md={4} 
          lg={3} 
        >
          <Card sx={{ backgroundColor: 'var(--primary-color) !important' }}>
            <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" color="white" fontFamily="Poppins" gutterBottom>
                  {metric.label}
                </Typography>
                {metric.icon}
              </Box>
              <Typography variant="h2" color="white">
                {metric.value}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
      <Box>
        {recordError ? (
  <Alert severity="error" style={{ width: 'fit-content', margin: 'auto' }}>
  <AlertTitle><strong>Error</strong></AlertTitle>
  <Typography>{recordError?.data?.message}</Typography>
</Alert>        ) : isRecordLoading ? (
          <p>Loading...</p>
        ) : projectRecords ? (
          <Box
            m="20px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "var(--tertiary-color) !important",
                
              },
              "& .MuiDataGrid-row.Mui-disabled-row": {
                backgroundColor: "lightgrey",
              },
              "& .MuiDataGrid-root": {
                border: "none",
               fontWeight: "600",
                fontSize: "12px !important",
                fontFamily: "Poppins",
        
              },

              "& .MuiDataGrid-columnHeaders": {

                backgroundColor: "var(--primary-color) !important",
                border: "none",
                color: "#ffffff!important",
                fontSize: "15px!important",
                fontWeight: "bold!important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                fontFamily: "Poppins",
                borderBottom: "none",
              },
              "& .MuiDataGrid-footerContainer": {
         
                backgroundColor: "var(--pagination-bottom-color) !important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                borderRadius: "0 0 6px 6px",
                marginTop: "5px!important",
                marginTop: "5px!important",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "#010e03!important",
                fontSize: "12px",
                width: "100px",
                marginBottom: "1px",
                fontFamily: "Poppins",
               
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#4b437cd1!important",
              },
              "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                color: 'white', // Ensure filter icons and three-dot menu icons are white
              },

            }}
          >
              <DataGrid
                 rowHeight={40}
                rows={projectRecords}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0
                    ? "Mui-odd"
                    : "Mui-even"
                }
              />
            </Box>
          ) : (
          <>Loading...</>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
