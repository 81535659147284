import { useForm, useFieldArray } from "react-hook-form";
import { useTheme } from '@mui/material/styles';
import { dataQualitySubmit } from "services/dataquality.services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  OutlinedInput,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import {
  useGetTasksDQQuery,
  useAddTaskParameterMutation,
  useUpdateTaskParameterMutation,
  useLazyGetDataQualityColumnsQuery,
  useLazyGetDataQualityColumnsFilesQuery
} from "../state/apiSlice";

import { useState, useEffect } from "react";




const styles = {
  btnCss: {
    backgroundColor: "rgb(0, 72, 190)!important",
    boxshadow: "grey 0px 0px 5px!important",
    color: "#FFFFFF!important",
    fontWeight: "800!important",
    fontSize: "13px!important",
  },
};

function getStyles(option, selectedValues, theme) {
  return {
    fontWeight:
      selectedValues.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const DQParameterFields = (props) => {
  const theme = useTheme();
  const { parameterFields, register, fieldName, index, options } = props;
  const [isTextFieldVisible, setTextFieldVisible] = useState(true);
  const [isTextFieldVisibleSelect, setTextFieldVisibleSelect] = useState(true);

  const [isSelectVisible, setSelectVisible] = useState(false);
  const [isSelectVisibleSelect, setSelectVisibleSelect] = useState(false);

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesSelect, setSelectedValueSelect] = useState([]);

  
  // Your existing handlers
  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  
  const handleSelectChange123 = (event) => {
    const selectedValue = event.target.value;
    console.log('Selected value:', selectedValue);
    setSelectedValueSelect(selectedValue);
  
  };
  const checkParameterFields = parameterFields.find(
    (rec) => rec.check_name === fieldName
  )?.param_field_list;

  if (checkParameterFields) {
    const sortedParameterFields = checkParameterFields.sort((a, b) => {
      return a.display_sequence - b.display_sequence;
    });

    const displayNames = sortedParameterFields
      .map((rec) => rec.display_name)
      .filter((rec, index, _arr) => _arr.indexOf(rec) === index);

      const output_fornat = sortedParameterFields
      .map((rec) => rec.output_fornat)
      .filter((rec, index, _arr) => _arr.indexOf(rec) === index);
    
    
    
    const handleTextFieldClick = () => {
      setTextFieldVisible(false);
      setSelectVisible(true);
    };
    
    const handleTextFieldClickSelect = () => {
      setTextFieldVisibleSelect(false);
      setSelectVisibleSelect(true);
    };
    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {fieldName !== "" ? (
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {displayNames.map((name) => (
                    <TableCell key={name.id}>{name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {sortedParameterFields.map((item, paramIndex) => (
                    <TableCell key={paramIndex.id} component="th" scope="row">
                      {item.field_type === 'multiselect' ? (
                        <>
                          {isTextFieldVisible && (
                            <TextField
                              label="columns"
                              variant="outlined"
                              {...register(`DataQuality[${index}].${item.field_id}`)}
                              onClick={handleTextFieldClick}
                            />
                          )}
                          {isSelectVisible && (
                            <Select
                              multiple
                              variant="outlined"
                              {...register(`DataQuality[${index}].${item.field_id}`)}
                              value={selectedValues}
                              onChange={handleSelectChange}
                              input={<OutlinedInput label="Name" />}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  style={getStyles(option, selectedValues, theme)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </>
                      ) : item.field_type === 'select' ? (
                        <>
                          {isTextFieldVisibleSelect && (
                            <TextField
                              label="columns"
                              variant="outlined"
                              {...register(`DataQuality[${index}].${item.field_id}`)}
                              onClick={handleTextFieldClickSelect}
                            />
                          )}
                          {isSelectVisibleSelect && (
                            <Select
                              variant="outlined"
                              {...register(`DataQuality[${index}].${item.field_id}`)}
                              value={selectedValuesSelect}
                              onChange={handleSelectChange123}
                              input={<OutlinedInput label="Name" />}
                              MenuProps={{ autoClose: true }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  style={getStyles(option, selectedValuesSelect, theme)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </>
                      ) : (
                        <TextField
                          variant="standard"
                          {...register(`DataQuality[${index}].${item.field_id}`)}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Box>
              <Typography variant="h6">
                Data Quality Check is not selected.
              </Typography>
            </Box>
          )}
        </TableCell>
      </TableRow>
    );
  } else {
    return null;
  }
};

const findSectionField = (formObject, section, field = null) => {
  const sectionRecord = formObject.find((rec) => rec.section === section);
  if (field) {
    const fieldRecord = sectionRecord.fields_list.find(
      (rec) => rec.field_id === field
    );
    return fieldRecord;
  }
  return sectionRecord;
};

const DataQualityForm = ({
  taskFormFields,
  values,
  formType,
}) => {


  const [addTaskParameter] = useAddTaskParameterMutation();
  const [updateTaskParameter] = useUpdateTaskParameterMutation();
  const { data: tasksRecords } = useGetTasksDQQuery(values.id);


  const editValue = {
    dq_pre_check: "",
    dq_post_check: "",
    DataQuality: []
  };


  editValue.dq_pre_check = values.details.DataQuality?.parameters?.dq_pre_check === "1";
  editValue.dq_post_check = values.details.DataQuality?.parameters?.dq_post_check === "1";

  if (values?.details?.DataQuality !== undefined) {
    const dataQualityArray = values.details.DataQuality?.DataQuality || [];

    for (const item of dataQualityArray) {
      const updatedItem = {
        ...item,
        dq_active: item.dq_active === "1" ? true : false,
        dq_ignore_bad_records: item.dq_ignore_bad_records === "1" ? true : false,

      };

      const dqList = item.dq_list || '';
      const dqListArray = dqList.split(',').map(item => item.trim());
      updatedItem.dq_list = dqListArray;

      editValue.DataQuality.push(updatedItem);
    }
  }

  console.log(editValue, "editValue1");
  const [options, setOptions] = useState([]);

  const [data, setData] = useState(editValue);

  console.log("Data:1 ", data);

  const dataQualityRecord = findSectionField(taskFormFields, formType);

  let executionCheckForm = JSON.parse(
    JSON.stringify(dataQualityRecord.fields_list.executioncheck)
  );
  let dataQualityMultiForm = JSON.parse(
    JSON.stringify(dataQualityRecord.fields_list.record)
  );

  executionCheckForm.sort((a, b) => {
    return a.display_sequence - b.display_sequence;
  });

  dataQualityMultiForm.sort((a, b) => {
    return a.display_sequence - b.display_sequence;
  });

  console.log(dataQualityMultiForm, "dataQualityMultiForm")

  const checkFieldOptionList = dataQualityMultiForm.find(
    (rec) => rec.field_id === "dq_check"
  ).option_list;

  console.log(checkFieldOptionList, "checkFieldOptionList");

  const parameterFields = dataQualityMultiForm.find(
    (rec) => rec.field_id === "dq_parameters"
  ).params_field_list;
  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: data,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "DataQuality",
  });

  const [getDataQualityColumns] = useLazyGetDataQualityColumnsQuery();
  const [getDataQualityColumnsFiles] = useLazyGetDataQualityColumnsFilesQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (values?.source === "AWS S3" || values?.source === "Local Server" || values?.source === "Remote Server") {
          const { src_connection_name, src_file_type, src_file_name, src_file_path, src_delimiter, src_encoding } = values?.details?.Source || {};
          const srcconnectionType = values?.source.toLowerCase().replace(/\s/g, '');
          
          const result = await getDataQualityColumnsFiles({ srcconnectionType, src_connection_name, src_file_type, src_file_name, src_file_path, src_delimiter, src_encoding });
          const { data } = result;

          if (data) {
            const actualColumnsArray = data.metaDataInfo.map((item) => item.actualColumns);
            setOptions(actualColumnsArray);
          }
        } else {
          const { src_connection_name, src_table_name, src_schema } = values?.details?.Source || {};
          const srcconnectionType = values?.source;
  
          const result = await getDataQualityColumns({ src_connection_name, src_table_name, src_schema, srcconnectionType });

          const { data } = result;
          if (data) {
            const actualColumnsArray = data.metaDataInfo.map((item) => item.actualColumns);
            setOptions(actualColumnsArray);
          }
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
  
    fetchData(); 
 
  }, [values?.source, values?.details?.Source?.src_table_name, values?.details?.Source?.src_connection_name]);
  


  console.log(options, "optionsss")

  const handleSelectChange = (index) => (e) => {
    const selectedOption = e.target.value;
  
    const param_init_values = parameterFields.find(
      (rec) => rec.check_name === selectedOption
    ).param_initial_list;
  
    const { DataQuality, ...checkFields } = data;
  
    Object.keys(param_init_values).forEach(field => {
      setValue(`DataQuality[${index}].${field}`, param_init_values[field]);
    });
  
    setValue(`DataQuality[${index}].dq_check`, selectedOption);
  
    const newDQArray = DataQuality.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          dq_check: selectedOption,
          ...param_init_values,
        };
      } else {
        return item;
      }
    });
  
    const newData = { DataQuality: newDQArray, ...checkFields };
    setData(newData);    
  };
  
  const addOrEditRecordDQ = async (values, row) => {
 
    if (tasksRecords?.data[0]?.id > 0) {
      await updateTaskParameter({ data: values, task_id: row });
      toast.success(`DataQuality Updated Successfully`, { position: "top-center" });
    } else {
      await addTaskParameter({ data: values });
      toast.success(`DataQuality Created Successfully`, { position: "top-center" });
    }

  };

  const onSubmit = (data) => {
    dataQualitySubmit(data, values, addOrEditRecordDQ)
  };
  
  return (
    <Box
      display="grid"
      gap="10px"
      gridTemplateColumns="repeat(1, minmax(0, 1fr))"
      padding={4}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Box>
          <Typography variant="h6">Execution Check Fields</Typography>
        </Box>
        <Box
          display="grid"
          gap="10px"
          gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        >
          {
            executionCheckForm?.map((field) => (
              <FormControlLabel
                key={field.id}
                control={
                  <Checkbox
                    {...register(field.field_id)}
                    checked={watch(field.field_id)}
                  />
                }
                label={field.display_name}
              />
            ))}
        </Box> */}
        {/* <Box sx={{ marginTop: "20px" }}>
          <Typography variant="h6">Data Quatity Form</Typography>
        </Box> */}
        <br></br>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="button" onClick={() => append({})} sx={styles.btnCss}>
            Add
          </Button>
        </div>
        <br></br>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
              <TableCell align="jestify">Seq Number</TableCell>
                <TableCell align="jestify" >Check Type</TableCell>
                <TableCell align="right">Data Quality Check</TableCell>
                <TableCell align="right">Threshold %</TableCell>
                <TableCell align="right">Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => {
                return (
                  <>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell component="th" scope="row">
                       {index+1}
                     </TableCell>
                      <TableCell component="th" scope="row">
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                        >
                          <Select
                            {...register(`DataQuality[${index}].dq_type`)}
                            value={watch(`DataQuality[${index}].dq_type`)}
                            onChange={(e) =>
                              setValue(e.target.name, e.target.value)
                            }
                          >
                            <MenuItem value="pre_check">Pre Check</MenuItem>
                            <MenuItem value="post_check">Post Check</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right">
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                        >
                          <Select
                            {...register(`DataQuality[${index}].dq_check`)}
                            value={watch(`DataQuality[${index}].dq_check`)}
                            onChange={handleSelectChange(index)}
                          >
                            {
                              checkFieldOptionList?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="standard"
                          {...register(
                            `DataQuality[${index}].dq_threshold_bad_records`
                          )}
                        />
                      </TableCell>
                      {/* <TableCell align="right">
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...register(
                                `DataQuality[${index}].dq_ignore_bad_records`
                              )}
                              checked={watch(
                                `DataQuality[${index}].dq_ignore_bad_records`
                              )}
                            />
                          }
                        />
                      </TableCell> */}
                      <TableCell align="right">
                        <FormControlLabel
                          control={
                            <Checkbox

                              {...register(`DataQuality[${index}].dq_active`)}
                              checked={watch(`DataQuality[${index}].dq_active`)}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#4b437cd1!important",
                                },
                              }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          onClick={() => remove(index)}
                          variant="contained"
                          color="error"
                        >
                          X
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        
                        <Button
                          type="button"
                          onClick={() => append(index) }
                          variant="contained"
                          // color="error"
                        >
                          +
                        </Button>
                      </TableCell>
                    </TableRow>


                    <DQParameterFields
                      fieldName={watch(`DataQuality[${index}].dq_check`)}
                      options={options}
                      parameterFields={parameterFields}
                      register={register}
                      index={index}
                    />
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        
        <br />
        
        <Button type="submit" variant="contained" sx={styles.btnCss}>
          Submit
        </Button>
        <ToastContainer />
      </form>
    </Box>
  );
};

export default DataQualityForm;
