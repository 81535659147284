import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  useTheme,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlexBetween from "../components/FlexBetween";
import CancelIcon from '@mui/icons-material/Cancel';

const styles = {
  myBackground: {
    //backgroundColor: "#4681f4!important",
    boxshadow: "grey 0px 0px 5px!important",
    backgroundColor: "var(--primary-color) !important",
    color: "#FFFFFF",
    fontWeight: "800",
    fontSize: "14px",
  },
  myBorder: {
    border: "2px solid green",
  },
  myFont: {
    color: "blue",
  },
};

const Popup = (props) => {
  const theme = useTheme();
  const {
    title,
    children,
    openPopup,
    setOpenPopup,
    width,
    handleYesBtnClick,
    handleNoBtnClick,
    handleOkBtnClick,
    handleCancelBtnClick,
    yesBtn = false,
    noBtn = false,
    okBtn = false,
    cancelBtn = false,
  } = props;

  return (
    <Dialog
      open={openPopup}
      fullWidth={true}
      maxWidth={width}
      sx={{
        padding: theme.spacing(2),
        position: "absolute",
        top: theme.spacing(5),
        borderRadius: "8px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <DialogTitle
        sx={{
          paddingRight: "0px",
          backgroundColor: "var(--primary-color) !important",
          paddingTop: "6px",
          height: "45px",
        }}
      >
        <FlexBetween>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}

            sx={{
              margin: 0,
              fontFamily:  "Poppins, sans-serif",
              fontSize: "17px!important",
              fontWeight: "500 !important",
              lineHeight: "1.6!important",
              color: "white"
            }}
          >
            {title}
          </Typography>
          <Button
            color="secondary"
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CancelIcon  sx={{ color: 'white', fontSize: 24}} />
          </Button>
        </FlexBetween>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {yesBtn && (
          <Button
            sx={{
              backgroundColor: "var(--primary-color) !important",
              color: "#FFFFFF!important",
              fontWeight: "700",  
              "&:hover": {
                backgroundColor: "#45a049",
              },
            }}
            onClick={handleYesBtnClick}
          >
            Yes
          </Button>
        )}
        {noBtn && (
          <Button
            sx={{
              backgroundColor: "var(--primary-color) !important",
              color: "#FFFFFF!important",
              fontWeight: "700",            
              "&:hover": {
                backgroundColor: "#d32f2f",
              },
            }}
            onClick={handleNoBtnClick}
            autoFocus
          >
            No
          </Button>
        )}
        {okBtn && (
          <Button
            sx={{
              backgroundColor: "var(--primary-color) !important",
              color: "#FFFFFF!important",
              fontWeight: "700",  
              "&:hover": {
                backgroundColor: "#0d47a1",
              },
            }}
            onClick={handleOkBtnClick}
            autoFocus
          >
            OK
          </Button>
        )}
        {cancelBtn && (
          <Button
          sx={{
            backgroundColor: "var(--primary-color) !important",
            color: "#FFFFFF!important",
            fontWeight: "700",  
            "&:hover": {
              backgroundColor: "#0d47a1",
            },
          }}
            onClick={handleCancelBtnClick}
          >
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

};

export default Popup;
