import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useResetPasswordMutation } from "../state/apiSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Stack,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import useStyles from "useStyles";

const ResetPassword = () => {
  const styles = useStyles();
  const location = useLocation();
  const resetLink = location.pathname.split("/").pop();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Must be 8 characters or more")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    resetPassword({ resetLink, newPass: values.newPassword })
      .unwrap()
      .then(() => {
        resetForm();
        setErrorMessage("");
        setSuccessMessage("Thank you! Your password has been changed successfully.");
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(
          error.response?.data?.message ||
          "Incorrect token or it is expired"
        );
      });
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: "var(--primary-color)",
          color: "white",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "500",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            margin: 0,
            fontFamily: "Poppins, sans-serif",
            fontSize: "17px!important",
            fontWeight: "500 !important",
            lineHeight: "1.6!important",
            color: "white"
          }}
        >
          Reset Password
        </Typography>
      </DialogTitle>
      <DialogContent>
        {successMessage ? (
          <Typography
            variant="body1"
            sx={{
              marginTop: 2,
              fontFamily: "Poppins, sans-serif",
              color: "green",
              textAlign: "center",
            }}
          >
            {successMessage}
          </Typography>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, values }) => (
              <Form>
                <Stack spacing={2} marginTop={2}>
                  <Box>
                    <TextField
                      label="New Password"
                      name="newPassword"
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      InputLabelProps={{
                        style: {
                          color: "black",
                          fontSize: "15px",
                          fontFamily: "Poppins",
                        },
                      }}
                    />
                  </Box>

                  <Box>
                    <TextField
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      InputLabelProps={{
                        style: {
                          color: "black",
                          fontSize: "15px",
                          fontFamily: "Poppins",
                        },
                      }}
                    />
                  </Box>
                </Stack>
                {errorMessage && (
                  <Box
                    display="flex"
                    justifyContent="end"
                    marginTop={2}
                    style={styles.errorMsg}
                  >
                    {errorMessage}
                  </Box>
                )}
                <DialogActions style={{ marginTop: "20px" }}>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    style={styles.myBackground}
                  >
                    {isLoading ? "Resetting Password..." : "Reset Password"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ResetPassword;
