import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentUser } from "../state/authSlice";
import NavBar from "../components/NavBar";
import Sidebar from "../components/Sidebar";
import { useGetAdminUserQuery } from "state/apiSlice";
import Login from "../components/Login";
import Home from "../components/Home";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px");
  const fetchAdminUser = useGetAdminUserQuery(); 

  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    setIsSidebarOpen(user ? true : false);
  }, [user]);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <NavBar
        user={user}
        fetchAdminUser={fetchAdminUser}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      {token ? (
        <>
          <Sidebar
            user={user}
            fetchAdminUser={fetchAdminUser}
            isNonMobile={isNonMobile}
            drawerWidth="220px"
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <Box flexGrow={1} sx={{ mt: "35px" }}> {/* Adjust the margin-top based on your NavBar height */}
            <Outlet />
          </Box>
        </>
      ) : (
        <Box flexGrow={1} sx={{ mt: "35px" }}> {/* Adjust the margin-top based on your NavBar height */}
          <Login fetchAdminUser={fetchAdminUser} />
          <Home />
        </Box>
      )}
    </Box>
  );
};

export default Layout;
