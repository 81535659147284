import React, { createContext, useContext } from 'react';

const DeleteNodeContext = createContext(null);

export const useDeleteNode = () => useContext(DeleteNodeContext);

export const DeleteNodeProvider = ({ children, deleteNodeCallback }) => {
  return (
    <DeleteNodeContext.Provider value={deleteNodeCallback}>
      {children}
    </DeleteNodeContext.Provider>
  );
};