function FilterStatusBar(operations, groups) {
    let errorMessage;
  
    /**
     * Process a condition based on the provided column, operator, and value.
     *
     * @param {Object} operation - The operation object.
     * @returns {string} The processed condition.
     */
    function processCondition(operation) {
      try {
        let column = operation["operation_column_name"];
        let op = operation["operation_operator"];
        let val = operation["operation_field_value"];
        return `${column} ${op} ${val}`;
      } catch (error) {
        console.log("Error");
        errorMessage = "Grouping is not properly";
        return null;
      }
    }
  
    /**
     * Identify the endpoint of a dependency flow and validate tasks.
     *
     * @param {Object} dependencies - A dictionary where keys are tasks and values are lists of tasks they depend on.
     * @param {Array} tasks - A list of all implemented tasks.
     * @returns {string} The endpoint task.
     */
    function findEndpoint(dependencies, tasks) {
      try {
        let allDependentTasks = new Set();
        let allTasks = new Set(Object.keys(dependencies));
  
        for (let deps of Object.values(dependencies)) {
          deps.forEach((dep) => allDependentTasks.add(dep));
        }
  
        let potentialEndpoints = new Set(
          [...allTasks].filter((x) => !allDependentTasks.has(x))
        );
        if (potentialEndpoints.size !== 1) {
          errorMessage = "The flow does not end in a single point.";
        }
  
        let endpoint = [...potentialEndpoints][0];
        let allTasksUnion = new Set([...allTasks, ...allDependentTasks]);
        allTasksUnion.forEach((task) => {
          if (!tasks.includes(task)) {
            errorMessage = `Task '${task}' is not implemented in the provided task list.`;
          }
        });
  
        return endpoint;
      } catch (error) {
        errorMessage = "Error in findEndpoint: ";
        return null;
      }
    }
  
    /**
     * Apply grouping operations based on provided conditions and dependencies.
     *
     * @param {Array} groupNames - List of group names.
     * @param {Object} operations - A object of operations.
     * @param {string} groupName - Name of the current group to process.
     * @param {Object} dependency - A object to store dependencies.
     * @returns {Array} Updated dependency and operations dictionaries.
     */
    function applyGrouping(
      groupNames,
      operations,
      groups,
      groupName,
      dependency
    ) {
      try {
        let group = groups[groupName];
        let condition1Name = group["group_column1"];
        let condition2Name = group["group_column2"];
        dependency[groupName] = [condition1Name, condition2Name];
  
        let condition1 =
          operations[condition1Name] ||
          applyGrouping(
            groupNames,
            operations,
            groups,
            condition1Name,
            dependency
          )[1];
        let condition2 =
          operations[condition2Name] ||
          applyGrouping(
            groupNames,
            operations,
            groups,
            condition2Name,
            dependency
          )[1];
  
        let op = group["group_operator"];
        operations[groupName] = `(${condition1} ${op} ${condition2})`;
        return [dependency, operations];
      } catch (error) {
        errorMessage = "Error in applyGrouping: ";
        return null;
      }
    }
  
    /**
     * Apply filters based on the provided groups and operations.
     *
     * @param {Object} operations - Operation object.
     * @param {Object} groups - Group object.
     * @returns {string} The query string based on the filters.
     */
    function applyFilter(operation, groups) {
      try {
        let operations = {};
  
        //To fetch operations from operations
  
        Object.entries(operation).forEach(([operationId, operationValue]) => {
          const operationName = operationValue["operation_operation_name"];
          operations[operationName] = processCondition(operationValue);
        });
  
        let restructuredGroup = {};
  
        for (let key in groups) {
          if (groups.hasOwnProperty(key)) {
            let group = groups[key];
            restructuredGroup[group.group_group_name] = group;
          }
        }
  
        let groupNames = [];
        let dependency = {};
  
        //To fetch groupName
  
        Object.entries(restructuredGroup).forEach(([groupId]) => {
          groupNames.push(groupId);
        });
  
        for (let groupName of groupNames) {
          if (!(groupName in operations)) {
            [dependency, operations] = applyGrouping(
              groupNames,
              operations,
              restructuredGroup,
              groupName,
              dependency
            );
          }
        }
  
        let endpoint;
        if (Object.keys(dependency).length > 0) {
          endpoint = findEndpoint(dependency, Object.keys(operations));
        } else if (Object.keys(operations).length === 1) {
          endpoint = Object.keys(operations)[0];
        } else {
          errorMessage = "Invalid Syntax. Grouping not done properly.";
        }
        return operations[endpoint];
      } catch (error) {
        errorMessage = "Error in applyFilter: ";
        return null;
      }
    }
  
    let query;
    query = applyFilter(operations, groups);
    console.log(errorMessage);
    if (typeof errorMessage === "undefined") {
      return query;
    } else {
      query = undefined;
      return query;
    }
  }
  export default FilterStatusBar;