import React from "react";
import { Typography, Box, useTheme } from "@mui/material";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        variant="h2"
        fontWeight="bold"
        fontFamily= "Poppins"
        sx={{ mb: "5px " ,fontSize:"23px!important",color:"var(--primary-color) !important" }}
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        color={"#000000"}
        fontWeight="bold"
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
