import React, { useCallback, useState, useEffect, useRef } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Panel,
  Controls,
  MarkerType,
  useReactFlow,
} from "reactflow";
import { Alert, AlertTitle, Button, Box } from "@mui/material";
import dagre from "dagre";

import DefaultEdge from "../components/diagrams/DefaultEdge";

import "reactflow/dist/style.css";
import { convertEdges2NodeSequence } from "../services/pipeline.services";
import { useUpdateTaskSequenceMutation } from "../state/apiSlice";
import useStyles from "useStyles";
const proOptions = { hideAttribution: true };

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  const updatedNodes = nodes.map((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    return {
      ...node,
      position: {
        x: 0,
        y: 0,
      },
    };
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = updatedNodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

const edgeTypes = {
  defaultEdge: DefaultEdge,
};

const TaskSequenceFlow = (props) => {
  const styles = useStyles();


  const { pipelineTasks } = props;

  const [updateTaskSequence] = useUpdateTaskSequenceMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const { nodes: initialNodes, edges: initialEdges } = pipelineTasks[0];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges || [{}]);

  const onConnect = useCallback(
    (params) => setEdges((els) => addEdge(params, els)),
    []
  );

  const onDeleteEdge = (id) => {
    const match = id.match(/\d+$/);
    const lastNumber = match ? parseInt(match[0]) : 0;
    const data = [{ id: lastNumber, task_sequence: 0 }];
    updateTaskSequence({ data });
    setEdges((egdes) => egdes.filter((edge) => edge.id !== id));
  };
  const { fitView } = useReactFlow();


  // const onLayout = useCallback(() => {
  //   const layouted = getLayoutedElements(nodes, edges);

  //   setNodes([...layouted.nodes]);
  //   setEdges([...layouted.edges]);

  //   // window.requestAnimationFrame(() => {
  //   //   fitView();
  //   // });
  // }, [nodes, edges, fitView]);
  const onLayoutCalled = useRef(false);

  const onLayout = useCallback(() => {
    if (!onLayoutCalled.current) {
      const layouted = getLayoutedElements(nodes, edges);

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      onLayoutCalled.current = true;
    }
  }, [nodes, edges]);

  onLayout();

  // useEffect(() => {
  //   onLayout(); 
  // }, [onLayout, pipelineTasks]);


  const onSaveClick = async (e) => {
    const result = await convertEdges2NodeSequence(edges);
    const updatedJson = {
      ...result,
      data: result.data.filter(item => item.task_sequence !== "\"undefined\"")
    };
    if (updatedJson?.status === "error") {
      setErrorMessage(updatedJson.message);
    } else {
      setErrorMessage("");
      try {
        await updateTaskSequence({ data: updatedJson.data });
      } catch (error) {
        setErrorMessage("Error while task_sequence column in tbl_task.");
      }
    }
    // update the records in tbl_task.
  };

  const defaultEdgeOptions = {
    style: { stokeWidth: 2, stoke: "black" },
    type: "defaultEdge",
    data: { onDelete: onDeleteEdge },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "black",
    },
  };

  return (
    <div style={{ width: "800px", height: "500px" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        fitView="onLoad"
        onLayout={onLayout}

        proOptions={proOptions}
      >
        {/* <MiniMap /> */}
        <Controls />
        <Panel position="top-right">
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button
              variant="contained"
              onClick={onSaveClick}
              sx={styles.myBackground}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => onLayout("LR")}
              sx={styles.myBackground}
            >
              Arrange Items
            </Button>
          </Box>
        </Panel>
        <Panel position="bottom-left">
          {errorMessage !== "" && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default TaskSequenceFlow;
