import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Box, Typography, Paper } from "@mui/material";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OperationsComponent from "./FilterOperationsComponent";
import Group from "./FilterGroupForm";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import Element from "../components/Element";
import { Formik } from "formik";
import { useFormik } from "formik";
import ReadJson from '../services/ReadJson';
import CustomComponent from "./CustomForm";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px", // More rounded corners
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};


function Filter({ jsonData, onSave, filterdata, filterRecord, filterDetails, actualColumns, connectedInputs, initialValues }) {

  const [columns, setColumns] = useState([]);

  // const columnsDisplay = actualColumns||["employee_id","first_name","last_name","department","email","ProductID","ProductName","Price","Quantity","Category","PurchaseData"];
  const filterJson = [filterRecord]
  const filterRandomString = () => {
    var randomstring = require("randomstring");
    let generatedRandomString = randomstring.generate({
      length: 3,
      charset: "alphabetic",
      capitalization: "lowercase",
    });
    let inputRandomString = "fil_".concat(generatedRandomString);
    return inputRandomString;
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [finalOperation, setFinalOperation] = useState({});
  const [finalGroup, setFinalGroup] = useState({});
  const [renderChoice, setRenderChoice] = useState();
  const [displayOper, setDisplayOper] = useState([]);
  const [formPrev, setFormPrev] = useState({});
  const [fill, setFill] = useState(false);
  const [queryString, setQueryString] = useState();
  const [isQueryStringUndefined, setIsQueryStringUndefined] = useState(false);
  const [custom, setCustom] = useState({});
  const getOperationId = () => `Operation_${generateId()}`;
  const getGroupId = () => `Group_${generateId()}`;
  const getCustomId = () => `Custom_${generateId()}`;
  const generateId = () => {
    var randomstring = require("randomstring");
    let generatedRandomString = randomstring.generate({
      length: 3,
      charset: "numeric",
    });

    return generatedRandomString;
  };
  /**
  * Function to handle form submission.
  * @param {Object} values - The form values.
  */
  const onSubmit = (values) => {
    try {
      const newOperation = { ...values };

      if (renderChoice === "custom") {
        // Handling custom choice
        const id = getCustomId();
        const customJSON = {
          [id]: {
            ...newOperation,
            id: id,
          },
        };
        setCustom(customJSON);
        setFinalGroup({});
        setFinalOperation({});
        setFill(true);
      } else if (renderChoice === "operation") {
        // Save operation
        saveOperation(newOperation);
        setCustom({});
        setFormPrev({});
      } else if (renderChoice === "group") {
        // Save group
        saveGroup(newOperation);
      }
      setFill(true);
    } catch (error) {
      throw error;
    }
  };

  /**
   * Function to save a new or edited operation.
   * @param {Object} newOperation - The new operation data.
   */
  const saveOperation = (newOperation) => {
    try {
      if (
        !isEditing &&
        Object.keys(finalOperation).some((operationKey) => {
          const operationName =
            finalOperation[operationKey]?.operation_operation_name;
          return operationName === newOperation.operation_operation_name;
        })
      ) {
        alert("Operation Name already exists");
        return;
      } else if (isEditing) {
        // Count occurrences of the operation name
        const occurrences = Object.keys(finalOperation).filter(
          (operationKey) => {
            return (
              finalOperation[operationKey]?.operation_operation_name ===
              newOperation.operation_operation_name
            );
          }
        ).length;

        if (
          newOperation.operation_operation_name !==
          formPrev.operation_operation_name &&
          occurrences >= 1
        ) {
          alert(
            "This operation name already exists please give it any another name"
          );
        } else {
          // Update existing operation
          finalOperation[newOperation.id] = {
            ...finalOperation[newOperation.id],
            ...newOperation,
          };
        }
      } else {
        // Create a new operation
        const id = getOperationId();
        const operationJSON = {
          ...finalOperation,
          [id]: {
            ...newOperation,
            id: id,
          },
        };
        setFinalOperation(operationJSON);
      }
      setIsEditing(false);
    } catch (error) {
      throw ("Error while saving a operation", error);
    }
  }

  /**
   * Function to save a new or edited group.
   * @param {Object} newGroup - The new group data.
   */
  const saveGroup = (newGroup) => {
    try {
      if (
        !isEditing &&
        Object.keys(finalGroup).some((groupKey) => {
          const groupName = finalGroup[groupKey]?.group_group_name;
          return groupName === newGroup.group_group_name;
        })
      ) {
        alert("Group Name already exists");
        return;
      } else if (isEditing) {
        // Count occurrences of the group name
        const occurrences = Object.keys(finalGroup).filter((groupKey) => {
          return (
            finalGroup[groupKey]?.group_group_name ===
            newGroup.group_group_name
          );
        }).length;

        if (
          newGroup.group_group_name !== formPrev.group_group_name &&
          occurrences >= 1
        ) {
          alert(
            "This group name already exists please give it any another name"
          );
        } else {
          // Update existing group
          finalGroup[newGroup.id] = {
            ...finalGroup[newGroup.id],
            ...newGroup,
          };
        }
      } else {
        // Create a new group
        const id = getGroupId();
        const groupJSON = {
          ...finalGroup,
          [id]: {
            ...newGroup,
            id: id,
          },
        };
        setFinalGroup(groupJSON);
      }
      setIsEditing(false);
    } catch (error) {
      throw ("Error while saving group", error);
    }
  };

  try {
    if (fill) {
      // Check if both finalOperation and finalGroup are empty
      if (
        Object.keys(finalOperation).length === 0 &&
        Object.keys(finalGroup).length === 0
      ) {
        setIsQueryStringUndefined(false);
        setQueryString("Fitler Condition => "?.concat(Object.values(custom)[0]?.custom_field));
      } else {
        const query = ReadJson(finalOperation, finalGroup);
        if (typeof query === "undefined") {
          setIsQueryStringUndefined(true);
        } else {
          setIsQueryStringUndefined(false);
          setQueryString("Filter Condition => ".concat(query));
        }
      }

      const operationName = [];
      const groupName = [];
      for (const key in finalOperation) {
        if (finalOperation.hasOwnProperty(key)) {
          operationName.push(finalOperation[key].operation_operation_name);
        }
      }
      for (const key in finalGroup) {
        if (finalGroup.hasOwnProperty(key)) {
          groupName.push(finalGroup[key].group_group_name);
        }
      }
      setDisplayOper([...operationName, ...groupName]);
      setFill(false);
    }
  } catch (error) {
    console.log("Error", error);
  }
  const handleDisplay = (id) => {
    setFormPrev(finalOperation[id]);
    setRenderChoice("operation")
    // dispatch(dataPrev(finalOperation[id]));
    // setModalOpen(true);
    setIsEditing(true);
  };

  const handleGroupDisplay = (id) => {
    setFormPrev(finalGroup[id]);
    // dispatch(dataPrev(finalGroup[id]));
    // setModalOpen(true);
    setRenderChoice("group")
    setIsEditing(true);
  };

  const handleBack = () => {
    setModalOpen(false);
  };


  // Function to handle opening model for deletion confirmation

  const handleOpenModel = (id) => {
    try {
      setModalOpen(true);
      setDeleteId(id);
    } catch (error) {
      console.log("Error while opening confirmation bar", error);
    }
  }

  //Function to call handleDeleteOpeartion or handleDeleteGroup on the basis of prefix of Id

  const handleDelete = () => {
    try {
      setModalOpen(false);
      if (deleteId.startsWith("Operation")) {
        handleDeleteOperation(deleteId);
      } else {
        handleDeleteGroup(deleteId);
      }
    } catch (error) {
      console.log("Error while closing model");
    }
  };

  //Function to delete a operation based on the operation Id

  const handleDeleteOperation = (id) => {
    try {
      const tempUpdate = { ...finalOperation };
      delete tempUpdate[id];
      setFinalOperation(tempUpdate);
      setFill(true);
    } catch (error) {
      console.log("Error while deleting operation", error);
    }
  };

  //Function to delete a group based on the group Id

  const handleDeleteGroup = (id) => {
    try {
      const tempUpdate = { ...finalGroup };
      delete tempUpdate[id];
      setFinalGroup(tempUpdate);
      setFill(true);
    } catch (error) {
      console.log("Error while deleting group", error);
    }
  };

  const discardFilter = () => {
    setDisplayOper([]);
    setFinalOperation({});
    setFinalGroup({});
    formik.resetForm();
  };
  const [deleteId, setDeleteId] = useState();
  const [formValidation, setFormValidation] = useState(null);


  const getInitialValues = (filterJson) => {
    try {
      const initialValues = {};
      for (const key in filterJson[0].initialvalues) {
        if (key === "tranformation_name") {
          initialValues[key] = filterJson[0].initialvalues[key];
        }
        else if (key === "output_df") {
          initialValues[key] = filterRandomString();
        } else {
          if (
            !key.startsWith("operation") &&
            !key.startsWith("group") &&
            !key.startsWith("custom")
          ) {
            initialValues[key] = filterJson[0].initialvalues[key];
          }
        }
      }
      return initialValues;
    } catch (error) {
      console.log("Error while fetching initial values", error);
      return {}; // Returning an empty object in case of error
    }
  };
  const getFieldValues = (filterJson) => {
    try {
      const fieldValues = [];
      const fieldsList = filterJson[0].fields_list;
      for (const field of fieldsList) {
        if (
          !field.field_id.startsWith("operation") &&
          !field.field_id.startsWith("group") &&
          !field.field_id.startsWith("custom")
        ) {
          fieldValues.push(field);
        }
      }

      return fieldValues;
    } catch (error) {
      console.log("Error while fetching field list", error);
      return []; // Returning an empty array in case of error
    }
  };

  const fieldsList = getFieldValues(filterJson);

  if (formValidation === null) {
    let validationObject = {};
    fieldsList?.forEach(
      ({ field_id, display_label, required, field_value }) => {
        if (required === "Y") {
          validationObject[field_id] = yup.string().required("Required");
        }
      }
    );
    const validationSchema = yup.object().shape(validationObject);
    setFormValidation(validationSchema);
  }
  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const newOperation = { ...values };
      const Filter = {};
      const mainKey = newOperation.transformation_name;
      Filter[mainKey] = {
        transformation_name: newOperation.transformation_name,
        input_df: newOperation.input_df,
        output_df: newOperation.output_df,
        select_columns: columns,
      };
      if (
        custom &&
        typeof custom === "object" &&
        Object.keys(custom).length > 0
      ) {
        const dynamicKey = Object.keys(custom)[0];

        const customKey = `${mainKey}_${dynamicKey}`;
        Filter[mainKey][customKey] = {
          id: customKey,
          condition_type: "custom",
          custom_field: custom[dynamicKey].custom_field,
        };
      }

      // Check if finalOperation is valid
      else {
        if (finalOperation && typeof finalOperation === "object") {
          for (const key in finalOperation) {
            if (Object.hasOwnProperty.call(finalOperation, key)) {
              const cond = finalOperation[key];
              const conditionKey = `${mainKey}_${cond.id}`; // Append mainKey prefix
              Filter[mainKey][conditionKey] = {
                operation_name: cond.operation_operation_name,
                id: conditionKey,
                condition_type: "operation",
                column_name: cond.operation_column_name,
                operator: cond.operation_operator,
                field_value: cond.operation_field_value,
              };
            }
          }
        }

        // Check if finalGroup is valid
        if (finalGroup && typeof finalGroup === "object") {
          for (const key in finalGroup) {
            if (Object.hasOwnProperty.call(finalGroup, key)) {
              const group = finalGroup[key];
              const groupKey = `${mainKey}_${group.id}`; // Append mainKey prefix
              Filter[mainKey][groupKey] = {
                group_name: group.group_group_name,
                operator: group.group_operator,
                id: groupKey,
                condition_type: "group",
              };

              // Function to find object by name
              function findObjectByName(name) {
                for (const groups in finalGroup) {
                  if (finalGroup[groups].group_group_name === name) {
                    return finalGroup[groups].id;
                  }
                }
                for (const operations in finalOperation) {
                  if (finalOperation[operations].operation_operation_name === name) {
                    return finalOperation[operations].id;
                  }
                }

              }

              // Check if group columns exist and assign conditions
              const column1Key = findObjectByName(group.group_column1);
              if (column1Key) {
                Filter[mainKey][groupKey]["Condition_01"] = `${mainKey}_${column1Key}`;
              }

              const column2Key = findObjectByName(group.group_column2);
              if (column2Key) {
                Filter[mainKey][groupKey]["Condition_02"] = `${mainKey}_${column2Key}`;
              }
            }
          }
        }
      }
      let filterData = {
        "Filter": Filter,
        select_columns: columns,
        output_df:newOperation.output_df,
      }

      onSave(filterData);
      resetForm();
    } catch (error) {
      console.log("Error while saving the filter", error);
    }
  };
  fieldsList.sort(function (a, b) {
    return a.display_sequence - b.display_sequence;
  });

  const formik = useFormik({
    initialValues: getInitialValues(filterJson),
    validationSchema: formValidation,
    onSubmit: handleFormSubmit,
  });
  
  useEffect(() => {
    if(initialValues === undefined){
      setColumns({});
      setFinalGroup({});
      setFinalOperation({});
      formik.values.transformation_name = '';
      formik.values.condition_type = '';
      setRenderChoice('');
      setQueryString('');
 
    }
    if (connectedInputs.length >= 1) {
      let columnString ;
      if(connectedInputs[0].script_type == "shell_script")
        columnString=connectedInputs[0]?.select_columns
      else if(connectedInputs[0].script_type == "inline_code")
        columnString = connectedInputs[0]?.select_columns_1;
      else
      columnString = connectedInputs[0]?.select_columns;      if (columnString && !Array.isArray(columnString)) {
        columnString = columnString.split(',').map(col => col.trim());
      }
      setColumns(columnString);
    }

    extractIncomingData(initialValues);
  }, [connectedInputs, initialValues]);

  useEffect(() => {
    let outputDf = null;
    if (connectedInputs?.length > 0) {
      outputDf = Object.values(connectedInputs[0])?.find(obj => obj?.output_df);
    }

    const outputDfValue = connectedInputs[0]?.output_df || Object.values(connectedInputs[0] || {}).flatMap(obj => Object.values(obj || {})).find(obj => obj?.output_df)?.output_df;
    if (outputDfValue && formik.values.input_df !== outputDfValue) {
      formik.setValues({
        ...formik.values,
        input_df: outputDfValue,
      });
    }
      
  }, [connectedInputs, formik.values.input_df]);

  const handleInputChange = (event) => {
    try {
      const { name, value } = event.target;
      formik.handleChange(event);
      if (name === "condition_type" && value === "operation") {
        setIsEditing(false)
        setRenderChoice("operation");
      } else if (name === "condition_type" && value === "group") {
        setIsEditing(false)

        setRenderChoice("group");
      }
      else if (name === "condition_type" && value === "custom") {
        setIsEditing(false)

        setRenderChoice("custom");
      }
    } catch (error) {
      console.log("Error while changing the drop drown value", error);
    }
  };

  
  
  const extractData = (Filter) => {
    const mainKey = Object.keys(Filter)[0]; // Assuming there is only one key in Filters
    const recOperation = {};
    const recGroup = {};
    const recCustom = {};

    if (mainKey) {
      const mainFilter = Filter[mainKey];
      for (const key in mainFilter) {
        const data = mainFilter[key];
        if (data?.condition_type === "custom") {
          recCustom[key.replace(new RegExp(`^${mainKey}_`), "")] = {
            id: key.replace(new RegExp(`^${mainKey}_`), ""),
            custom_field: data.custom_field,
          };
          setFormPrev({ custom_field: data.custom_field });
        }
        if (data?.condition_type === "operation") {
          recOperation[key.replace(new RegExp(`^${mainKey}_`), "")] = {
            id: key.replace(new RegExp(`^${mainKey}_`), ""), // Extract the id
            operation_operation_name: data.operation_name,
            operation_column_name: data.column_name,
            operation_operator: data.operator,
            operation_field_value: data.field_value,
          };
        } else if (data?.condition_type === "group") {
          recGroup[key.replace(new RegExp(`^${mainKey}_`), "")] = {
            id: key.replace(new RegExp(`^${mainKey}_`), ""), // Extract the id
            group_group_name: data.group_name,
            group_operator: data.operator,
          };


          if (data.Condition_01) {
            recGroup[key.replace(new RegExp(`^${mainKey}_`), "")].group_column1 =
              Filter[mainKey][data.Condition_01]?.operation_name || Filter[mainKey][data.Condition_01]?.group_name;
          }
          if (data.Condition_02) {
            recGroup[key.replace(new RegExp(`^${mainKey}_`), "")].group_column2 =
              Filter[mainKey][data.Condition_02]?.operation_name || Filter[mainKey][data.Condition_01]?.group_name;
          }
        }
      }

      formik.values.transformation_name = mainFilter.transformation_name;
        formik.values.input_df = mainFilter.input_df;
        formik.values.output_df = mainFilter.output_df;
        formik.values.condition_type = "custom";
    }
    setRenderChoice("custom");
    setFinalGroup(recGroup);
    setFinalOperation(recOperation);
    setCustom(recCustom)
    setFill(true);
  };

 
  
  const extractIncomingData = (filterData) => {
    if (typeof filterData === 'object' && filterData !== null) {
      if ('transformation_name' in filterData && 'input_df' in filterData && 'output_df' in filterData) {
        const transformationName = filterData.transformation_name;
        const newObj = {
          [transformationName]: filterData
        };
        extractData(newObj);
      }
      else {
        extractData(filterData.Filter);
      }
    }
  };

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, margin: "auto", maxWidth: "1400px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div>
            <div style={{}}>
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "360px",
                        fontSize: "16px",
                        marginLeft: "12px",
                        backgroundColor: isQueryStringUndefined
                          ? "#FFFF8F"
                          : "#FFFFFF",
                        color: "#333",
                      }}
                    >
                      {isQueryStringUndefined ? (
                        <>
                          <span
                            style={{ marginRight: "10px", fontSize: "20px" }}
                          >
                            ⚠️
                          </span>
                          <span style={{ flexGrow: "1" }}>
                            Grouping is not done properly
                          </span>
                        </>
                      ) : (
                        <p style={{ margin: 0, paddingLeft: "12px" }}>
                          {queryString}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        width: "360px",
                        marginLeft: "12px",
                      }}
                    >
                      <Button 
                         sx={{
                          backgroundColor: "rgb(0, 72, 190)!important",
                          color: 'white',
                        }}
                      type="submit" variant="outlined" >
                        Save Filter
                      </Button>
                      {/* <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "rgb(0, 72, 190)!important",
                          color: 'white',
                        }}
                        onClick={discardFilter}
                      >
                        Discard Filter
                      </Button> */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {fieldsList
                      ? fieldsList.map((field) => (
                        <Element
                          key={field.id}
                          field={field}
                          values={formik.values}
                          touched={formik.touched}
                          errors={formik.errors}
                          handleBlur={formik.handleBlur}
                          handleChange={handleInputChange}
                          disabled={field.field_id === "inp_df"}
                        />
                      ))
                      : null}
                  </div>
                </Box>
              </form>
            </div>
 
            {renderChoice === "operation" && (
              <>
                <OperationsComponent
                  onSubmit={onSubmit}
                  isEditing={isEditing}
                  filterJson={filterJson}
                  columnsDisplay={columns}
                  dataPrev={formPrev}
                />
              </>
            )}
 
            {renderChoice === "group" && (
              <>
                <Group
                  onSubmit={onSubmit}
                  isEditing={isEditing}
                  filterJson={filterJson}
                  displayOper={displayOper}
                  dataPrev={formPrev}
                />
              </>
            )}
 
            {renderChoice === "custom" && (
              <>
                <CustomComponent
                  onSubmit={onSubmit}
                  filterJson={filterJson}
                  dataPrev={formPrev}
                />
              </>
            )}
          </div>
 
          <div
            style={{
              display: "flex",
              margin: "12px",
              flexWrap: "wrap",
            }}
          >
            <Box
            >
              <p style={{ marginLeft: "20px" }}>OPERATION</p>
              {Object.entries(finalOperation).map(([id, operation]) => (
                <div
                  key={id}
                  style={{
                    margin: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => handleDisplay(id)}
                    variant="contained"
                    sx={{ mr: 2 }}
                  >
                    {operation.operation_operation_name}
                  </Button>
 
                  <Button
                    onClick={() => handleOpenModel(id, "Operation")}
                    variant="contained"
                    color="error"
                  >
                    Delete
                  </Button>
                </div>
              ))}
            </Box>
 
            <Box
            >
              <p style={{ marginLeft: "20px" }}>GROUP</p>
              {Object.entries(finalGroup).map(([id, operation]) => (
                <div
                  key={id}
                  style={{
                    margin: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => handleGroupDisplay(id)}
                    variant="contained"
                    sx={{ mr: 2 }}
                  >
                    {operation.group_group_name}
                  </Button>
 
                  <Button
                    onClick={() => handleOpenModel(id)}
                    variant="contained"
                    color="error"
                  >
                    Delete
                  </Button>
                </div>
              ))}
            </Box>
          </div>
        </div>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={() => setModalOpen(false)}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2 }}
            >
              Confirm Deletion
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mb: 3, textAlign: "center" }}
            >
              Are you sure you want to delete this condition? Once deleted, it
              cannot be accessed again
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleBack}
                sx={{ bgcolor: "grey.500" }}
              >
                Cancel
              </Button>
              <Button variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
      </Paper>
    </>
  );
}
export default Filter;
