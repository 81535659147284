
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Element from "../components/Element";
import * as Yup from "yup";
import extractDataFromInitialData from "../services/Extract"
import getInitialFormikValues from "../services/getinitialvalues"

import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  Add as AddIcon,
  Save as SaveIcon,
  ReplayOutlined as ClearIcon,
  Edit as PencilIcon,
  DeleteOutline as TrashIcon,
} from "@mui/icons-material";
import { lastIndexOf } from "lodash";
// import "./style.css";

var randomstring = require("randomstring");
let formValue = { Expression: {} };
const validationSchema = Yup.object({
  transformation_name: Yup.string()
    .required("Transformation name is required")
    .min(3, "Transformation name must be at least 3 characters long"),
  expression_list: Yup.object().test(
    "is-non-empty",
    "Expression list cannot be empty",
    (value) => value && Object.keys(value).length > 0
  ),
});

/**
 * Component to manage transformations and expressions.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.sourceColumn - The source column data.
 * @param {Function} props.onEditColumn - Function to handle editing a column.
 * @param {Object} props.editedColumn - The edited column data.
 * @param {Function} props.onSubmitOutputColNames - Function to handle the submission of output column names.
 * @param {Array} props.initialData - The initial data.
 * @param {Object} props.formData - The form data.
 * @returns {JSX.Element}
 */

export default function Component({
  sourceColumn,
  onEditColumn,
  editedColumn,
  onSubmitOutputColNames,
  initialData,
  formData,
  connectedInputs
}) {
  console.log(initialData,"check initialData");
  // Helper function to get initial field values for specific fields
  const getExpListFirst = (formData) => {
    try {
      const groupFieldValues = [];
      const fieldsList = formData.fields_list;

      for (const field of fieldsList) {
        if (
          field.field_id === "transformation_name" ||
          field.field_id === "input_df" ||
          field.field_id === "output_df"
        ) {
          groupFieldValues.push(field);
        }
      }
      return groupFieldValues;
    } catch (error) {
      console.log("Error while fetching group field values", error);
      return []; // Return empty array in case of error
    }
  };

  // Helper function to get field values within a specific display sequence range
  const getExpF = (formData) => {
    try {
      const groupFieldValues = [];
      const fieldsList = formData.fields_list;

      for (const field of fieldsList) {
        if (field.display_sequence >= 4 && field.display_sequence <= 6) {
          groupFieldValues.push(field);
        }
      }
      return groupFieldValues;
    } catch (error) {
      console.log("Error while fetching group field values", error);
      return []; // Return empty array in case of error
    }
  };

  const fieldsList = getExpListFirst(formData);
  const fl = getExpF(formData);
  const [columns, setColumns] = useState([]);
  const [clearSequence, setClearSequence] = useState(null);
  const getRowFields = (formData) => {
    try {
      const groupFieldValues = [];
      const fieldsList = formData.fields_list;

      for (const field of fieldsList) {
        if (field.display_sequence >= 4 && field.display_sequence <= 6) {
          groupFieldValues.push(field);
        }
      }
      return groupFieldValues;
    } catch (error) {
      console.log("Error while fetching group field values", error);
      return []; // Return empty array in case of error
    }
  };
  const rowFleids = getRowFields(formData);
  
  // Initialize Formik outside useEffect with default initial values
  const initialFormikValues = getInitialFormikValues(formData);
  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: validationSchema,
  });
  // Effect to handle initial data and set form values  
  useEffect(() => {
    if (
      initialData != {} &&
      Object.keys(initialData).length > 0 &&
      Object.keys(initialData)[0] !== "undefined"
    ) {
      const mainKey = Object.keys(initialData)[0];
      const expressionData = initialData[mainKey];

      if (expressionData && expressionData.expression_list) {
        // Transform the keys in expression_list
        const transformedExpressionList = {};
        Object.keys(expressionData.expression_list).forEach((key) => {
          const index= key.lastIndexOf("_")
          const newKey = key.substring(index+1); 
          transformedExpressionList[newKey] =
            expressionData.expression_list[key];
        });

        const initialValues = {
          transformation_name: expressionData.transformation_name || "",
          input_df: expressionData.input_df || "",
          output_df: expressionData.output_df || "",
          expression_list: transformedExpressionList,
        };

        setColumns(initialValues.expression_list);
        formik.setValues(initialValues);
      }
    } else if (sourceColumn && Array.isArray(sourceColumn)) {
      const generatedOutputDf = `exp_${randomstring.generate({
        length: 3,
        charset: "alphabetic",
        capitalization: "lowercase",
      })}`;
     
      const initialExpressionList = sourceColumn.reduce(
        (acc, header, index) => {
          acc[index + 1] = {
            sequence: index + 1,
            input_col_name: header,
            output_col_name: header,
            expression_value: " ",
            operatorType: "",
            operator: "",
          };
          return acc;
        },
        {}
      );
   

     
      
      const updatedValues = {
        ...initialFormikValues, // Maintain other initial values
        output_df: generatedOutputDf,
        // input_df:connectedInputs[0]?.output_df||outputDf?.output_df||"",
        expression_list: sourceColumn.reduce((acc, header, index) => {
          acc[index + 1] = {
            sequence: index + 1,
            input_col_name: header,
            output_col_name: header,
            expression_value: " ",
            operatorType: "",
            operator: "",
          };
          return acc;
        }, {}),
      };

      setColumns(initialExpressionList);

      // Update Formik values
      formik.setValues(updatedValues);
    }
  }, [sourceColumn,initialData]);
  // Effect to handle edited column updates
  useEffect(() => {
    if (editedColumn) {
      setColumns((prevColumns) => ({
        ...prevColumns,
        [editedColumn.sequence]: editedColumn,
      }));

      const updatedValues = {
        ...formik.values, // Maintain other formik values
        expression_list: {
          ...formik.values.expression_list,
          [editedColumn.sequence]: editedColumn,
        },
      };

      // Update Formik values
      formik.setValues(updatedValues);
    }
  }, [editedColumn]);
  useEffect(() => {
    let outputDf = null; 
    if (connectedInputs?.length > 0) {
      outputDf = Object.values(connectedInputs[0])?.find(obj => obj?.output_df);
    }
    const outputDfValue = connectedInputs[0]?.output_df || Object.values(connectedInputs[0] || {}).flatMap(obj => Object.values(obj || {})).find(obj => obj?.output_df)?.output_df;
  
    if (outputDfValue && formik.values.input_df !== outputDfValue) {
      formik.setValues({
        ...formik.values,
        input_df: outputDfValue,
      });
    }
  }, [connectedInputs, formik.values.input_df]);

  // Function to add a new row/column
  const addRow = () => {
    const newSequence = Object.keys(columns).length + 1;
    const newColumn = {
      sequence: newSequence,
      input_col_name: "",
      output_col_name: `new_column${newSequence}`,
      expression_value: "",
      operatorType: "",
      operator: "",
    };

    // Update Formik values with the new column
    const updatedValues = {
      ...formik.values, // Maintain other formik values
      expression_list: {
        ...formik.values.expression_list,
        [newSequence]: newColumn,
      },
    };

    // Update Formik values
    formik.setValues(updatedValues);

    setColumns((prevColumns) => ({
      ...prevColumns,
      [newSequence]: newColumn,
    }));
  };

  // Function to handle saving form values
  const handleSave = () => {
    const formValues = { Expression: {} , output_df : formik.values.output_df};
    const mainkey = formik.values.transformation_name;
 
    // Transforming the columns to have the desired keys
    const transformedColumns = {};
    const outputColNames = [];
    Object.keys(columns).forEach((key, index) => {
      const newKey = `${mainkey}_${(index + 1).toString()}`;
      transformedColumns[newKey] = {
        ...columns[key],
        sequence: index + 1, // ensuring sequence remains correct
      };
      outputColNames.push(transformedColumns[newKey].output_col_name);
    });
 
    formValues.Expression[mainkey] = {
      transformation_name: formik.values.transformation_name,
      input_df: formik.values.input_df,
      output_df: formik.values.output_df,
      expression_list: transformedColumns,
    };
    // formValues.Expression[mainkey].select_columns = outputColNames;
    formValues.select_columns=outputColNames;
 
    formik.handleSubmit();
    onSubmitOutputColNames(formValues);
    formValue = formValues;
  };

  // Function to handle deleting a column
  const handleDelete = (sequence) => {
    setColumns((prevColumns) => {
      const newColumns = { ...prevColumns };
      delete newColumns[sequence];

      // Update Formik values after deleting the column
      const updatedValues = {
        ...formik.values, // Maintain other formik values
        expression_list: Object.fromEntries(
          Object.entries(newColumns).map(([key, value], index) => [
            index + 1,
            { ...value, sequence: index + 1 },
          ])
        ),
      };

      // Update Formik values
      formik.setValues(updatedValues);

      return Object.fromEntries(
        Object.entries(newColumns).map(([key, value], index) => [
          index + 1,
          { ...value, sequence: index + 1 },
        ])
      );
    });
  };

  // Function to handle clearing a column's expression values
  const handleClear = (sequence) => {
    // Clear the specified sequence
    const updatedColumn = {
      ...columns[sequence],
      expression_value: "",
      operatorType: "",
      operator: "",
    };

    // Update Formik values after clearing the column
    const updatedValues = {
      ...formik.values, // Maintain other formik values
      expression_list: {
        ...formik.values.expression_list,
        [sequence]: updatedColumn,
      },
    };

    // Update Formik values
    formik.setValues(updatedValues);
    formik.setFieldValue(`expression_list.${sequence}`, updatedColumn);

    // Update state to reflect cleared column
    setColumns((prevColumns) => ({
      ...prevColumns,
      [sequence]: updatedColumn,
    }));
  };

  // Function to handle drag and drop reordering of columns
  const onDragEnd = (result) => {
    if (!result.destination) return;
  
    const reorderedColumns = Array.from(Object.entries(columns));
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);
  
    const updatedColumns = reorderedColumns.reduce((acc, [key, column], index) => {
      const newKey = (index + 1).toString();
      acc[newKey] = { ...column, sequence: index + 1 };
      return acc;
    }, {});
  
    setColumns(updatedColumns);
  
    const updatedExpressionList = Object.keys(updatedColumns).reduce((acc, key) => {
      const column = updatedColumns[key];
      acc[key] = column;
      return acc;
    }, {});
  
    const updatedValues = {
      ...formik.values,
      expression_list: updatedExpressionList,
    };
  
    formik.setValues(updatedValues);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const handleEditClick = (column) => {
    setSelectedRow(column.sequence);
    onEditColumn(column);
  };

  return (
    <Paper className="main-container" style={{ width: "70%", maxWidth: "1500px", borderWidth: "3px", display: "flex", flexDirection: "column" }}>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-6 max-w-4xl mx-auto">
          <div className="grid grid-cols-1 gap-6 border rounded-lg p-6" style={{ borderWidth: "4px" }}>
            <div className="flex items-center gap-4">
              <div className="flex-4">
               
                <Box
                  className="flex items-center border-b pb-2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    marginTop: 1,
                  }}
                >
                  <Box>
                    {fieldsList
                      ? fieldsList.map((field) => (
                          <Element
                            key={field.field_id}
                            field={field}
                            values={formik.values}
                            touched={formik.touched}
                            errors={formik.errors}
                            handleBlur={formik.handleBlur}
                            handleChange={formik.handleChange}
                          />
                        ))
                      : null}
                  </Box>
                  <Box className="Button-container" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "2px", marginTop: "8px", marginLeft: "auto", marginRight: "8px" }}>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      size="small"
                      onClick={addRow}
                      sx={{
                        backgroundColor: 'var(--primary-color) !important',
                        color: 'white',
                        fontFamily: "Poppins"
                      }}
                    >
                      Add New Columns
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<SaveIcon />}
                      onClick={handleSave}
                      size="small"
                      sx={{
                        backgroundColor: 'var(--primary-color) !important',
                        color: 'white',
                        fontFamily: "Poppins"
                      }}
                    >
                      Update Expression
                    </Button>
                  </Box>
                </Box>
                {formik.touched.transformation_name &&
                formik.errors.transformation_name ? (
                  <div className="text-red-600">
                    {formik.errors.transformation_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="scrollable-table-body" style={{ display: "flex", flexDirection: "column", position: "relative" }}>
      <div className="border rounded-lg overflow-hidden" style={{ position: "relative" }}>
        <TableContainer className="tableContainer" style={{ width: "100%", maxHeight: "400px", overflowY: "auto" }}>
          <Table stickyHeader>
            <TableHead className="fixed-headers" style={{ position: "sticky", top: 0, zIndex: 2, backgroundColor: "white" }}>
              <TableRow>
                <TableCell className="font-bold text-center" sx={{ fontWeight: "bold" }}>
                  Sequence
                </TableCell>
                <TableCell className="font-bold text-center" sx={{ fontWeight: "bold" }}>
                  Input Column
                </TableCell>
                <TableCell className="font-bold text-center" sx={{ fontWeight: "bold" }}>
                  Output Column
                </TableCell>
                <TableCell className="font-bold text-center" sx={{ fontWeight: "bold" }}>
                  Expressions
                </TableCell>
                <TableCell className="font-bold text-center" sx={{ fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="scrollable-body">
              {Object.values(columns).map((column, index) => (
                <TableRow 
                  key={column?.sequence?.toString() || '0'}
                  style={{
                    backgroundColor: selectedRow === column.sequence ? '#d3d3d3' : 'transparent',
                  }}
                >
                  <TableCell className="text-center">{column.sequence}</TableCell>
                  {rowFleids
                    ? rowFleids.map((field) => {
                        const key = `expression_list`;
                        return (
                          <TableCell key={field.field_id} className="text-center">
                            <Element
                              key={field.field_id}
                              field={field}
                              values={formik.values[key][column?.sequence]}
                              touched={formik.touched}
                              errors={formik.errors}
                              handleBlur={formik.handleBlur}
                              handleChange={
                                field.field_id === "output_col_name"
                                  ? (e) => {
                                      const newColumns = {
                                        ...columns,
                                        [column?.sequence]: {
                                          ...columns[column?.sequence],
                                          output_col_name: e.target.value,
                                        },
                                      };
                              
                                      const updatedValues = {
                                        ...formik.values,
                                        expression_list: {
                                          ...formik.values.expression_list,
                                          [column?.sequence]: {
                                            ...formik.values.expression_list[column?.sequence],
                                            output_col_name: e.target.value,
                                          },
                                        },
                                      };
                              
                                      setColumns(newColumns);
                                      formik.setValues(updatedValues);
                                    }
                                  : undefined
                              }
                              
                              
                            />
                          </TableCell>
                        );
                      })
                    : null}
                  <TableCell className="text-center">
                    <div className="">
                      <Button size="small" variant="text" onClick={() => handleClear(column?.sequence)} sx={{ '& svg': { color: 'black' } }}>
                        <ClearIcon />
                      </Button>
                      <Button size="small" variant="text" onClick={() => handleEditClick(column)} sx={{ '& svg': { color: 'black' } }}>
                        <PencilIcon />
                      </Button>
                      <Button size="small" variant="text" onClick={() => handleDelete(column?.sequence)}>
                        <TrashIcon color="error" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
            {formik.touched.expression_list && formik.errors.expression_list ? (
              <div className="text-red-600">
                {formik.errors.expression_list}
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </Paper>
  );
}
