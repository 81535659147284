import { Box, CircularProgress, Fab } from '@mui/material'
import React, { useState } from 'react'
import { Check, Save } from '@mui/icons-material'
import { green } from '@mui/material/colors';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  useLazyGetUserbynameQuery,
  useLazyGetProgrambynameQuery,
  useLazyGetProjectbynameQuery,
  useInsertlnkuserprojectsMutation,
  useUpdatelnkuserprojectsMutation
} from '../state/apiSlice'

const UsersActions = ({ params, rowId, setRowId }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [getUserbyname] = useLazyGetUserbynameQuery();
  const [getProgrambyname] = useLazyGetProgrambynameQuery();
  const [getProjectbyname] = useLazyGetProjectbynameQuery();
  const [updatelnkuserprojects] = useUpdatelnkuserprojectsMutation();
  const [insertlnkuserprojects] = useInsertlnkuserprojectsMutation();
  const handleSubmit = async () => {
    setLoading(true);
    try {
      let response;
      let { user_id, program_id, project_id, read_role, write_role, execute_role, is_active, created_by, id } = params.row;
      const userResponse = await getUserbyname(user_id);
      const userData = userResponse.data;
      user_id = userData.id;

      // Get the program ID
      const programResponse = await getProgrambyname(program_id);
      const programData = programResponse.data;
      program_id = programData.id;

      // Get the project ID
      const projectResponse = await getProjectbyname(project_id);
      const projectData = projectResponse?.data;
      project_id = projectData?.id || null;

      if (id) {
        response = await updatelnkuserprojects({ id, user_id, program_id, project_id, read_role, write_role, execute_role, is_active, created_by });
      } else {
        response = await insertlnkuserprojects({ user_id, program_id, project_id, read_role, write_role, execute_role, is_active, created_by });
      }

      if (response.data) {
        setSuccess(true);
        setRowId(null);
      } else {
        console.error("Request failed:", response.error);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        m: 1,
        position: 'relative',
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 30,
            height: 30,
            minHeight: 30,
          }}
        >
          <DoneAllIcon
            sx={{
              color: green[500],
              fontSize: "17px", // Adjust the icon size
              "&:hover": { color: green[700] },
            }}
          />
        </Fab>
      ) : (
        <Fab
          sx={{
            width: 30,
            height: 30,
            minHeight: 30,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save sx={{
            fontSize: "17px", // Adjust the icon size
          }} />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={20}
          sx={{
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the progress
            zIndex: 1,
          }}
        />
      )}
    </Box>
  )
};

export default UsersActions
