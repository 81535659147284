import React, { useState,useEffect, useRef, useCallback ,useMemo} from 'react';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { AiTwotoneNotification } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { IoWarningOutline } from 'react-icons/io5';
import dagre from "dagre";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import { useGetTransformationFormQuery } from 'state/apiSlice'
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType,

  
  ReactFlowProvider
} from 'reactflow';
import Expression from "./Expression";
import { useGetExpressionDescriptionsQuery } from 'state/apiSlice';
import 'reactflow/dist/style.css';
import {  Button} from '@mui/material';
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Filter from './Filter'
import CustomNodes from '../components/CustomNodes';
import DefaultEdge from '../components/DefaultEdge';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';

import * as yup from "yup";


import '../components/css/index.css';
import '../components/css/edgebutton.css';

import TransformationForm from './TransformationForm';
import { DeleteNodeProvider } from '../components/DeleteNodeContext';


const handleDeleteNode = (id, setNodes, setEdges) => {
  setNodes((prevNodes) => {
    const newNodes = prevNodes.filter(node => node.id !== id);
    return newNodes;
  });
  setEdges((prevEdges) => {
    const newEdges = prevEdges.filter(edge => edge.source !== id && edge.target !== id);
    return newEdges;
  });
};

let id = 0;
const getId = () => `dndnode_${id++}`;
const edgeTypes = {
  defaultEdge: DefaultEdge,
};

const nodeTypes = {
  customNode: CustomNodes
};
const findSectionField = (formObject, section, field = null) => {
  const sectionRecord = formObject?.find((rec) => rec.section === section);
  if (field) {
    const fieldRecord = sectionRecord.fields_list.find(
      (rec) => rec.field_id === field
    );
    return fieldRecord;
  }
  return sectionRecord;
};

const styles = {
  statusBar: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    backgroundColor: 'white',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    zIndex: 1000,
    width: '300px',
  },
  statusBarTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  notificationIcon: {
    marginRight: '5px',
    fontSize: '1.5em',
  },
  issueList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  issueContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  issueIcon: {
    marginRight: '5px',
    fontSize: '1.5em',
    flexShrink: 0,
  },
  issueText: {
    flex: 1,
    wordWrap: 'break-word',
  },
  criticalIssue: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    borderLeft: '5px solid #f5c6cb',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
  warningIssue: {
    backgroundColor: '#fff3cd',
    color: '#856404',
    borderLeft: '5px solid #ffeeba',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
  successMessage: {
    backgroundColor: '#d4edda',
    color: '#155724',
    borderLeft: '5px solid #c3e6cb',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
  errorMessage: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    borderLeft: '5px solid #f5c6cb',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
};
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 30,   // Reduced space between nodes
    edgesep: 30,   // Reduced minimum edge length
    ranksep: 60,   // Reduced separation between levels
    marginx: 10,   // Reduced margin for better spacing
    marginy: 10,   // Reduced margin for better spacing
  });

  // Setting nodes with zero positions initially
  const updatedNodes = nodes.map((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    return {
      ...node,
      position: { x: 0, y: 0 },  // Initially set to 0, 0
    };
  });

  // Setting edges
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Perform the layout calculation
  dagre.layout(dagreGraph);

  let minX = Infinity, minY = Infinity;

  // Map the calculated positions back to the nodes
  const layoutedNodes = updatedNodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // Update minX and minY to find the top-left corner of all nodes
    minX = Math.min(minX, nodeWithPosition.x - nodeWidth / 2);
    minY = Math.min(minY, nodeWithPosition.y - nodeHeight / 2);

    return {
      ...node,
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  // Apply offset to bring all nodes into view
  const offsetX = Math.abs(minX) + 20; // Add some margin
  const offsetY = Math.abs(minY) + 20;

  const adjustedNodes = layoutedNodes.map((node) => ({
    ...node,
    position: {
      x: node.position.x + offsetX,
      y: node.position.y + offsetY,
    },
  }));

  return { nodes: adjustedNodes, edges };
};
const Transformation = (props) => {
  let { transformationFlow: initialTransformationFlow } = props;


  // Initialize state with the prop value
  let [transformationFlow, setTransformationFlow] = useState([initialTransformationFlow[0]]);

  const [temporaryMessage, setTemporaryMessage] = useState(null);  
  const [initialSaveEnabled, setInitialSaveEnabled] = useState(false);
  const [skipFirstToggle, setSkipFirstToggle] = useState(true); 
   const [wasConnectedOnce, setWasConnectedOnce] = useState(false);
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(false);
  const [isButtonActive, setButtonActive] = useState(false);

 
  const { data: operatorsData1, isLoading: isRecordLoading } = useGetExpressionDescriptionsQuery();
  const currentUser = useSelector(selectCurrentUser);
let user=currentUser?.login_id
  const { taskFormFields, values: editValue, addOrEditRecord } = props;
  const [issues, setIssues] = useState([]);
  const [dismissedIssues, setDismissedIssues] = useState([]);


  const { data: transformationData, isLoading: isFormLoading, error: formError } = useGetTransformationFormQuery(user);
const mainRecord = findSectionField(transformationData, "main");
let inputRecord = findSectionField(transformationData, "Input");
const outputRecord = findSectionField(transformationData, "Output");
let joinerRecord = findSectionField(transformationData, "Joiner");
const filterRecord = findSectionField(transformationData, "Filter");
const expressionRecord = findSectionField(transformationData, "Expression");
const originalInitialvalues = expressionRecord?.initialvalues || {};
const extractInitialValue = (editValue) => {
  const detailsArray = editValue?.details?.details?.details || [];
  const mainEntry = detailsArray.find(item => item?.Main);
  return mainEntry ? mainEntry.Main[0].task?.job_execution : 'pandas';
};
const [selectedOption, setSelectedOption] = useState(extractInitialValue(editValue)||'pandas');
 
const initialvaluesStructure = Object.entries(originalInitialvalues).reduce((acc, [key, value]) => {
    if (key !== "transformation_name" && key !== "input_df" && key !== "output_df") {
        acc.expression_list[key] = value;
    } else {
        acc[key] = value;
    }
    return acc;
}, {
    transformation_name: "",
    input_df: "",
    output_df: "",
    expression_list: {},
    columns:[]
});

// Create a new object representing the modified expressionRecord
const modifiedExpressionRecord = {
    ...expressionRecord, // Copy other properties from the original expressionRecord
    initialvalues: initialvaluesStructure // Assign the new initialvalues structure
};
const sqlRecord = findSectionField(transformationData, "SQL");
const pythonRecord = findSectionField(transformationData, "Python");
const mainFormData = mainRecord?.fields_list;

const inputFormData = inputRecord?.fields_list;
const outputFormData = outputRecord?.fields_list;
const joinerFormData = joinerRecord?.fields_list;
const sqlFormData = sqlRecord?.fields_list;
const pythonFormData = pythonRecord?.fields_list;

const filterFormData = filterRecord?.fields_list;
const expressionFormData = expressionRecord?.fields_list;

const sections = transformationData
  ? transformationData
      .map(item => item.section)
      .filter(section => section !== 'main')
  : [];




  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [choice, setChoice] = useState(null);
  const [joinerSelection, setJoinerSelection] = useState({ joinerId: null, inputs: [] });
  const [outputSelection, setOutputSelection] = useState({ outputId: null, inputs: [] });
  const [sqlSelection, setSQLSelection] = useState({ sqlId: null, inputs: [] });
  const [pythonSelection, setPythonSelection] = useState({ pythonId: null, inputs: [] });


  
  const [filterSelection, setFilterSelection] = useState({ filterId: null, inputs: [] });
  const [expressionDataSelection, setExpressionDataSelection] = useState({ expressionId: null, inputs: [] });


  const [dbType, setDbType] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState();
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [nodeName, setNodeName] = useState('');
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [renderChoice, setrenderChoice] = useState(false);

  const { nodes: initialNodes, edges: initialEdges } = transformationFlow[0];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges || []);

 //Function to check label whether node is Filter or expression or any other node and call find label function
 const checkLable = (sourceNode) => {
  try {
    let edgeLabel;
    if (sourceNode.data.label === "Filter") {
      edgeLabel = findLabel(sourceNode.data.Filter);
      return edgeLabel;
    } else if (sourceNode.data.label === "Expression") {
      edgeLabel = findLabel(sourceNode.data.Expression);
      return edgeLabel;
    } else {
      edgeLabel = sourceNode.data.output_df;
      return edgeLabel;
    }
  } catch (error) {
    console.log("Error in fetching label", error);
  }
};
//Function to read the label if Node is filter or expression or node

const findLabel = (obj) => {
  try {
    for (const key in obj) {
      if (
        obj[key] &&
        typeof obj[key] === "object" &&
        obj[key].hasOwnProperty("output_df")
      ) {
        return obj[key].output_df;
      }
    }
    return null; // or throw an error if you prefer
  } catch (error) {
    console.log("Error in finding label", error);
  }
};

// Function to connect edges

const onConnect = useCallback(
  (params) => {
    const sourceNode = nodes.find((node) => node.id === params.source);
    let edgeLabel = checkLable(sourceNode);
    const newEdge = {
      ...params,
      type: "defaultEdge",
      data: {
        // sourceNodeData: sourceNode.data,
        label: edgeLabel,
      },
    };

    setEdges((eds) => addEdge(newEdge, eds));
  },
  [nodes]
);

//Use effect will get triggered on every change in data for edge label to udpate it in default edges

useEffect(() => {
  setEdges((eds) =>
    eds.map((edge) => {
      const sourceNode = nodes.find((node) => node.id === edge.source);
      let edgeLabel = checkLable(sourceNode);
      if (sourceNode) {
        return {
          ...edge,
          data: {
            ...edge.data,
            // sourceNodeData: sourceNode.data,
            label: edgeLabel,
          },
        };
      }
      return edge;
    })
  );
}, [nodes]);
  const defaultEdgeOptions = {
    // style: { stokeWidth: 2, stoke: "black" },
    type: "defaultEdge",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "black",
    },
  };
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  const findConnectedNodes = (nodeId, handleId = null) => {
    return edges
      .filter(edge => 
        (handleId ? edge.targetHandle === handleId : edge.target === nodeId)
      )
      .map(edge => getNode(edge.source));
  };
  
  const getNode = (nodeId) => {
    return nodes.find(node => node.id === nodeId);
  };
  // const findConnectedNodes = (nodeId) => {
  //   const connectedEdges = edges.filter(edge => edge.target === nodeId);
  //   return connectedEdges.map(edge => nodes.find(node => node.id === edge.source));
  // };

  const handleNameChange = (event) => {
    setNodeName(event.target.value);
  };

  const handleUpdate = () => {
    setNodes((prevElements) =>
      prevElements.map((el) => {
        if (el.id === selectedNodeId) {
          return {
            ...el,
            data: {
              ...el.data,
              displayName: nodeName,
            },
          };
        }
        return el;
      })
    );
  };



  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    setOpenUpdate(true);

  };
  let initValues = {};
  const initialDataByType = (label, savedData = {}) => {

    const commonData = {
      displayName: `${label}`,
      // savedData: savedData[label] || {}, 
    };
  
    switch (label) {
      case 'Main':
        return {
          label,
          // input_values: formValues[label] || {}, 
          ...commonData,
        };
      case 'Input':
        return {
          label,
          // input_values: formValues[label] || {}, 
          ...commonData,
        };
      case 'Joiner':
        return {
          label,
          
          // input_values: formValues[label] || {},
          select_columns:'',
          ...commonData,
        };

      case 'SQL':
          return {
            label,
            // input_values: formValues[label] || {},
            ...commonData,
          };
          case 'Python':
            return {
              label,
              // input_values: formValues[label] || {},
              ...commonData,
            };
      case 'Filter':
        return {
          label,
          select_columns:'',

          // input_values: formValues[label] || {}, 
          ...commonData,
        };

        case 'Expression':
        return {
          label,

          // input_values: formValues[label] || {},
          expression_data:{},
          ...commonData,
        };
      case 'Output':
        return {
          label,
          // input_values: formValues[label] || {},
          ...commonData,
        };

      default:
        return {
          label,
          ...commonData,
        };
    }
  };

  

  const deleteNodeCallback = useCallback(
    (id) => handleDeleteNode(id, setNodes, setEdges),
    [setNodes, setEdges]
  );
  const hasMultipleIndependentGraphs = (nodes, edges) => {
    // Create an adjacency list to represent the graph
    const adjacencyList = new Map();
  
    // Initialize the adjacency list with each node
    nodes.forEach((node) => adjacencyList.set(node.id, []));
  
    // Populate the adjacency list with edges
    edges.forEach(({ source, target }) => {
      adjacencyList.get(source).push(target);
      adjacencyList.get(target).push(source); // Assuming it's an undirected graph
    });
  
    // Helper function for DFS
    const dfs = (node, visited) => {
      visited.add(node);
      adjacencyList.get(node).forEach((neighbor) => {
        if (!visited.has(neighbor)) {
          dfs(neighbor, visited);
        }
      });
    };
  
    // Find connected components using DFS
    const visited = new Set();
    let componentCount = 0;
  
    for (let node of nodes) {
      if (!visited.has(node.id)) {
        // New component found, start a DFS from this node
        dfs(node.id, visited);
        componentCount++;
  
        // If more than one component is found, return true
        if (componentCount > 1) {
          return true;
        }
      }
    }
  
    // If only one component is found, return false
    return false;
  };
  const checkAllConnectionsAndCycles = (nodes, edges) => {
    const allConnected = nodes.every(node => {
      const incoming = edges.filter(edge => edge.target === node.id).length;
      const outgoing = edges.filter(edge => edge.source === node.id).length;
  
      switch (node.data.label) {
        case 'Input':
          return outgoing >= 1;
        case 'Joiner':
          return incoming >= 2 && outgoing >= 1;
        case 'Expression':
        case 'Filter':
          return incoming >= 1 && outgoing >= 1;
          case 'SQL':
          return incoming <=4 && outgoing >= 1;
          case 'Python':
            return incoming <=4 && outgoing >= 1;
        case 'Output':
          return incoming >= 1;
        default:
          return false;
      }
    });
  
    if (!allConnected) {
      return { allConnected, hasCycle: false, hasMultipleGraphs: false };
    }
  
    // Function to check for cycles using Depth First Search (DFS)
    const hasCycle = (node, edges, visited = new Set(), recStack = new Set()) => {
      if (recStack.has(node)) {
        // Cycle detected
        return true;
      }
  
      if (visited.has(node)) {
        return false;
      }
  
      visited.add(node);
      recStack.add(node);
  
      const nextNodes = edges.filter(edge => edge.source === node).map(edge => edge.target);
  
      for (const nextNode of nextNodes) {
        if (hasCycle(nextNode, edges, visited, recStack)) {
          return true;
        }
      }
  
      recStack.delete(node);
      return false;
    };
  
    // Initialize visited and recursion stack sets
    const visited = new Set();
    const recStack = new Set();
  
    // Check for cycles starting from each node
    for (const node of nodes) {
      if (hasCycle(node.id, edges, visited, recStack)) {
        return { allConnected, hasCycle: true, hasMultipleGraphs: false };
      }
    }
  
    // Check for multiple independent graphs
    const hasMultipleGraphs = hasMultipleIndependentGraphs(nodes, edges);
  
    return { allConnected, hasCycle: false, hasMultipleGraphs };
  };
  useEffect(() => {
    const { allConnected, hasCycle, hasMultipleGraphs } = checkAllConnectionsAndCycles(nodes, edges);
  
    const newIssues = [];
    let sqlNodeWarningShown = false;
  
    if (hasCycle) {
      newIssues.push({ type: 'critical', message: "Cycle detected in the dataflow!" });
      // toast.error("Cycle detected in the graph!");
    }
    if (hasMultipleGraphs) {
      newIssues.push({ type: 'critical', message: "Multiple independent dataflow's detected!" });
      // toast.error("Multiple independent graphs detected!");
    }
  
    const sortNodes = nodes.filter(node => node.data.label === 'SQL');
    sortNodes.forEach(sortNode => {
      const incomingEdges = edges.filter(edge => edge.target === sortNode.id);
      if (incomingEdges.length > 4) {
        const issueMessage = "Only four connections are allowed for SQL Node. Please fix the dataflow";
        if (!newIssues.find(issue => issue.message === issueMessage)) {
          newIssues.push({ type: 'warning', message: issueMessage });
          sqlNodeWarningShown = true;
        }
      }
    });
    const pythonNodes = nodes.filter(node => node.data.label === 'Python');
    pythonNodes.forEach(sortNode => {
      const incomingEdges = edges.filter(edge => edge.target === sortNode.id);
      if (incomingEdges.length > 4) {
        const issueMessage = "Only four connections are allowed for Python  Node. Please fix the dataflow";
        if (!newIssues.find(issue => issue.message === issueMessage)) {
          newIssues.push({ type: 'warning', message: issueMessage });
          sqlNodeWarningShown = true;
        }
      }
    });
  
    setIssues(newIssues);
    setButtonActive(allConnected && newIssues.filter(issue => issue.type === 'critical').length === 0 && !sqlNodeWarningShown);
  
    const visibleIssues = newIssues;
    const shouldShowSaveMessages = visibleIssues.length === 0;
  
    setIsStatusBarVisible(visibleIssues.length > 0 || !!temporaryMessage);
  
    if (skipFirstToggle && allConnected) {
      setWasConnectedOnce(true);
      setSkipFirstToggle(false);
      return;
    }
  
    if (wasConnectedOnce && shouldShowSaveMessages) {
      if (!allConnected && isButtonActive) {
        setTemporaryMessage({ type: 'error', message: 'Save is disabled. Please complete the dataflow' });
        setIsStatusBarVisible(true);
      } else if (allConnected && !isButtonActive) {
        setTemporaryMessage({ type: 'success', message: 'Save is enabled. You can now save your dataflow' });
        setIsStatusBarVisible(true);
      }
    }
  
    const timer = setTimeout(() => {
      setTemporaryMessage(null);
      setIsStatusBarVisible(visibleIssues.length > 0);
    }, 4500);
  
    return () => clearTimeout(timer);
  
  }, [nodes, edges, isButtonActive, wasConnectedOnce, skipFirstToggle, temporaryMessage]);
  const dismissIssue = (issueMessage) => {
    setDismissedIssues([...dismissedIssues, issueMessage]);
    const updatedIssues = issues.filter(issue => issue.message !== issueMessage);
    setIssues(updatedIssues);
  
    const visibleIssues = updatedIssues.filter(issue => !dismissedIssues.includes(issue.message));
    setIsStatusBarVisible(visibleIssues.length > 0);
  };
 const hasCriticalIssues = issues.some(issue => issue.type === 'critical');
  
  function validateFormSchema(formData) {
    let validSchemaObject = {};
    formData.forEach(({ field_id, display_label, required }) => {
      if (required === "Y") {
        validSchemaObject[field_id] = yup
          .string()
          .required(`${field_id} is required`);
      }
      if (field_id === "task_name") {
        validSchemaObject[field_id] = validSchemaObject[field_id].matches(
          /^[a-zA-Z0-9_]+$/,
          `${field_id} must be alphanumeric and may contain underscores only`
        );
      }
    });
    return yup.object().shape(validSchemaObject);
  }
  
  function validateSchemaObject(section) {
    let validationObject = {};
  
    switch (section) {
      case "main":
        if (mainFormData != null) {
          validationObject[section] = validateFormSchema(mainFormData);
        }
        break;
  
      case "input":
        if (inputFormData != null) {
          validationObject[section] = validateFormSchema(
            inputFormData?.field_list
          );
        }
        break;
      case "joiner":
        if (joinerFormData != null) {
          validationObject[section] = validateFormSchema(
            joinerFormData?.field_list
          );
        }
        break;
      
        case "output":
          if (outputFormData != null) {
            validationObject[section] = validateFormSchema(
              outputFormData?.field_list
            );
          }
          break;
      default:
        break;
    }
  
    return validationObject;
  }
  

  let transformedFilter = [];
  let transformedExpression = [];
  if (editValue === null || editValue === undefined) {
    initValues = {
      ...mainFormData?.initialvalues,
      ...inputFormData?.initialvalues,
      ...outputFormData?.initialvalues,
      ...joinerFormData?.initialvalues,
      ...sqlFormData?.initialvalues,
      ...pythonFormData?.initialvalues,
    };
  } else {
    const { details, ...taskRecord } = editValue || {};

    const detailsArray = details?.details?.details || [];

    const inputDetails = detailsArray.find(item => item?.Input);
    const mainDetails=detailsArray.find(item => item?.Main)
    const joinerDetails = detailsArray.find(item => item?.Joiner);
    const sqlDetails = detailsArray.find(item => item?.SQL);
    const pythonDetails = detailsArray.find(item => item?.Python);
    const outputDetails = detailsArray.find(item => item?.Output);
    const filterDetails = detailsArray.find(item => item?.Filter);
    const expressionDetails = detailsArray.find(item => item?.Expression);
  
    const input = inputDetails ? inputDetails?.Input : null;
    const main = mainDetails ? mainDetails?.Main : null;
   
    const joiner = joinerDetails ? joinerDetails?.Joiner : null;
    const sql = sqlDetails ? sqlDetails?.SQL : null;
    const python = pythonDetails ? pythonDetails?.Python : null;

    const output = outputDetails ? outputDetails?.Output : null;
    const transformNestedDetails = (type, detailsArray) => {
      const items = detailsArray.find(detail => Object.keys(detail)[0] === type)?.[type] || [];
      if (!Array.isArray(items)) return null;
  
      const transformations = items.filter(item => {
          const key = Object.keys(item)[0];
          return item[key].transformation_name;
      });
  
      if (transformations.length === 0) {
          console.log("No transformations found");
          return null;
      }
  
      let result = [];
  
      transformations.forEach(transformationEntry => {
          const transformationName = Object.keys(transformationEntry)[0];
          const transformationData = transformationEntry[transformationName];
  
          if (!transformationData || !transformationData.transformation_name) {
              console.log("Transformation name not found for", transformationName);
              return;
          }
  
          const transformationNamee = transformationData.transformation_name;
          let entry = {
              [transformationNamee]: { ...transformationData }
          };
  
          items.forEach(item => {
              const key = Object.keys(item)[0];
              if (key.startsWith(transformationName) && key !== transformationName) {
                  entry[transformationNamee][key] = item[key];
              }
          });
  
          result.push(entry);
      });
  
      return result;
  };
  
  
    const filter = filterDetails ? transformNestedDetails("Filter", detailsArray) : null;
    const expression = expressionDetails ? transformNestedDetails("Expression", detailsArray) : null;

    
  
    let transformedJoiner = [];
    let transformedSQL = [];
    let transformedPython = [];

    let transformedInput = [];
    let transformedOutput = [];
    let transformedExpression = [];
  
    const prefixKeys = (item, prefix) => {
      const transformedItem = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          transformedItem[`${prefix}-${key.toLowerCase()}`] = item[key];
        }
      }
      return transformedItem;
    };
  
    if (joiner) {
      transformedJoiner = joiner.map((item) => prefixKeys(item, "joiner"));
    }
    if (sql) {
      transformedSQL = sql.map((item) => prefixKeys(item, "sql"));
    }
    if (python) {
      transformedPython = python.map((item) => prefixKeys(item, "python"));
    }
    if (input) {
      transformedInput = input.map((item) => prefixKeys(item, "input"));
    }
  
    if (filter) {
      transformedFilter = filter.map((item) => prefixKeys(item, "filter"));
    }
  
    if (expression) {
      transformedExpression = expression.map((item) => prefixKeys(item, "expression"));
    }
  
    if (output) {
      transformedOutput = output.map((item) => prefixKeys(item, "output"));
    }
  
    if (!joiner && !input && !output) {
      console.log(null);
    }

    const detailRecord = Object.assign({}, ...transformedInput, ...transformedJoiner,...transformedSQL,...transformedPython, ...transformedOutput, ...transformedFilter, ...transformedExpression);
    initValues = { ...taskRecord, ...detailRecord };
  }
  const memoizedInitValues = useMemo(() => initValues, [initValues]);


  const [datavalues, setDataValues] = useState("true");

  const updateTransformationFlow = useCallback((initValues) => {
    if (!initValues) return;
  
    setNodes((prevNodes) =>
      prevNodes.map((node) => {

        if(node.data.label == "Filter"|| node.data.label=="Expression"){
          const updatedData = initValues[node.id];
          const tranformationName = updatedData?.["transformation_name"];
          let appendTransformationName = {
            [tranformationName]:updatedData
          }
          if(node.data.label=="Filter"){
          let filterUpdatedData ={
            "Filter":appendTransformationName
          }
          if (updatedData) {
            return {
              ...node,
              data: {
                ...node.data,
                ...filterUpdatedData,
              },
            };
          }
          
        }
        else if(node.data.label=="Expression"){
          let expressionUpdatedData={
            "Expression":appendTransformationName
          }
          if (updatedData) {
            return {
              ...node,
              data: {
                ...node.data,
                ...expressionUpdatedData,
              },
            };
          }
        }
        return node;
        }

        const updatedData = initValues[node.id];
        if (updatedData) {
          return {
            ...node,
            data: {
              ...node.data,
              ...updatedData,
            },
          };
        }
        return node;
      })
    );
  
  }, []);
  
  useEffect(() => {
    if (datavalues === "true") {
      updateTransformationFlow(initValues);
      setDataValues("false"); 
    }
  }, [datavalues, initValues, updateTransformationFlow]);

  
const [formValues, setFormValues] = useState(initValues);
    const validationSchema = validateSchemaObject(sections);
    const formik = useFormik({
      initialValues: initValues,
      validationSchema: validationSchema[sections],

      onSubmit: (values, { resetForm }) => {
        const taskNameRegex = /^[a-zA-Z0-9_]+$/;
        if (!taskNameRegex.test(values?.task_name)) {
          return;
        }
        handleFormSubmit(values, resetForm);
      },
    });



    const handleNodeFormSubmit = (nodeId, formData, resetForm) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === nodeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  ...formData,
                },
              }
            : node
        )
      );
    
      // Set the form data for the connected node
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [nodeId]: formData,
      }));
    
      resetForm();
      setIsFormVisible(false);
    };
    
    
    const handleFormSubmit = async (values, resetForm) => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [selectedNodeId]: values,
      }));
      resetForm();
    };
    
    
    const formMain = (formikProps) => {
      return (
        <TransformationForm
          taskProps={mainRecord}
          formProps={formikProps}
          object={"Task"}
          onSave={handleSaveForm} 
          initialValues={initValues}
        />
      );
    };
    const [newOptions,setOptions]=useState()
   

    const handleOutDfValues = (outDfValues) => {
      setOptions(outDfValues)
    };
    const [actualColumns,setActualColums]=useState()


const handleActualColumnsValue=(onactualColumnsValue)=>{
  setActualColums(onactualColumnsValue)

}
    // const updatedJoinerRecord = {
    //   ...joinerRecord,
    //   fields_list: joinerRecord?.fields_list?.map(field => {
    //     if (field?.field_id === 'left_input_df' || field?.field_id === 'right_input_df') {
    //       let filteredOptions;
    //       if (field?.field_id === 'right_input_df' && newOptions?.length === 2) {
    //         filteredOptions = newOptions?.filter(option => option !== '2');
    //       } else {
    //         filteredOptions = newOptions;
    //       }
    //       return {
    //         ...field,
    //         option_list: filteredOptions?.map(value => ({ id: value, name: value }))
    //       };
    //     }
        
    //     return field;
    //   })
    // };
    
    
      
    const formJoiner = (formikProps, output_df) => {
      return (
        <TransformationForm
          taskProps={joinerRecord}
          connectedInputs={joinerSelection.inputs} 
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, { ...formData, output_df })}
          initialValues={formValues[selectedNodeId]}
          formProps={formikProps}
          object={"Joiner"}
          onSave={(formData) => handleSaveForm(formData)}
          onOutDfValues={handleOutDfValues} 

        />
      );
    };
    
    // joinerRecord = updatedJoinerRecord; 
    const formFilter = (formikProps) => {
      return (
        <Filter
        onSave={(formData) => handleSaveForm(formData)}
        connectedInputs={filterSelection.inputs} 

        initialValues={formValues[selectedNodeId]}

        actualColumns={actualColumns}
        filterRecord={filterRecord}
        filterDetails={transformedFilter}
        />
      );
    };

    const formSQL = (formikProps) => {
      return (
        <TransformationForm
        taskProps={sqlRecord}
        connectedInputs={sqlSelection.inputs} 

          formProps={formikProps}
          object={"SQL"}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={formValues[selectedNodeId]}
          onSave={(formData) => handleSaveForm(formData)}
        

        />
      );
    };   const formPython = (formikProps) => {
      return (
        <TransformationForm
        taskProps={pythonRecord}
        connectedInputs={pythonSelection.inputs} 

          formProps={formikProps}
          object={"Python"}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={formValues[selectedNodeId]}
          onSave={(formData) => handleSaveForm(formData)}
        

        />
      );
    };

    const formExpression = (formikProps) => {

      return (
        <Expression
            connectedInputs={expressionDataSelection?.inputs}
            onFormValuesSubmit={handleSaveForm}
            initialData={formValues[selectedNodeId]}
            formData={modifiedExpressionRecord}
            // initialData={selectedNode?.data}
            operatorsData={operatorsData1}
            expressionDetails={transformedExpression}
            

          />
      );
    };
    const formInput = (formikProps) => {
      return (
        <TransformationForm
        taskProps={inputRecord}

          formProps={formikProps}
          object={"Input"}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={formValues[selectedNodeId]}
          onSave={(formData) => handleSaveForm(formData)}
          onactualColumnsValue={handleActualColumnsValue} 
          onOutDfValues={handleOutDfValues} 

        />
      );
    };
    const formOutput = (formikProps) => {
      return (
        <TransformationForm
          taskProps={outputRecord}
          connectedInputs={outputSelection.inputs} 

          formProps={formikProps}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={{ ...formValues[selectedNodeId], ...initValues }} 
          object={"Output"}
          onSave={handleSaveForm}
          
        />
      );
    };
    
  

  const calculateVerticalLayout = useCallback(() => {
    const verticalSpacing = 150; 
    let posY = 0;
    return nodes.map((node, index) => ({
      ...node,
      position: { x: node.position.x, y: posY + index * verticalSpacing },
    }));
  }, [nodes]);
  
  // Use onLayout to set the positions of nodes based on the vertical layout
  const onLayoutCalled = useRef(false);

  const onLayout = useCallback(() => {
    if (!onLayoutCalled.current) {
      const layouted = getLayoutedElements(nodes, edges);
  
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);
  
      onLayoutCalled.current = true;
    }
  }, [nodes, edges]);
  
  onLayout(); 
  
 

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const passedArgs = event.dataTransfer.getData('application/reactflow');
      const { nodeType, nodeData } = JSON.parse(passedArgs);

      if (typeof nodeType === 'undefined' || typeof nodeData === 'undefined') {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type: nodeType,
        position,
        data:initialDataByType(nodeData)
        
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance],
  );
let [formComponent,setformComponent]=useState()
let [nodeLable,setNodeLable]=useState();

const handleResetForm = (nodeFormValues,label)=>{
  try{
  const fetchRecord = findSectionField(transformationData,label);
  const initialValues = fetchRecord?.initialvalues;
  if (Object.keys(nodeFormValues).length === 0){
    formik.setValues(initialValues)
  }else{
    formik.setValues(nodeFormValues);
  }
}catch(error){
  console.log("Error while handling form reset",error)
}
}

const onNodeClick = useCallback((event, node,) => {


  setSelectedNodeId(node.id);
  setNodeName(node.data.displayName);
  const nodeFormValues = formValues[node.id] || {};
  // formik.setValues(nodeFormValues);
  handleResetForm(nodeFormValues,node.data.label);
  setrenderChoice(true);
  setOpen(true);
  setNodeLable(node.data.label);

  if (node.data.label === "Joiner") {

    // Assuming 'joiner' is the type set for joiner nodes
    const handle1Id = `handle_${node.id}`;
    const handle2Id = `handle_${node.id}1`;
    

    const inputNodes1 = findConnectedNodes(node.id, handle1Id);
    console.log("onNodeClick ~ inputNodes1:", inputNodes1)
    const inputNodes2 = findConnectedNodes(node.id, handle2Id);
    console.log("🚀 ~ onNodeClick ~ inputNodes2:", inputNodes2)
    

    // Add a property to distinguish the data sources
    const inputData1 = inputNodes1.map(inputNode => ({
      source: 'inputNodes1', // added property
      ...inputNode.data
    }));
    const inputData2 = inputNodes2.map(inputNode => ({
      source: 'inputNodes2', // added property
      ...inputNode.data
    }));

    const inputData = [...inputData1, ...inputData2];

    console.log("🚀 ~ onNodeClick ~ inputData:", inputData)

    setJoinerSelection({ joinerId: node.id, inputs: inputData });
  }


  else if(node.data.label==="Filter"){
    
    const inputNodes = findConnectedNodes(node.id);
      const inputData = inputNodes.map(inputNode => {
        const { displayName, ...restOfData } = inputNode.data; // Destructure to exclude unwanted fields
        return restOfData; // Return the rest of the data
    });
      
      setFilterSelection({ filterId: node.id, inputs: inputData });
  }
  else if(node.data.label==="Output"){
    
    const inputNodes = findConnectedNodes(node.id);
      const inputData = inputNodes.map(inputNode => {
        const { displayName, ...restOfData } = inputNode.data; // Destructure to exclude unwanted fields
        return restOfData; // Return the rest of the data
    });
      
      setOutputSelection({ outputId: node.id, inputs: inputData });
  }
  else if(node.data.label==="SQL"){
    
    const inputNodes = findConnectedNodes(node.id);
      const inputData = inputNodes.map(inputNode => {
        const { displayName, ...restOfData } = inputNode.data; 
        return restOfData; 
    });
      
      setSQLSelection({ sqlId: node.id, inputs: inputData });
  }
  else if(node.data.label==="Python"){
    
    const inputNodes = findConnectedNodes(node.id);
      const inputData = inputNodes.map(inputNode => {
        const { displayName, ...restOfData } = inputNode.data; 
        return restOfData; 
    });
      
      setPythonSelection({ pythonId: node.id, inputs: inputData });
  }
  else if (node.data.label === "Expression") {
    const inputNodes = findConnectedNodes(node.id);
    setSelectedNode(node)
    const inputData = inputNodes.map(inputNode => {
      const { displayName, ...restOfData } = inputNode.data; // Destructure to exclude unwanted fields
      return restOfData; // Return the rest of the data
  });
    setExpressionDataSelection({ expressionId: node.id, inputs: inputData });
    setChoice("");
  }
}, [nodes, edges, formik, formValues]);



useEffect(() => {
  let component = null;
  
  
    if (nodeLable === "main") {
      component = formMain(formik);
    } else if (nodeLable === "Joiner" ) {
      component = formJoiner(formik);
    }
    else if (nodeLable=== "Filter") {
      component = formFilter(formik);
    } else if (nodeLable=== "Expression") {
      component = formExpression(formik);
    } else if (nodeLable === "Input") {
      component = formInput(formik);
    } else if (nodeLable === "Output") {
      component = formOutput(formik);
    }
    else if (nodeLable === "SQL") {
      component = formSQL(formik);
    }
    else if (nodeLable === "Python") {
      component = formPython(formik);
    }
  

  setformComponent(component);
}, [nodeLable, selectedValue, formik]); 


const updateDisplayName = (id, newName) => {
  setNodes(prevNode =>
    prevNode.map(node =>
      node.id === id ? { ...node, data: { ...node.data, displayName: newName } } : node
    )
  );
};


const handleSaveForm = (formData, uiState) => {
  setNodes((currentNodes) => {
    const updatedNodes = currentNodes.map((node) => {
      if (node.id === selectedNodeId) {
        updateDisplayName(selectedNodeId,formData?.transformation_name ??
  Object.values(formData || {}).flatMap(obj => Object.values(obj || {})).find(o => o?.transformation_name)?.transformation_name);
        if(node?.data?.label==="Joiner"){
          return {
            ...node,
            data: {
              ...node.data,
              ...formData,
            }
        }        
      }
        console.log('Updating node:', node);
        return {
          ...node,
          data: {
            ...node.data,
            ...formData,
          },
        };
      }
      return node;
    });
 
    return updatedNodes;
  });
  setFormValues((prevFormValues) => ({
    ...prevFormValues,
    [selectedNodeId]: formData,
  }));
  setIsFormVisible(false);
 
};


const handleChange = (event) => {
  setSelectedOption(event.target.value);
};

const options = [
  { id: 'pandas', name: 'Pandas' },
  { id: 'polars', name: 'Polars' }
];
const handleSave = (resetForm) => {
  console.log(JSON.stringify(nodes, null, 2), "JSON Generated for nodes");

  let taskDetails = [];
  taskDetails.push({
    task_type: "Main", 
    parameter_type: "task", 
    key_01:"job_execution", 
    value_01:selectedOption||"", 
    sequence:  1,
    is_active: "Y",
  });
  nodes.forEach((node, index) => {
    const { id, data } = node;
    const { label, transformation_name } = data;
   
    if (data && label === "Filter") {
      const Filter = data.Filter;

      for (const filterKey in Filter) {

        const firstFilterKey1 = filterKey;
        const value = Filter[filterKey];

        if (typeof value === 'object' && value !== 'select_columns') {
          for (const innerKey in value) {
            const firstFilterKey = innerKey;
            const innerValue = value[innerKey];

            if (typeof innerValue === 'object' && innerValue !== 'select_columns') {
              for (const subInnerKey in innerValue) {
                const subInnerValue = innerValue[subInnerKey];

                if (subInnerKey !== 'label' && subInnerKey !== 'displayName' && subInnerKey !== 'select_columns' &&firstFilterKey !== 'select_columns') {
                  taskDetails.push({
                    task_type: label,
                    parameter_type: firstFilterKey,
                    key_01: subInnerKey,
                    value_01: subInnerValue,
                    sequence: index + 1,
                    is_active: "Y",
                  });
                }
              }
            } else {
              if (innerKey !== 'label' && innerKey !== 'displayName' && innerKey !== 'select_columns'&&firstFilterKey !== 'select_columns') {
                taskDetails.push({
                  task_type: label,
                  parameter_type: firstFilterKey1,
                  key_01: innerKey,
                  value_01: innerValue,
                  sequence: index + 1,
                  is_active: "Y",
                });
              }
            }
          }
        } else {
          if (filterKey !== 'label' && filterKey !== 'displayName',filterKey !== 'select_columns') {
            taskDetails.push({
              task_type: label,
              parameter_type: transformation_name || "id",
              key_01: filterKey,
              value_01: value,
              sequence: index + 1,
              is_active: "Y",
            });
          }
        }
      }
    } else if (data && label === "Expression") {
      const expression = data.Expression;

      for (const exprKey in expression) {
        const exprValue = expression[exprKey];

        if (typeof exprValue === 'object') {
          for (const innerKey in exprValue) {
            const innerValue = exprValue[innerKey];

            if (typeof innerValue === 'object') {
              for (const subInnerKey in innerValue) {
                const subInnerValue = innerValue[subInnerKey];

                if (typeof subInnerValue === 'object') {
                  for (const subSubInnerKey in subInnerValue) {
                    const subSubInnerValue = subInnerValue[subSubInnerKey];

                    if (subSubInnerKey !== 'label' && subSubInnerKey !== 'displayName' && subSubInnerKey !== 'select_columns') {
                      taskDetails.push({
                        task_type: label,
                        parameter_type: subInnerKey,
                        key_01: subSubInnerKey,
                        value_01: subSubInnerValue,
                        sequence: index + 1,
                        is_active: "Y",
                      });
                    }
                  }
                } else {
                  if (subInnerKey !== 'label' && subInnerKey !== 'displayName' && subInnerKey !== 'select_columns') {
                    taskDetails.push({
                      task_type: label,
                      parameter_type: innerKey,
                      key_01: subInnerKey,
                      value_01: subInnerValue,
                      sequence: index + 1,
                      is_active: "Y",
                    });
                  }
                }
              }
            } else {
              if (innerKey !== 'label' && innerKey !== 'displayName'  && innerKey !== 'select_columns' ) {
                taskDetails.push({
                  task_type: label,
                  parameter_type: exprKey,
                  key_01: innerKey,
                  value_01: innerValue,
                  sequence: index + 1,
                  is_active: "Y",
                });
              }
            }
          }
        } else {
          if (exprKey !== 'label' && exprKey !== 'displayName' && exprKey !== 'select_columns' ) {
            taskDetails.push({
              task_type: label,
              parameter_type: transformation_name || "id",
              key_01: exprKey,
              value_01: exprValue,
              sequence: index + 1,
              is_active: "Y",
            });
          }
        }
      }
    } 
    else if (data && label === "SQL") {
      for (const key in data) {
        if (key !== 'label' && key !== 'displayName') {
          const values = data[key]?.split(',').map(item => item.trim()) || [];
          values.forEach((value, index) => {
            taskDetails.push({
              task_type: label,
              parameter_type: transformation_name,
              key_01: key, 
              value_01: value,
              sequence: index + 1,
              is_active: "Y",
            });
          });
        }
      }
    }
    else if (data && label === "Python") {
      console.log(data,'data')
      for (const key in data) {
        if (key !== 'label' && key !== 'displayName') {
          let value = data[key];
          let trimmedValue = typeof value === 'string' ? value.trim() : value;
          let values = [trimmedValue];
    
          if (key === 'input_df') {
            values = data[key]?.split(',').map(item => item.trim()) || [];
          }
    
          values.forEach((value, index) => {
            taskDetails.push({
              task_type: label,
              parameter_type: transformation_name,
              key_01: key,
              value_01: value,
              sequence: index + 1,
              is_active: "Y",
            });
          });
        }
      }
    }
    else if (data && label === "Joiner") {
        for (const key in data) {
          const value = data[key];
          if (key !== 'label' && key !== 'displayName') {
            taskDetails.push({
              task_type: label,
              parameter_type: transformation_name,
              key_01: key,
              value_01: value,
              sequence: index + 1,
              is_active: "Y",
            });
          }
        }
      }
    
    
    else if (data) {
      for (const key in data) {
        const value = data[key];
        if (key !== 'label' && key !== 'displayName') {
          taskDetails.push({
            task_type: label,
            parameter_type: transformation_name,
            key_01: key,
            value_01: value,
            sequence: index + 1,
            is_active: "Y",
          });
        }
      }
    }
  });

  let taskRecord = {
    ...editValue,
    details: taskDetails,
  };
console.log(taskRecord,"ooo");
  try {
    addOrEditRecord(taskRecord, resetForm);
    if (taskRecord?.id === 0) {
      toast.success(`Task Created Successfully`, { position: "top-center" });
    } else {
      toast.success(`Task Updated Successfully`, { position: "top-center" });
    }
  } catch (error) {
    console.error("Form Submission Error: ", error);
  }
};

  const onDragStart = (event, nodeData, nodeType) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify({ nodeData, nodeType }));
    event.dataTransfer.effectAllowed = 'move';
};


  return (
    <>
        {/* <button onClick={handleUpdateClick}>Update Transformation Flow</button> */}

      <div className="dndflow">
        <aside className='sideBar'>
          {sections.map((section, index) => (
            <div key={index} className={`dndnode ${section.toLowerCase()}`} draggable onDragStart={(event) => onDragStart(event, section, 'customNode')}>
              {section}
            </div>
          ))}
  <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel id="select-helper-mui5-label" style={{ color: 'black' }}>Job Execution</InputLabel>

      <Select
        labelId="select-helper-mui5-label"
        id="restartability"
        value={selectedOption}
        onChange={handleChange}
        label="Job Execution"
        InputLabelProps={{
          style: { color: "black" },
        }}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
        </aside>

        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <DeleteNodeProvider deleteNodeCallback={deleteNodeCallback}>
            <ReactFlow
              onLayout={onLayout}
              nodes={nodes}
              edges={edges}
              fitView="onLoad" 
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodeClick={onNodeClick}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onDrop={onDrop}
              onDragOver={onDragOver}
              defaultEdgeOptions={defaultEdgeOptions}
            >
              <Controls />
              <Button
  disabled={!isButtonActive}
  onClick={handleSave}
  variant="contained"
  style={{
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1000,
    backgroundColor: isButtonActive ? 'blue' : 'grey',
    color: 'white' 
  }}
>
  Save
</Button>
{isStatusBarVisible && (
        <div style={styles.statusBar}>
          <div style={styles.statusBarTitle}>
            <AiTwotoneNotification style={styles.notificationIcon} />
            Status Bar
          </div>
          <ul style={styles.issueList}>
            {issues.map((issue, index) => (
              <li key={index} style={issue.type === 'critical' ? styles.criticalIssue : styles.warningIssue}>
                <div style={styles.issueContent}>
                  {issue.type === 'critical' ? <BiError style={styles.issueIcon} /> : <IoWarningOutline style={styles.issueIcon} />}
                  <span style={styles.issueText}>{issue.message}</span>
                </div>
              </li>
            ))}
            {temporaryMessage && (
              <li style={temporaryMessage.type === 'success' ? styles.successMessage : styles.errorMessage}>
                <div style={styles.issueContent}>
                  {temporaryMessage.type === 'success' ? <TiTick style={styles.issueIcon} /> : <ImCross style={styles.issueIcon} />}
                  <span style={styles.issueText}>{temporaryMessage.message}</span>
                </div>
              </li>
            )}
          </ul>
        </div>
      )}
            </ReactFlow>
            </DeleteNodeProvider>
          </div>
       
        </ReactFlowProvider>
      </div>
    

      {formComponent}
    
      <ToastContainer
        position="top-center"  // Set position to top-center
        autoClose={10000}       // Set autoClose to 5000ms (5 seconds)
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: '1.2em' }}  // Increase the font size
      />

    </>
  );
};

export default Transformation