import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Element from "../components/Element";
import { useFormik } from "formik";

function OperationsComponent({
  onSubmit,
  isEditing,
  filterJson,
  columnsDisplay,
  dataPrev,
}) {
  const [formValidation, setFormValidation] = useState(null);
  // console.log(filterJson,"filterJson")

  const getOperationInitialValues = (filterJson) => {
    try {
      const operationInitialValues = {};

      for (const key in filterJson[0].initialvalues) {
        if (key.startsWith("operation")) {
          operationInitialValues[key] = filterJson[0].initialvalues[key];
        }
      }

      return operationInitialValues;
    } catch (error) {
      console.log("Error in fetching initial values for operations", error);
      return {};
    }
  };

  const getOperationFieldValues = (filterJson) => {
    try {
      const operationFieldValues = [];
      const fieldsList = filterJson[0].fields_list;
      for (const field of fieldsList) {
        if (field.field_id.startsWith("operation")) {
          if (field.field_id === "operation_column_name") {
            const modifiedArray = columnsDisplay?.map((value) => {
              return { id: value, name: value };
            });
           
            const updatedField = { ...field, option_list: modifiedArray };
            operationFieldValues.push(updatedField);
          } else {
            operationFieldValues.push(field);
          }
        }
      }
      return operationFieldValues;
    } catch (error) {
      console.log("Error while fetching initial values", error);
      return [];
    }
  };

  const fieldsList = getOperationFieldValues(filterJson);

  if (formValidation === null) {
    let validationObject = {};
    fieldsList?.forEach(
      ({ field_id, display_label, required, field_value }) => {
        if (required === "Y") {
          validationObject[field_id] = yup.string().required("Required");
        }
      }
    );
    const validationSchema = yup.object().shape(validationObject);
    setFormValidation(validationSchema);
  }

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values);
    onSubmit(values);
    formik.resetForm();
  };

  fieldsList.sort(function (a, b) {
    return a.display_sequence - b.display_sequence;
  });

  const formik = useFormik({
    initialValues: getOperationInitialValues(filterJson),
    validationSchema: formValidation,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    if (isEditing) {
      formik.setValues(dataPrev);
    }
  }, [isEditing, formik.setValues, dataPrev]);

  return (
    <>
      <div style={{ maxWidth: "800px" }}>
        <form onSubmit={formik.handleSubmit}>
          <p style={{marginLeft : "12px"}}>OPERATION FORM</p>
          <Box
            display="flex"
            flexWrap="wrap"
            gap="20px"
            marginTop="20px"
          >
            {fieldsList
              ? fieldsList.map((field) => (
                  <Element
                    key={field.id}
                    field={field}
                    values={formik.values}
                    touched={formik.touched}
                    errors={formik.errors}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    disabled={isEditing && field.field_id === "password"}
                  />
                ))
              : null}
          </Box>
          <Box display="flex" mt="20px" marginLeft="12px">
            <Button type="submit" sx={{ backgroundColor: "rgb(0, 72, 190)!important", color: "white" }}>Update</Button>
          </Box>
        </form>
      </div>
    </>
  );
}
export default OperationsComponent;
