import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import OperatorComponent from "./OperatorComponent";
import Component from "./ExpressionForm";
import convertToRenderFormat from "../services/Extract";

export default function ParentComponent({
  connectedInputs,
  onFormValuesSubmit,
  operatorsData,
  initialData,
  initialValues,
  formData
}) {
  
  function findSelectColumns(obj) {
    const mainKey = Object.keys(connectedInputs);
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'select_columns' && typeof obj[key] !== 'string') {
          return obj[key];
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          let result = findSelectColumns(obj[key]);
          if (result) return result;
        }
      }
    }
    return null;
  }

  function extractOutputColNames(data) {
            const firstKey = Object.keys(data)[0];
            const expressionList = data[firstKey]["expression_list"];
            const outputColNames = [];
            for (const key in expressionList) {
              if (expressionList.hasOwnProperty(key)) {
                outputColNames.push(expressionList[key]["output_col_name"]);
              }
            }
            return outputColNames;
          }
        
          function haveDifferentValues(arr1, arr2) {
            const set1 = new Set(arr1);
            const set2 = new Set(arr2);
            if (set1.size !== set2.size) return true;
            for (let item of set1) {
              if (!set2.has(item)) return true;
            }
            return false;
          }

  const [columns, setColumns] = useState([]);
  const [source1Columns, setSource1Columns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [renderData, setRenderData] = useState({});
  const [outputColumn, setOutputColumn] = useState([]);
  const prevColumnsRef = useRef(columns);

  useEffect(() => {
    try {
      if (connectedInputs && connectedInputs.length >= 1) {
        let columnString;

        if (connectedInputs[0].label === "Expression") {
          columnString = findSelectColumns(connectedInputs[0]);
        } else {
          if(connectedInputs[0].script_type == "shell_script")
            columnString=connectedInputs[0]?.select_columns
          else if(connectedInputs[0].script_type == "inline_code")
            columnString = connectedInputs[0]?.select_columns_1;
          else
          columnString = connectedInputs[0]?.select_columns;        }

        if (columnString && !Array.isArray(columnString)) {
          columnString = columnString.split(',').map(col => col.trim());
        }
        setColumns(columnString);
      } else {
        throw new Error('connectedInputs is empty or undefined');
      }
    } catch (error) {
      console.error(error.message);
      setColumns([]); // or handle the error as needed
    }
  }, [connectedInputs]);
  
  useEffect(() => {
    if (initialData !== undefined) {
      let newRenderData;
      let initialOutputColumn = [];
      if (
        initialData?.transformation_name &&
        initialData?.input_df &&
        initialData?.output_df
      ) {
        newRenderData = convertToRenderFormat(initialData);
        initialOutputColumn = extractOutputColNames(newRenderData);
      } 
      else if (initialData.hasOwnProperty("Expression")){
        newRenderData =  initialData.Expression;
        initialOutputColumn = extractOutputColNames(newRenderData);
        
      }
      setRenderData(newRenderData);
      setOutputColumn(initialOutputColumn);
    }
  }, [initialData]);

let mainkey=Object.keys(renderData)[0];
  useEffect(() => {
    let outputDf = null; 
    if (connectedInputs?.length > 0 ) {
      outputDf = Object.values(connectedInputs[0])?.find(obj => obj?.output_df);
    }
    const outputDfValue = connectedInputs[0]?.output_df || Object.values(connectedInputs[0] || {}).flatMap(obj => Object.values(obj || {})).find(obj => obj?.output_df)?.output_df;
  
    if ((outputDfValue && renderData[mainkey]?.input_df !== outputDfValue && Object.keys(renderData).length > 0) || (outputDfValue && renderData[mainkey]?.input_df === outputDfValue && haveDifferentValues(columns,outputColumn) && columns?.length > 0)) {
      const expressionlist = columns.reduce(
        (acc, header, index) => {
          acc[`${mainkey}_${index+1}`] = {
            sequence: index + 1,
            input_col_name: header,
            output_col_name: header,
            expression_value: " ",
            operatorType: "",
            operator: "",
          };
          return acc;
        },
        {}
      );
      const updatedValues = {
        ...renderData,
        [mainkey]: {
            ...renderData[mainkey],
            expression_list: expressionlist,
        },
    };
    setRenderData(updatedValues);
    }
  }, [connectedInputs]);

  useEffect(() => {
    if (haveDifferentValues(columns,source1Columns)) {
      setSource1Columns(columns);
    }
  }, [columns]);

  const handleEditColumn = (column) => {
    setSelectedColumn(column);
  };

  const handleUpdateColumnInfo = (updatedColumnInfo) => {
    setSelectedColumn(updatedColumnInfo);
  };

  const handleSubmitOutputColNames = (formvalues) => {
    onFormValuesSubmit(formvalues);
  };

  return (
    <div className="Main-container" style={{ margin: "auto" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", width: "100%", gap: "2px", fontSize: "small" }}>
          <Component
            sourceColumn={source1Columns}
            onEditColumn={handleEditColumn}
            editedColumn={selectedColumn}
            onSubmitOutputColNames={handleSubmitOutputColNames}
            initialData={renderData}
            connectedInputs={connectedInputs}
            formData={formData}
            sx={{ width: "70%", height: "100%" }}
          />
          <OperatorComponent
            columnInfo={selectedColumn}
            operatorsData={operatorsData}
            onUpdateColumnInfo={handleUpdateColumnInfo}
            sx={{ width: "30%", height: "100%" }}
          />
        </div>
      </Box>
    </div>
  );
}
