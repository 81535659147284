import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from '../api/axios';
import Element from "../components/Element";
import { selectCurrentToken } from "state/authSlice";
import { useSelector } from "react-redux";
import useStyles from "useStyles";
const ObjectForm = (props) => {
  const styles = useStyles();
  const accessToken = useSelector(selectCurrentToken);

  const { fieldsList, values, addOrEdit, object } = props;
  const [userSchema, setUserSchema] = useState(null);
  const [action, setAction] = useState(null);
  const [userExists, setUserExists] = useState(false);

  const [resetKey, setResetKey] = useState(0);

  const [isEditing, setIsEditing] = useState(Boolean(values?.id));

  const [loginIdChanged, setLoginIdChanged] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;

  if (userSchema === null) {
    if (values?.id > 0) {
      setAction("Update");
    } else {
      setAction("Create");
    }

    let validationObject = {};
    fieldsList?.forEach(
      ({ field_id, display_label, required, field_value }) => {
        if (required === "Y") {
          validationObject[field_id] = yup
            .string()
            .required(`${field_id} is required`);
        }
      }
    );
    const validationSchema = yup.object().shape(validationObject);

    setUserSchema(validationSchema);
  }

  const checkUserExists = async (login_id) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/users/userexists/${login_id}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json', // Add other headers if needed
          },
        }
      );
      setUserExists(response.data.exists);
      console.log(response.data.exists, "response.data.exists");
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsEditing(Boolean(values?.id));
    if (!("id" in values)) {
      values["id"] = 0;
    }
    if (!("created_by" in values)) {
      values["created_by"] = "system";
      values["updated_by"] = "system";
    }
    addOrEdit(values, resetForm);
  };

  return (
    <Box>
      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={userSchema}
        initialValues={values}
        key={resetKey}

      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box margin="1.5rem"
              display="grid"
              rowGap="15px"
              columnGap="15px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              {fieldsList
                ? fieldsList.map((field) => (
                  <Element
                    key={field.id}
                    field={field}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={(event) => {
                      handleChange(event);
                      if (!loginIdChanged) {
                        setLoginIdChanged(true);
                      }
                      checkUserExists(event.target.value);
                    }}
                    disabled={isEditing && field.field_id === "password"}
                  />
                ))
                : null}
            </Box>
            <Box display="flex" justifyContent="end">
            <Button
                type="button"
                className="outline"
                onClick={() => {
                  handleReset();
                  setResetKey((prevKey) => prevKey + 1); 
                }}
                sx={styles.myBackground}
  
                disabled={!dirty || isSubmitting}
              >
                Reset
              </Button>{" "}
              &nbsp;
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                sx={styles.myBackground}
              >
                {action} {object}
              </Button>
            </Box>
            {userExists ? (
              <Box color="red" mt="1rem">
                User already exists!
              </Box>
            ) : null}
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ObjectForm;
